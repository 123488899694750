import { Center, Flex, ListItem, Spinner, Text, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { round } from '../../../helpers/formatValues'
import { safeLog } from '../../../helpers/logging'

const MethodologiesBreakdown = (props) => {

  const methodologies = props.methodologies

  safeLog('methodologies: ', methodologies, props.ready)

  const hasAttributes = (methodology) => Boolean(methodology.method_information[0]?.parameters['Variables Used']?.length)

  const Scope1Breakdown = (props) => {
    const scope = props.scope

    if (scope.detail === 'Not Found' || scope.length === 0 || scope[0].length === 0)
      return <Text> Data not found </Text>

    return scope.map((methodology, index) => {

      if (!methodology.method_information[0]?.methodology)
        return <></>

      return <Text key={index}>
        For {methodology.source.emissions_source} emissions, the methodology used
        was {methodology.method_information[0]?.methodology || <>&mdash;</>}, {hasAttributes(methodology) ?
          <>
            with the following attributes: {methodology.method_information[0]?.parameters['Variables Used'].join(', ')}
          </> : <></>}
      </Text>
    })
  }

  const Scope3Breakdown = (props) => {
    const scope = props.scope

    if (scope.detail === 'Not Found' || scope.length === 0 || scope[0].length === 0)
      return <Text> Data not found </Text>

    return <UnorderedList>
      {scope.map((method, index) => {
        if (!method.methods[0] && !method.supplier_pct)
          return <></>

        return <ListItem key={index} marginBottom='12px'>
          <Text fontSize='20px'> {method.ghgcategory} </Text>

          {
            method.methods[0]?.method_name ?
              <>
                <Text> {method.methods[0]?.method_name || <>&mdash;</>} was used. </Text>
                <Text> The data quality was "{method.methods[0]?.data_quality || <>&mdash;</>}" </Text>
                <Text> The methodology was "{method.methods[0]?.methodology || <>&mdash;</>}" </Text>
              </>
              : <Text> Methodology information not available for this category </Text>
          }

          <Text> Percentage of emissions mapped to the supply chain: {round(method.supplier_pct, 1) ?? <>&mdash;</>}% </Text>
        </ListItem>
      })}

    </UnorderedList>
  }

  const Content = () => {
    if (!props.ready)
      return <Center><Spinner /></Center>

    return <Flex direction={'column'} gap='2em'>

      <Flex direction={'column'} gap='1em'>
        <Text fontSize={24}> Scope 1 </Text>
        <Scope1Breakdown scope={methodologies.scope1} />
      </Flex>

      <Flex direction={'column'} gap='1em'>
        <Text fontSize={24}> Scope 2 </Text>
        <Text> Data not found </Text>
      </Flex>

      <Flex direction={'column'} gap='1em'>
        <Text fontSize={24}> Scope 3 </Text>
        <Scope3Breakdown scope={methodologies.scope3} />
      </Flex>

    </Flex>
  }

  return (
    <Flex direction={'column'} gap='1em' className="reportSection printPageBreak">
      <Text fontWeight='600' fontSize="2em">Description of Methodologies and Data Used</Text>
      <Content />
    </Flex>
  )
}

export default MethodologiesBreakdown