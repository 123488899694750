import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCurrencySymbol } from '../helpers/currency'
import { safeLog } from '../helpers/logging'
import { objectToArray } from '../helpers/objectToArray'
import { alphabeticalSort } from '../helpers/sorting'
import {
  getAssetBreakdown,
  getAssetEmissionBreakdown,
  getAssetTopInitiatives,
  getCombinedTargetLine,
  getEmissionsCompoundsByScope,
  getInsights,
  getInitiatives,
  getIntensities,
  getInternalCarbonPrices,
  getMonthlyEmissions,
  getMonthlySupplierEmissions,
  getOrganisation,
  getOrganisationPartners,
  getReport,
  getReportTypes,
  getRoadmap,
  getSBTIs,
  getScope3CategoryEmissions,
  getScope3CategoryEmitters,
  getScope3Emissions,
  getScope3Emitters,
  getSourceEmissions,
  getSourceBreakdown,
  getSpecificAsset,
  getSupplierCategories,
  getSuppliers,
  getTargets,
  getTopEmitters,
  getTopEmissionSources,
  getVendors,
  getIdNames,
  getIndustryCarbonMaturity,
  getIndustryIds,
  getLocationIdNames,
  getSupplierEmissions,
  getPopulatedYears,
  getMethodologies,
  genericAPICall,
  getTransparency,
} from './APICalls'


export const useRoadmap = (token, orgId, locationId) => {

  const defaultValue = {
    combined: [],
    s1: [],
    s2: [],
    s3: [],
  }

  const [roadmapData, setRoadmapData] = useState(defaultValue)

  const navigate = useNavigate()

  useEffect(() => {
    setRoadmapData(defaultValue)
    if (locationId) {
      const getLocationId = async () => {
        if (locationId != -1)
          return locationId
        return getAssetEmissionBreakdown(token, orgId, 2020).then(response => checkTimeout(response, navigate)
        ).then(response => response[0]?.location_id)
      }

      getLocationId().then(locationId => getRoadmap(token, orgId, null, locationId).then(response => {
        let data = checkTimeout(response)
        data = data.filter((point, index) =>
          index != 0 && point.date != data[index - 1].date
        )
        setRoadmapData({
          combined: data,
          s1: [], s2: [], s3: []
        })
      }))
    } else {
      let requests = [null, 1, 2, 3].map(scope => getRoadmap(token, orgId, scope))
      Promise.all(requests).then(responses => {
        responses.forEach(response => checkTimeout(response, navigate))
        setRoadmapData({
          combined: responses[0],
          s1: responses[1],
          s2: responses[2],
          s3: responses[3]
        })
      })
    }
  }, [locationId])

  return { roadmapData: Object(roadmapData) }
}

export const useInitiatives = (token, orgId, scope) => {
  const [initiativeData, setInitiativeData] = useState([])
  const [loaded, setLoaded] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setInitiativeData([])
    getInitiatives(token, orgId, scope).then(response => {
      setInitiativeData(checkTimeout(response, navigate))
      setLoaded(true)
    })
  }, [scope])

  return { initiativeData, setInitiativeData, loaded }
}

// You can get scope 3 org emissions by just providing scope = 3 and a year. Asset breakdowns are available only for scopes 1 and 2
export const useEmissions = (token, orgId, scope, year, location, system, unit, equipment, period) => {

  const defaultValue = {
    emissions_total_summary: 0,
    emissions_total_summary_uom: '',
    scope1_pct: 0,
    scope2_pct: 0,
    scope3_pct: 0,
    emissions_scope1_tonnes: 0,
    emissions_scope2_tonnes: 0,
    emissions_scope3_tonnes: 0,
    carbon_price: 0,
    carbon_price_currency: null,
    emissions_total_cost_display: '',
    emissions_scope1_cost_display: '',
    emissions_scope2_cost_display: '',
    emissions_scope3_cost_display: '',
    emissions_monthly: [],
  }

  const [emissionData, setEmissionData] = useState(defaultValue)

  const navigate = useNavigate()

  useEffect(() => {
    if (!token)
      return
    setEmissionData(defaultValue)
    getMonthlyEmissions(token, orgId, scope, year, location, system, unit, equipment, period).then(response => {
      setEmissionData(checkTimeout(response, navigate))
    })
  }, [scope, year, location, system, unit, equipment])

  return emissionData
}

export const useSuppliersEmissions = (token, orgId, year) => {
  const [supplierEmissions, setSupplierEmissions] = useState({
    emissions_total: null,
    emissions_total_cost_display: null,
    emissions_monthly: []
  })

  const navigate = useNavigate()

  useEffect(() => {
    getMonthlySupplierEmissions(token, orgId, year).then(response => {
      setSupplierEmissions(checkTimeout(response, navigate))
    })
  }, [token, orgId, year, navigate])

  return supplierEmissions
}

export const useScope3Emissions = (token, orgId, category_type, year, primary_id, secondary_id, tertiary_id) => {
  const [S3emissionData, setS3EmissionData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getScope3Emissions(token, orgId, category_type, year, primary_id, secondary_id, tertiary_id).then(response => setS3EmissionData(checkTimeout(response, navigate)))
  }, [])

  return { S3emissionData }
}

export const useScope3Emitters = (token, orgId, category_type, year, primary_id, secondary_id) => {
  const [S3emitterData, setS3EmitterData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getScope3Emitters(token, orgId, category_type, year, primary_id, secondary_id).then(response => setS3EmitterData(checkTimeout(response, navigate)))
  }, [])

  return { S3emitterData }
}

export const useSupplierEmissions = (token, orgId, locationId, year) => {
  const [supplierData, setSupplierData] = useState({
    emissions_total: null, emissions_total_cost_display: null, accuracy: null, supplier_name: null,
    supplier_type: null,
  })
  const navigate = useNavigate()

  useEffect(() => {
    getSupplierEmissions(token, orgId, locationId, year).then(response =>
      setSupplierData(checkTimeout(response, navigate)))
  }, [locationId])

  return supplierData
}

/**
 * Hook for the API call that will fetch the emission data by estimating emissions for each compound (CO2, CH4, NOX, etc)
 * for scope for an organisation in a single year.
 *
 * Example Call — https://api.xct.ai/emissions/scopes/{scope}/compounds/totals/years/{year}
 *
 * @param  token     The auth token
 * @param  orgId     The organisation ID
 * @param  scope     The scope (1, 2, 3)
 * @param  year      The year
 * @param  compound  A specific compound (co2, ch4) - optional
 * @return string    A json blob containing the returned results
 */
export const useEmissionsCompoundsByScope = (token, orgId, scope, year, compound = null) => {
  const [emissionsCompoundsData, setEmissionsCompoundsData] = useState([{}])

  const navigate = useNavigate()

  useEffect(() => {
    getEmissionsCompoundsByScope(token, orgId, scope, year, compound = null).then(response =>
      setEmissionsCompoundsData(checkTimeout(response, navigate)))
  }, [])

  return emissionsCompoundsData
}

export const useAssetEmissionBreakdown = (token, orgId, year, location, system, unit) => {
  const [assetEmissionData, setAssetEmissionData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getAssetEmissionBreakdown(token, orgId, year, location, system, unit).then(response => setAssetEmissionData(checkTimeout(response, navigate)))
  }, [])

  return { assetEmissionData }
}


export const useTopEmitters = (token, orgId, scope, year) => {
  const [emitterData, setEmitterData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setEmitterData([])
    if (scope != 3)
      getTopEmitters(token, orgId, scope, year).then(response => setEmitterData(checkTimeout(response, navigate)))
  }, [scope, year])

  return emitterData
}


export const useTopEmissionSources = (token, orgId, scope, year, categoryType = 'ghgprotocol') => {
  const [emissionSourceData, setEmissionSourceData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setEmissionSourceData([])
    if (scope != 3)
      getTopEmissionSources(token, orgId, scope, year).then(response => setEmissionSourceData(checkTimeout(response, navigate)))
    else
      getScope3Emitters(token, orgId, categoryType, year).then(response => checkTimeout(response, navigate)).then(response => {
        if (Array.isArray(response))
          response.forEach(source => source.name = source.primary_category)
        setEmissionSourceData(response)
      })
  }, [scope, year, categoryType])

  return emissionSourceData
}

export const useCategoryEmissions = (token, orgId, scope, categoryId, secondaryId, tertiaryId, year) => {

  const defaultValue = {
    emissions_total: null,
    emissions_total_cost_display: null,
    emissions_monthly: [],
  }

  const [categoryData, setCategoryData] = useState(defaultValue)

  const navigate = useNavigate()

  useEffect(() => {
    setCategoryData(defaultValue)
    if (scope == 3) {
      getScope3CategoryEmissions(token, orgId, categoryId, secondaryId, tertiaryId, year).then(response => checkTimeout(response, navigate)).then(response => {
        setCategoryData(response)
      }
      )
    }
  }, [])

  return categoryData
}

export const useCategoryEmitters = (token, orgId, scope, categoryId, secondaryId, year) => {

  const [emitters, setEmitters] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    setEmitters([])
    if (scope == 3)
      getScope3CategoryEmitters(token, orgId, categoryId, secondaryId, year).then(response => checkTimeout(response, navigate)).then(response => {
        setEmitters(response)
      })
  }, [categoryId, navigate, orgId, scope, secondaryId, token, year])

  return emitters
}

export const useSourceEmissions = (token, orgId, scope, year, emission_source) => {
  const [sourceTimeseriesData, setSourceTimeseriesData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSourceEmissions(token, orgId, scope, year, emission_source).then(response => setSourceTimeseriesData(checkTimeout(response, navigate)))
  }, [])

  return { sourceTimeseriesData }
}

export const useSourcebreakdown = (token, orgId, scope, year, emission_source) => {
  const [sourceBreakdownData, setSourceBreakdownData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSourceBreakdown(token, orgId, scope, year, emission_source).then(response => setSourceBreakdownData(checkTimeout(response, navigate)))

  }, [])

  return { sourceBreakdownData }
}

export const useSpecificAsset = (token, orgId, location, system, unit, equipment) => {
  const [assetInformation, setAssetInformation] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSpecificAsset(token, orgId, location, system, unit, equipment).then(response => setAssetInformation(checkTimeout(response, navigate)))
  }, [])

  return { assetInformation }
}

export const useAssetBreakdown = (token, orgId, year, location, system, unit) => {
  const [assetBreakdown, setAssetBreakdown] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getAssetBreakdown(token, orgId, year, location, system, unit).then(response =>
      setAssetBreakdown(checkTimeout(response, navigate)))
  }, [])

  return assetBreakdown
}


export const useAssetTopInitiatives = (token, orgId, location, system, unit, equipment) => {
  const [assetTopInitiatives, setAssetTopInitiatives] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getAssetTopInitiatives(token, orgId, location, system, unit, equipment).then(response => setAssetTopInitiatives(checkTimeout(response, navigate)))
  }, [])

  return { assetTopInitiatives }
}

/**
 * Hook for the API call that will fetch the report data that has been customised by an organisation for
 * GHG Protocol, TCFD, and other reports.
 *
 * Example Call — https://api.xct.ai/reports/{report_type}/years/{year}
 *
 * @param  token   The auth token
 * @param  orgId  The organisation ID
 * @param  reportType   The report type (ghg_protocol, tcfd, etc)
 * @param  reportYear The year of the report
 * @return string   A json blob containing the returned results
 */
export const useReport = (token, orgId, reportType, reportYear) => {
  safeLog('calling APIHooks - useReport() - including reportType ' + reportType + ' + reportYear ' + reportYear)
  const [reportDetails, setReportDetails] = useState([])

  const navigate = useNavigate()

  // todo remove
  const report = getReport(token, orgId, reportType, reportYear)

  useEffect(() => {
    getReport(token, orgId, reportType, reportYear).then(response => setReportDetails(checkTimeout(response, navigate)))
  }, [])

  safeLog('APIHooks - useReport() - returned results are')
  safeLog(reportDetails)
  if (typeof reportDetails[0] !== 'undefined') {
    return reportDetails[0]
  }

}

export const useReportTypes = (token, orgId) => {
  const [reportTypes, setReportTypes] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getReportTypes(token, orgId).then(response => setReportTypes(checkTimeout(response, navigate)))
  }, [])

  return { reportTypes }
}

export const useIntensities = (token, orgId, year, location, system, unit) => {
  const defaultValue = {
    intensity_carbon: null,
    intensity_carbon_uom: null,
    intensity_energy: null,
    intensity_energy_uom: null,
  }
  const [intensityData, setIntensityData] = useState(defaultValue)

  const navigate = useNavigate()

  useEffect(() => {
    setIntensityData(defaultValue)
    getIntensities(token, orgId, year, location, system, unit).then(response => setIntensityData(checkTimeout(response, navigate)))
  }, [year])

  return intensityData
}

export const useVendors = (token, orgId, vendor) => {
  const [vendorData, setVendorData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getVendors(token, orgId, vendor).then(response => setVendorData(checkTimeout(response, navigate)))
  }, [])

  return { vendorData }
}

export const useSuppliers = (token, orgId, year) => {
  const [supplierData, setSupplierData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSuppliers(token, orgId, year).then(response => {
      setSupplierData(checkTimeout(response, navigate))
    })
  }, [])

  return supplierData
}

export const useSupplierCategories = (token, orgId, year, categoryType) => {
  const [supplierCategories, setSupplierCategories] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSupplierCategories(token, orgId, year, categoryType).then(response => {
      setSupplierCategories(checkTimeout(response, navigate))
    })
  }, [])

  return supplierCategories

}

export const useOrganisationPartners = (token, orgId) => {
  const [partnerData, setPartnerData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getOrganisationPartners(token, orgId).then(response => setPartnerData(checkTimeout(response, navigate)))
  }, [])

  return { partnerData }
}

export const useTargets = (token, orgId, summary) => {
  const [targetData, setTargetData] = useState([{}])
  const [combinedTarget, setCombinedTarget] = useState([{}])
  const [loaded, setLoaded] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    getTargets(token, orgId).then(response => {
      setLoaded(true)
      setTargetData(checkTimeout(response, navigate))
      safeLog('targetData: ', response)
    })
    if (!summary) {
      getCombinedTargetLine(token, orgId).then(response =>
        setCombinedTarget(
          checkTimeout(response, navigate)
        )
      )
    }
  }, [])

  return { targetData: Object(targetData), combinedTarget, loaded, setTargetData }
}

export const useSBTis = (token, orgId) => {
  const [SBTIData, setSBTIData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    getSBTIs(token, orgId)
      .then(SBTIData => {
        let namedData = SBTIData.map(sbt => {
          sbt.name = 'Science Based Target: Reduce Scope ' + sbt.scope + ' ' + sbt.reduction_percentage + '% by ' + sbt.year
          sbt.target_id = 'sbt' + sbt.sbtarget_id
          sbt.years = sbt.emissions
          return sbt
        })
        return namedData
      })
      .then(response => setSBTIData(checkTimeout(response, navigate)))
  }, [])

  return { SBTIData }
}

export const useAssets = (token, orgId, year, locationId, systemId, unitId, equipmentId) => {
  const [assetData, setAssetData] = useState([])
  const [ready, setReady] = useState(false)

  const [transparency, setTransparency] = useState()
  // todo: accuracy ready

  const navigate = useNavigate()

  useEffect(() => {
    setAssetData([])
    setReady(false)

    if (year) {
      getAssetEmissionBreakdown(token, orgId, year, locationId, systemId, unitId, equipmentId).then(response => {
        response = checkTimeout(response, navigate)
        if (!locationId) {
          // We are getting the list of locations. Sort them alphabetically
          response = response.sort(alphabeticalSort('location_name'))
        }
        setAssetData(response)
        setReady(true)

        // if (locationId)
        //   getTransparency(token, orgId, year, locationId, systemId, unitId, equipmentId).then(transparencyResponse =>
        //     setTransparency(checkTimeout(transparencyResponse, navigate)))
      })
    }
  }, [orgId, year, locationId, systemId, unitId, equipmentId, token, navigate])

  return { assetData: Object(assetData), transparency, ready }
}

export const useAssetTransparency = (token, orgId, year, locationId, systemId, unitId, equipmentId) => {
  const [transparency, setTransparency] = useState()
  const [ready, setReady] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setReady(false)
    setTransparency(undefined)
    if (locationId)
      getTransparency(token, orgId, year, locationId, systemId, unitId, equipmentId).then(response => {
        setTransparency(checkTimeout(response, navigate))
        setReady(true)
      })
  }, [equipmentId, locationId, navigate, orgId, systemId, token, unitId, year])

  return { transparency: {transparency_info: transparency}, transparencyReady: ready }
}

export const useIdNames = (token, orgId, page, primary_id, secondary_id, tertiary_id) => {
  const [names, setNames] = useState([])
  const navigate = useNavigate()
  useEffect(() => {
    if (page == 'scope3')
      getIdNames(token, orgId, page, primary_id, secondary_id, tertiary_id).then(response => {
        setNames(checkTimeout(response, navigate))
      })
  }, [page, primary_id, secondary_id, tertiary_id])

  return names
}

export const useLocationIdNames = (token, orgId, locationId, systemId, unitId, equipmentId) => {
  const [names, setNames] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    if (locationId)
      getLocationIdNames(token, orgId, locationId, systemId, unitId, equipmentId).then(response => {
        setNames(checkTimeout(response, navigate))
      })
  }, [])

  return names
}

/**
 * Hook for the API call that will fetch a single organisation
 *
 * Example Call — https://api.xct.ai/organisations/{org_id}
 *
 * @param  token   The auth token
 * @param  orgId  The organisation ID
 * @return string   A json blob containing the returned results
 */
export const useOrganisation = (token, orgId) => {
  const [organisationData, setOrganisationData] = useState({
    carbon_price: null,
    carbon_price_currency: null,
  })

  useEffect(() => {
    if (token && orgId)
      getOrganisation(token, orgId).then(response => {
        setOrganisationData(response)

        // Now we know the org id, also fetch the org's years which have available data
        getPopulatedYears(token, orgId).then(yearsResponse => {
          response.populatedYears = yearsResponse
          setOrganisationData(response)
          response.currencySymbol = getCurrencySymbol(response.carbon_price_currency)
          setOrganisationData(response)
        })
      })
  }, [token, orgId])

  return organisationData
}

/**
 * Hook for the API call that will fetch all of the internal carbon prices for a particular industry.
 *
 * Example Call — https://api.xct.ai/insights/icp/industries/{industryId}
 *
 * @param  token   The auth token
 * @param  industryId  The industry ID
 * @return string   A json blob containing the returned results
 */
export const useInternalCarbonPrices = (token, industryId) => {
  const [internalCarbonPricesData, setInternalCarbonPricesData] = useState([{}])

  const navigate = useNavigate()

  useEffect(() => {
    getInternalCarbonPrices(token, industryId).then(response =>
      setInternalCarbonPricesData(checkTimeout(response, navigate)))
  }, [])

  return { internalCarbonPricesData }
}

export const useIndustryCarbonMaturity = (token, industryId) => {
  const [industryData, setindustryData] = useState([{}])
  const [industryIds, setIndustryIds] = useState([{}])

  const navigate = useNavigate()

  useEffect(() => {
    setindustryData([])

    getIndustryCarbonMaturity(token, industryId).then(response =>
      setindustryData(checkTimeout(response, navigate)))
  }, [industryId])

  useEffect(() => {
    getIndustryIds(token).then(response =>
      setIndustryIds(checkTimeout(response, navigate)))
  }, [])

  return { industryData, industryIds }
}

export const usePopulatedYears = (token, orgId) => {
  const [populatedYears, setPopulatedYears] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    getPopulatedYears(token, orgId).then(response => {
      setPopulatedYears(checkTimeout(response, navigate))
    })
  }, [])

  return populatedYears
}

export const useOrgChanging = (token, userId) => {
  // dummy hook for now //

  const [availableOrganisations] = useState([{ name: 'Sonoro', id: 293 }, { name: 'Energy Zero', id: 1 }, { name: 'SOCAR', id: 2 }])
  const [isLoading, setIsLoading] = useState(false)

  const changeOrganisation = () => {
    safeLog('changing organisation!')
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }

  return { availableOrganisations, isLoading, changeOrganisation }
}

export const useMethodologies = (token, orgId, year) => {
  const [methodologies, setMethodologies] = useState({})
  const [ready, setReady] = useState(false)

  useEffect(() => {

    Promise.all([1, 2, 3].map(scope => getMethodologies(token, orgId, year, scope))).then(responses => {
      setMethodologies({ scope1: responses[0], scope2: responses[1], scope3: responses[2] })
      setReady(true)
    })

  }, [token, orgId, year])

  return { methodologies, ready }
}

export const usePartners = (token, orgId) => {
  const { data, ready } = useGenericAPIHook(token, '/organisations/partners/', { orgId })
  return { partners: data, ready }
}

export const useOrganisations = (token) => {
  const { data, ready } = useGenericAPIHook(token, '/organisations/')
  return { organisations: data, ready }
}

export const useProductBreakdown = (token, orgId, year) => {
  const { data, ready } = useGenericAPIHook(token, '/products/', { orgId, year })
  return { products: objectToArray(data).sort((a, b) => b.emissions - a.emissions), ready }
}

export const useOrgFiles = (token, orgId) => {
  const { data, ready } = useGenericAPIHook(token, '/uploadfile/files', {orgId})
  return { fileList: data, ready }
}

export const useProductEmissions = (token, orgId, year, productId) => {
  const { data, ready } = useGenericAPIHook(token, '/products/years/' + year + '/' + productId, { orgId })
  return { productEmissions: data, ready }
}

/**
 * Hook for the API call that will fetch insights for an organisation.
 *
 * Example Call — https://api.expectai.com/insights
 * Example Call — https://api.expectai.com/insights/scopes/{scope}
 * Example Call — https://api.expectai.com/insights/scopes/{scope}/categories/{primary_category_id}
 *
 * @param  token     The auth token
 * @param  orgId     The organisation ID
 * @param  scope     The scope (1, 2, 3) - optional
 * @param  primary_category_id     The primary_category_id - optional
 * @return string    A json blob containing the returned results
 */
export const useInsights = (token, orgId, scope = null, primary_category_id = null) => {
  const [insightsData, setInsightsData] = useState([])
  const [ready, setReady] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setReady(false)
    getInsights(token, orgId, scope, primary_category_id).then(response => {
      setReady(true)
      setInsightsData(checkTimeout(response, navigate))
    })
  }, [])

  return { insightsData, ready }
}

// Use and improve this method wherever possible. This file has gotten way too long!
const useGenericAPIHook = (token, endpoint, args) => {
  const [data, setData] = useState()
  const [ready, setReady] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setReady(false)
    genericAPICall(token, endpoint, args).then(response => {
      setReady(true)
      setData(checkTimeout(response, navigate))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, endpoint])

  return { data: Object(data), ready }
}

const checkTimeout = (response, navigate) => {
  if (Object(response).detail === 'Signature has expired') {
    navigate('/login')
    return
  }
  return response
}
