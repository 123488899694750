import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { displayCarbon, displayCost } from '../../helpers/formatValues'
import Card from '../General/Card'
import TrendIcon from '../General/TrendIcon'

const TargetCard = (props) => {

  const target = props.target

  const reduction = 1000
  const benefit = 1500

  const getReductionFocus = (focus) => {
    if (focus === "carbon_intensity")
      return "CARBON INTENSITY"
    return focus
  }

  return (
    <Card style={{ cursor: "pointer", maxWidth: "600px", maxHeight: "260px" }}
      onClick={() => props.editTarget(target)}>

      <Flex direction='column' alignItems={'center'} gap='1em' textAlign={'center'}>
        <Text textTransform={'uppercase'} fontSize={14}> Target for {target.year} </Text>

        <Box>
          Reduce scope {target.scope} {getReductionFocus(target.reduction_focus)} by
          <Text fontSize={60} fontWeight={700}>
            {target.reduction_percentage}%
          </Text>
        </Box>

        <Flex w='100%'>
          <Spacer />
          <Box>
            Carbon
            <Flex gap='0.5em'>
              <TrendIcon value={-reduction} />
              {displayCarbon((props.baseline[target.scope - 1] * target.reduction_percentage / 100) || null) || <>&mdash;</>}
            </Flex>
          </Box>

          <Spacer />

          <Box>
            Benefit
            <Flex gap='0.5em'>
              <TrendIcon value={-benefit} invert />
              {displayCost((props.carbonPrice * props.baseline[target.scope - 1] * target.reduction_percentage / 100000) || null) || <>&mdash;</>}
            </Flex>
          </Box>
          <Spacer />
        </Flex>
      </Flex>
    </Card>
  )
}

export default TargetCard