import { Button, FormControl, FormErrorMessage, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Tooltip } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { safeLog } from '../../../helpers/logging'
import { editInitiative, updateInitiative } from '../../../services/APICalls'
import ConfirmModal from '../../General/ConfirmModal'
import InitiativeForm from './InitiativeForm'
import { getCarbonAnnualTotal, prepareNewInitiative } from './ManageInitiatives/prepareInitiative'

const InitiativeFormModal = (props) => {

  const auth = useContext(props.context)
  const [latestId, setLatestId] = useState(0)
  const [newData, setNewData] = useState({
    initiative_name: props.initiative.initiative_name,
    carbon_impact_date: props.initiative.carbon_impact_date,
    front_end_id: latestId
  })
  const [isInvalid, setIsInvalid] = useState(false)
  const isForeign = (props.initiative.initiative_id && (auth.orgId.toString() != props.initiative.source_org_id))

  useEffect(() => {
    // Runs when form is opened
    setNewData({
      initiative_name: props.initiative.initiative_name,
      carbon_impact_date: props.initiative.carbon_impact_date,
      front_end_id: latestId - 1,
    })
    setLatestId(latestId - 1)
  }, [props.isOpen])

  const addOrEdit = props.initiative.initiative_name ? 'Edit' : 'Add'

  const onSave = () => {

    if (isInvalid || (!newData['initiative_name'])) {
      setIsInvalid(true)
      return
    }
    if (addOrEdit === 'Add') {
      const newInitiative = prepareNewInitiative(auth, newData)
      updateInitiative(auth.token, auth.orgId, null, newInitiative.Initiative).then(response => {
        if (response.success) {
          newInitiative.Initiative.initiative_id = response.initiative_id
          props.newInitiatives.unshift(newInitiative)
          props.setNewInitiatives(props.newInitiatives)
        }
        props.onClose()
      })
      return
    }

    const toEdit = [...props.newInitiatives, ...props.initiativeData].find(i => i.Initiative.initiative_id == props.initiative.initiative_id)

    // Overwrite the initiative with the new values from the user
    Object.entries(newData).forEach(([property, value]) => {
      toEdit.Initiative[property] = value ?? toEdit.Initiative[property]
    })
    toEdit.Initiative.carbon_annual_total = [1, 2, 3].map(n => toEdit.Initiative['carbon_annual_scope_' + n] || 0).reduce(
      (prev, current) => Number(prev) + Number(current)
    )
    toEdit.Initiative['carbon_annual_total'] = getCarbonAnnualTotal(toEdit)
    toEdit.Initiative['cost_annual_carbon'] = (toEdit.Initiative['carbon_annual_total'] / 1000) * auth.orgData.carbon_price
    toEdit.Initiative['carbon_impact_date'] = toEdit.Initiative['carbon_impact_date'] || null

    // Call API
    updateInitiative(auth.token, auth.orgId, toEdit.Initiative.initiative_id, toEdit.Initiative)

    // Update intiatives in the front end
    props.setInitiatives(props.initiativeData)

    props.onClose()
  }

  const onDelete = () => {
    let toDelete = props.initiativeData.find(i => i.Initiative.initiative_id == props.initiative.initiative_id)
    if (toDelete) {
      toDelete.Initiative.carbon_impact_date = null
      props.setInitiatives(props.initiativeData)
      props.setInitiatives(props.initiativeData.filter(i => i.Initiative.initiative_id != toDelete.Initiative.initiative_id))
      toDelete.Initiative.removed_by_user = true

      // Call API
      editInitiative(auth.token, auth.orgId, toDelete.Initiative)

      props.onClose()
      return
    }

    toDelete = props.newInitiatives.find(i => i.Initiative.front_end_id == props.initiative.front_end_id)
    if (toDelete) {
      toDelete.Initiative.carbon_impact_date = null
      // props.setNewInitiatives(props.newInitiatives)
      props.setNewInitiatives(props.newInitiatives.filter(i => i.Initiative.front_end_id != toDelete.Initiative.front_end_id))
      props.onClose()
      return
    }

    props.onClose()
  }

  const validate = (value) => {
    setNewData(value)

    safeLog('value: ', value)
    setIsInvalid(value.initiative_name === '')
  }

  const getErrorMessage = () => {
    return "Name cannot be empty!"
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} preserveScrollBarGap>
        <ModalOverlay />
        <ModalContent bg="ui.200" minW="800px">
          <ModalHeader> {props.initiative.initiative_name || 'Add an initiative'} </ModalHeader>
          <ModalCloseButton />

          <ModalBody>

            <FormControl isInvalid={isInvalid}>

              <InitiativeForm
                newData={newData}
                setNewData={validate}

                baselineYear={props.baselineYear}
                currency={auth.orgData.carbon_price_currency}
                finalYear={props.finalYear}
                initiative={props.initiative}
              />

              <FormErrorMessage> {getErrorMessage()} </FormErrorMessage>

            </FormControl>

          </ModalBody>

          <ModalFooter>

            {
              addOrEdit === "Add" ? <></> :
                <ConfirmModal
                  openModal={<IconButton aria-label='delete' bg="danger.high" _hover={{ bg: "danger.medium" }}
                    icon={<Image src="/icons/delete.svg" />} />}
                  prompt={<> Are you sure you want to remove <b>{props.initiative.initiative_name}</b> ? </>}
                  continuePrompt="Yes, remove the initiative"
                  onContinue={onDelete}
                />
            }

            <Spacer />

            <Button mr={3} variant={"ghost"} onClick={props.onClose}>
              Cancel
            </Button>

            <Tooltip label={isForeign ? "You don't have permission to edit this initiative" : null}>
              <div>
                <Button isDisabled={isInvalid || isForeign} bg={"primary.primary"} _hover={{ bg: "sky.700" }} onClick={onSave}>
                  {addOrEdit === "Add" ? "Add initiative" : "Update initiative"}
                </Button>
              </div>
            </Tooltip>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InitiativeFormModal