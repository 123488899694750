import { Box, Button, Center, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { round } from '../../../helpers/formatValues'
import { safeLog } from '../../../helpers/logging'
import { useSupplierEmissions } from '../../../services/APIHooks'
import Card from '../../General/Card'
import KPI from '../../General/KPI'
import ConnectionIcon from '../../Data/Connections/ConnectionIcon'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsKPIs from '../EmissionsKPIs'

const SupplierEmissions = (props) => {

  const auth = useContext(props.context)
  const params = useParams()
  const supplierId = params.supplierId

  const supplier = Object(useSupplierEmissions(auth.token, auth.orgId, supplierId, auth.year))

  safeLog('supplier', supplier)

  const percentageOfTotal = supplier.percentage ? round(supplier.percentage, 2) + '%' : null
  const connected = supplier.in_expect == "completed"

  const Header = () => {

    return <Box pl="20px">
      <Heading> {supplier.supplier_name} </Heading>
      <Text> {supplier.supplier_type} supplier </Text>
    </Box>
  }

  const Chart = () => {
    return <Card>
      <Flex>
        <Box>
          <Text> Monthly CO2e emissions for {supplier.supplier_name || <>&mdash;</>}</Text>
        </Box>
      </Flex>
      <EmissionsChart
        period='month'
        label={supplier.supplier_name || 'supplier'}
        type={'supplier'}
        scope={props.scope}
        lineData={Object(supplier).emissions_monthly || []}
      />
    </Card>
  }

  const ExtraKPIs = () => {
    return <SimpleGrid columns={2} gridGap="2em">
      <Card>
        <Text> Supplier net zero target </Text>
        <Text className='subtitle'> {supplier.net_promise ? supplier.net_year : "None set"}</Text>
      </Card>

      <Card>
        {supplier.in_expect ?
          <Flex gap="14px">
            <ConnectionIcon connected={connected} />
            {connected ?
              <Text> This supplier is connected to Expect </Text>
              :
              <>
                <Text> This supplier is not yet connected to Expect</Text>
                <Button
                  minW={"142px"}
                  bg={"primary.softPrimaryDefault"}
                  color="onSurface.primary.high"
                  padding="10px"
                  variant="ghost"
                > Ask {supplier.supplier_name} to connect </Button>
              </>
            }
          </Flex>
          : <></>
        }
      </Card>
    </SimpleGrid>
  }

  return (
    <Flex direction={"column"} gap="2em">

      <Header />

      <EmissionsKPIs
        currency={auth.orgData.carbon_price_currency}
        emissions={supplier?.emissions_total}
        cost={supplier?.emissions_total_cost_display}
        accuracy={supplier?.accuracy || <>&mdash;</>}
        extras={[{ value: percentageOfTotal, label: 'Percentage of total supplier emissions' }]}
        noNBA
      />

      <Chart />

      <ExtraKPIs />
    </Flex>
  )
}

export default SupplierEmissions