import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

const LoadingOrgYears = () => {
  return (
    <Center h="calc(100vh - 200px)">
      <Spinner/>
    </Center>
  )
}

export default LoadingOrgYears