import { Center, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'

const NoTargetsYet = (props) => {
  return (
    <Center h="calc(100vh - 200px)">
      <VStack gap="2em">
        <Text> Your org does not yet have any targets </Text>
        { props.button }
      </VStack>
    </Center>
  )
}

export default NoTargetsYet