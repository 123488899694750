import { Accordion } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useOrgFiles } from '../../services/APIHooks'
import { categories } from './categoryNames'
import DataChecklistCard from './Checklist/DataChecklistCard'

const DataUpload = (props) => {

  const auth = useContext(props.context)

  const { fileList } = useOrgFiles(auth.token, auth.orgId)

  return (
    <Accordion allowToggle borderWidth={0} display='flex' flexDirection='column' gap='1em'>
      {categories.map(category =>
        <DataChecklistCard
          auth={auth} scope={category.scope} category={category.category}
          name={category.name} description={category.description}
          files={fileList.length ? fileList.filter(file => file.category === category.category) : []}
        />
      )}
    </Accordion>
  )
}

export default DataUpload