import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

const TrendIcon = (props) => {

  const value = props.value
  if (!value)
    return <Flex w={props.height || "20px"}/>

  const invert = props.invert ? 'Invert' : ''
  const trendUp = value > 0

  return (
    <Flex
      w={props.height || "20px"}
    >
      {trendUp === !props.invert ?
        <Image src={'/icons/trendUp' + invert + '.svg'} />
        :
        <Image src={'/icons/trendDown' + invert + '.svg'} />
      }

    </Flex>
  )
}

export default TrendIcon