import { Flex, Spinner, Table, Tbody, Td, Th, Thead, Tr, Text, Tooltip } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import React from 'react'

const DataTable = (props) => {

  const navigate = useNavigate()

  const headings = props.headings
  const rows = props.rows
  const links = props.links

  let rowHover = {}
  if (links?.length) {
    rowHover = {
      cursor: "pointer",
      td: {
        div: {
          bg: "ui.300"
        }
      }

    }
  }

  const onTableRowClick = (url, event) => {
    if (event.button === 0)
      navigate(url)
  }

  const getCellAlign = (index) => {
    if (index === 0)
      return 'left'
    if (props.center) {
      return 'center'
    }
    if (index === rows[0]?.length - 1)
      return 'right'
    return 'left'
  }

  const filterFunction = props.filterFunction || ((row) => row)

  return (
    <>
      <Table width={"100%"} variant={"expectTable"}>
        <Thead>
          <Tr>
            {
              props.firstHeadingIsTitle ?
              <Th fontSize={"16px"} fontWeight={500} paddingLeft={0}>
                {headings[0]}
              </Th> : <></>
            }
            {headings.slice(props.firstHeadingIsTitle ? 1 : 0).map((heading, index) => (
              <Th
                key={index}
                fontFamily={"SF Pro Display"}
                fontWeight={500}
                fontSize={"14px"}
                lineHeight={"20px"}
                color={"rgba(255, 255, 255, 0.42)"}
                textAlign={getCellAlign(index + (props.firstHeadingIsTitle ? 1 : 0))}
              >
                {heading}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody fontSize={"14px"}>
          {rows.filter(filterFunction).map((row, index) => (
            <Tr
              key={index}
              _hover={rowHover}
              onClick={(event) => {
                if (links) onTableRowClick(links[index], event)
              }}

              color={"rgba(255, 255, 255, 0.9)"}
              height={"60px"}
              marginBottom={"20px"}
            >
              {row.map((row, tdIndex) => (
                <Td key={tdIndex}
                  padding="6px 0"
                  maxWidth={"50px"}
                  textAlign={getCellAlign(tdIndex)}
                >
                  <Flex
                    // justifyContent={getCellAlign(tdIndex)}

                    justifyContent={(tdIndex === headings.length - 1) ? "end" : ""}
                    background={"ui.100"}
                    maxH="60px"
                    minH="60px"
                    alignItems={"center"}
                    padding={"0 20px"}

                    borderLeftRadius={tdIndex === 0 ? "8px" : "0px"}
                    borderRightRadius={tdIndex === headings.length - 1 ? "8px" : "0px"}
                    borderLeftWidth={tdIndex === 0 ? "2px" : "0px"}
                    borderRightWidth={tdIndex === headings.length - 1 ? "2px" : "0px"}
                    borderTopWidth="1px"
                    borderBottomWidth="1px"

                    h="100%"
                    w="100%"
                    borderColor={"ui.300"}

                  >
                    <Text as="span"
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                      width="100%"
                    >
                      <Tooltip label={typeof (row) == 'string' || typeof (row) == 'number' ? String(row) : ''}>
                        {row || <>&mdash;</>}
                      </Tooltip>
                    </Text>
                  </Flex>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>

      </Table>
      {!rows.length && !props.noSpinner ?
        <Flex w="100%" h="100%" justifyContent={"center"} alignItems="center">
          <Spinner />
        </Flex>
        :
        <></>
      }
    </>


  )
}

export default DataTable