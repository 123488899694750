import React, { useContext } from 'react'
import { Outlet, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, ButtonGroup, Center, Collapse, Flex, Heading, Hide, Image, Link, ListItem, OrderedList, Select, SimpleGrid, Spacer, Stack, Text, UnorderedList, useDisclosure } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { safeLog } from '../../../helpers/logging'
import { printWindow } from '../../../helpers/helpers'
import { useEmissions, useEmissionsCompoundsByScope, useMethodologies, useOrganisation, useReport, useTopEmissionSources } from '../../../services/APIHooks'
import { CompoundsTable } from '../../Emissions/Compounds/Compounds'
import MethodologiesBreakdown from '../Methodologies/MethodologiesBreakdown'

const GHGProtocol = (props) => {

  const auth = useContext(props.context)
  const navigate = useNavigate()
  const params = useParams()

  // Report type
  const reportTitle = 'GHG Protocol';
  const reportType = 'ghg_protocol';

  // Prepare the organisation data
  const organisation = Object(useOrganisation(auth.token, auth.orgId))
  const organisationInternalCarbonPrice = (organisation.carbon_price) ? "$" + organisation.carbon_price + " " + organisation.carbon_price_currency + "/tCO2e" : "—"
  const orgImage = "/images/brands/" + organisation.slug + ".png";
  const orgName = organisation.org_name

  // Determine the reporting year and the baseline year (if available)
  const date = new Date();
  const currentDay = date.toLocaleString('default', { day: '2-digit' });
  const currentMonth = date.toLocaleString('default', { month: 'short' });
  const currentYear = date.toLocaleString('default', { year: 'numeric' });
  const currentDate = currentMonth + " " + currentDay + ', ' + currentYear;
  const yearEarliest = 2020; {/* TODO Replace with the organisation's first year that we have data for */}
  const yearBaseline = (parseInt(organisation.year_baseline)) ? organisation.year_baseline : null;
  if (params.yearReport) {
    var yearReport = params.yearReport;
  } else if (auth.year > 2000) {
    var yearReport = auth.year;
  } else {
    var yearReport = currentYear - 1;
  }

  // Event used to change year of the report that is generated
  const reportYearChange = (event) => {
    navigate('/reports/' + reportType.replace('_', '-') + '/years/' + event.target.value)
  }

  // Dropdown for the Reports Header to change the report year
  var years = new Array();
  for (let y = yearEarliest; y <= currentYear; y++) {
    years.push(y);
  }
  let YearsDropdown = () => {
    return <>
      <Box width={"10em"}>
        <Select variant={"filled"} onChange={reportYearChange} defaultValue={yearReport}>
          {years.map((y, index) => (
            <option key={index} value={y} > {y} </option>
          ))}
        </Select>
      </Box>
    </>
  }

  // Fetch the reporting data that has been customised and amended by the organisation's user
  const report = useReport(auth.token, auth.orgId, reportType, yearReport)

  // Prepare the data for the Cover Page
  const section_0_org_name = (report?.section_0_org_name) ? report.section_0_org_name : organisation.org_name;
  const section_0_org_address_1 = (report?.section_0_org_address_1) ? report.section_0_org_address_1 : organisation.address_1;
  const section_0_org_address_2 = (report?.section_0_org_address_2) ? report.section_0_org_address_2 : organisation.address_2;
  const section_0_org_address_3 = (report?.section_0_org_address_3) ? report.section_0_org_address_3 : organisation.address_3;
  const section_0_org_city = (report?.section_0_org_city) ? report.section_0_org_city : organisation.city;
  const section_0_org_state_code = (report?.section_0_org_state_code) ? report.section_0_org_state_code : organisation.state_code;
  const section_0_org_zip_code = (report?.section_0_org_zip_code) ? report.section_0_org_zip_code : organisation.zip_code;
  const section_0_org_country_name = (report?.section_0_org_country_name) ? report.section_0_org_country_name : organisation.country_code;
  var section_0_address = (section_0_org_address_1) ? section_0_org_address_1 : '';
        section_0_address += (section_0_org_address_2) ? ", " + section_0_org_address_2 : ''; // TODO replace comma with line breaks (\r\n not working)
        section_0_address += (section_0_org_address_3) ? ", " + section_0_org_address_3 : ''; // TODO replace comma with line breaks (\r\n not working)
  var section_0_address_city_state_zip = (section_0_org_city) ? section_0_org_city + ", " : '';
        section_0_address_city_state_zip += (section_0_org_state_code) ? " " + section_0_org_state_code : '';
        section_0_address_city_state_zip += (section_0_org_zip_code) ? " " + section_0_org_zip_code : '';

  // Prepare the data for Section 1 — Descriptive Information
  const section_1_org_name = (report?.section_1_org_name) ? report.section_1_org_name : organisation.org_name;
  const section_1_org_description = (report?.section_1_org_description) ? report.section_1_org_description : null;
  const section_1_consolidation_approach = (report?.section_1_consolidation_approach) ? report.section_1_consolidation_approach : null;
  const section_1_org_description_businesses_operations = (report?.section_1_org_description_businesses_operations) ? report.section_1_org_description_businesses_operations : null;
  const section_1_activities_included = (report?.section_1_activities_included) ? report.section_1_activities_included : null;
  const section_1_activities_excluded = (report?.section_1_activities_excluded) ? report.section_1_activities_excluded : null;
  const section_1_baseline_emissions_year_rationale = (report?.section_1_baseline_emissions_year_rationale) ? report.section_1_baseline_emissions_year_rationale : null;
  const section_1_baseline_emissions_year_recalculated = (report?.section_1_baseline_emissions_year_recalculated) ? report.section_1_baseline_emissions_year_recalculated : null;

  // Prepare the data for Section 2 — Emission Data - VCUs
  const section_2_vcus = (report?.section_2_vcus) ? report.section_2_vcus : null;

  // Prepare the data for Section 3 — Biogenic Emissions
  const section_3_biogenic_emissions = (report?.section_3_biogenic_emissions) ? report.section_3_biogenic_emissions : null;

  // Prepare the data for Section 4 — Data sources and methodologies - TODO, think through how to manage this section
  const section_4_scope_1_types_and_sources =                    (report?.section_4_scope_1_types_and_sources) ? report.section_4_scope_1_types_and_sources : null;
  const section_4_scope_1_data_quality =                         (report?.section_4_scope_1_data_quality) ? report.section_4_scope_1_data_quality : null;
  const section_4_scope_1_methodologies =                        (report?.section_4_scope_1_methodologies) ? report.section_4_scope_1_methodologies : null;
  const section_4_scope_1_percentage_from_suppliers =            (report?.section_4_scope_1_percentage_from_suppliers) ? report.section_4_scope_1_percentage_from_suppliers : null;

  // Section 5 — Notes for the baseline year
  const section_5_notes = (report?.section_5_notes) ? report.section_5_notes : null;

  // Fetch the emission data for Scope 1, 2, and 3 for the reporting year
  const orgEmissions = useEmissions(auth.token, auth.orgId, null, yearReport)
  const orgEmissionsOverall = (orgEmissions?.emissions_total_tonnes) ? Math.round(orgEmissions.emissions_total_tonnes) : 0;
  const orgEmissionsOverallDisplay = orgEmissionsOverall.toLocaleString();
  const orgEmissionsScope1  = (orgEmissions?.emissions_scope1_tonnes) ? Math.round(orgEmissions.emissions_scope1_tonnes) : 0;
  const orgEmissionsScope1Display = orgEmissionsScope1.toLocaleString();
  const orgEmissionsScope2  = (orgEmissions?.emissions_scope2_tonnes) ? Math.round(orgEmissions.emissions_scope2_tonnes) : 0;
  const orgEmissionsScope2Display = orgEmissionsScope2.toLocaleString();
  const orgEmissionsScope3  = (orgEmissions?.emissions_scope3_tonnes) ? Math.round(orgEmissions.emissions_scope3_tonnes) : 0;
  const orgEmissionsScope3Display = orgEmissionsScope3.toLocaleString();

  // Fetch the Scope 3 categorical emission data for the reporting year
  const orgEmissionsScope3Categories = useTopEmissionSources(auth.token, auth.orgId, 3, yearReport, 'ghgprotocol')
  var orgEmissionsScope3CategoriesPrep = new Array();
  for (var c = 1; c <= 15; c++) {
    orgEmissionsScope3CategoriesPrep[c] = new Object
    orgEmissionsScope3CategoriesPrep[c].emissions_tonnes_display = 0;
    if (orgEmissionsScope3Categories.length) {
      orgEmissionsScope3Categories.forEach(el => {
        if (c == el.primary_category_id) {
          orgEmissionsScope3CategoriesPrep[c] = el;

          var emissions_tonnes_rounded = (el.emissions_total_tonnes) ? Math.round(el.emissions_total_tonnes) : 0;
          orgEmissionsScope3CategoriesPrep[c].emissions_tonnes_display = emissions_tonnes_rounded.toLocaleString();
        }
      });
    }
  }
  const orgEmissionsScope3CategoriesDisplay = orgEmissionsScope3CategoriesPrep;

  // Prep data for
  var section_1_scope_3_categories_excluded_count = 0;
  var section_1_scope_3_categories_included_count = 0;
  orgEmissionsScope3CategoriesDisplay.forEach(el => {
    (el.emissions_total_tonnes > 0) ? section_1_scope_3_categories_included_count++ : section_1_scope_3_categories_excluded_count++;
  })

  // Fetch the Scope 1 + 2 compound emission data for the reporting year
  const orgEmissionsCompounds = CompoundsTable(auth.token, auth.orgId, yearReport);

  // Fetch the baseline emission data for Scope 1, 2, and 3 for the baseline year
  const orgEmissionsBaseline = useEmissions(auth.token, auth.orgId, null, yearBaseline)
  const orgEmissionsBaselineOverall = (orgEmissionsBaseline?.emissions_total_tonnes) ? Math.round(orgEmissionsBaseline.emissions_total_tonnes) : 0;
  const orgEmissionsBaselineOverallDisplay = orgEmissionsBaselineOverall.toLocaleString();
  const orgEmissionsBaselineScope1  = (orgEmissionsBaseline?.emissions_scope1_tonnes) ? Math.round(orgEmissionsBaseline.emissions_scope1_tonnes) : 0;
  const orgEmissionsBaselineScope1Display = orgEmissionsBaselineScope1.toLocaleString();
  const orgEmissionsBaselineScope2  = (orgEmissionsBaseline?.emissions_scope2_tonnes) ? Math.round(orgEmissionsBaseline.emissions_scope2_tonnes) : 0;
  const orgEmissionsBaselineScope2Display = orgEmissionsBaselineScope2.toLocaleString();
  const orgEmissionsBaselineScope3  = (orgEmissionsBaseline?.emissions_scope3_tonnes) ? Math.round(orgEmissionsBaseline.emissions_scope3_tonnes) : 0;
  const orgEmissionsBaselineScope3Display = orgEmissionsBaselineScope3.toLocaleString();

  // Fetch the Scope 3 categorical emission data for the reporting year
  const orgEmissionsBaselineScope3Categories = useTopEmissionSources(auth.token, auth.orgId, 3, yearBaseline, 'ghgprotocol')
  safeLog('categories;, ', orgEmissionsScope3Categories)
  var orgEmissionsBaselineScope3CategoriesPrep = new Array();
  for (var c = 1; c <= 15; c++) {
    orgEmissionsBaselineScope3CategoriesPrep[c] = new Object
    orgEmissionsBaselineScope3CategoriesPrep[c].emissions_tonnes_display = 0;
    if (orgEmissionsBaselineScope3Categories.length) {
      orgEmissionsBaselineScope3Categories?.forEach(el => {
        if (c == el.primary_category_id) {
          orgEmissionsBaselineScope3CategoriesPrep[c] = el;
          var emissions_tonnes_rounded = (el.emissions_total_tonnes) ? Math.round(el.emissions_total_tonnes) : 0;
          orgEmissionsBaselineScope3CategoriesPrep[c].emissions_tonnes_display = emissions_tonnes_rounded.toLocaleString();
        }
      });
    }
  }
  const orgEmissionsBaselineScope3CategoriesDisplay = orgEmissionsBaselineScope3CategoriesPrep

  // Fetch the Scope 1 + 2 compound emission data for the baseline year
  const orgEmissionsBaselineCompounds = CompoundsTable(auth.token, auth.orgId, yearBaseline);


  /**
   * Begin page generation
   */

  // Generate the header for the page
  const Header = () => {
    return <>
      <Box className="noPrint">
        <Box className="noPrint">
          <Flex>
            <Box>
              <Heading> { reportTitle } </Heading>
            </Box>
            <Spacer/>
            <YearsDropdown />
          </Flex>
        </Box>
      <Text marginTop="20px">This { reportTitle } report template is pre-populated with your organisation's carbon data from Expect, where available and applicable.</Text>
      </Box>
    </>
  }

  // Add the summary boxes, each of which when clicked expose the appropriate section
  const Summary = () => {
    return <>
      <SimpleGrid minChildWidth='225px' minChildHeight='250px' spacing="40px" marginTop='60px' >
        <Box className="reportSummary">
          <Link href="#coverPage" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185" >
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>1</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Cover Page</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Design the cover page for your report</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#descriptiveInformation" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>2</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Descriptive Information</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Describe your organisation and how it pertains to sustainability</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#ghgEmissionsData" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>3</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>GHG Emissions</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Report your organisation's greenhouse gas emissions</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#biogenicEmissions" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>4</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Biogenic Emissions</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Report your organisation's biogenic greenhouse gas emissions, if applicable</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#methodologiesAndData" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>5</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Methodologies and Data</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Report your organisation's biogenic greenhouse gas emissions, if applicable</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#baselineData" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>6</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Baseline Data</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Provide your organisation's emission data from its baseline year, if applicable</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link onClick={printWindow} style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>7</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Export</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Print or download your prepared report</Text>
            </Box>
          </Link>
        </Box>
      </SimpleGrid>
    </>
  }

  // Generate the Cover Page at the top of the report
  const CoverPage = () => {
    return <>
        <a name="coverPage"></a>
        <Box className="reportSection">
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            padding="10px"
            width='100%'
          >
          </Box>

          <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
            <Image src={orgImage} width="50px" height="50px" />
            <Text fontWeight='600' fontSize="2em" marginLeft="5px">{orgName}</Text>
          </Box>

          <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
            <Text fontWeight='600' fontSize="3em">GHG Protocol Report</Text>
            <Text fontWeight='500' fontSize="1.5em">Jan 1, {yearReport} &ndash; Dec 31, {yearReport}</Text>
          </Stack>

          <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
            <Text fontWeight='600' fontSize="1em">Report Generated On</Text>
            <Text fontWeight='400' fontSize="1em">{currentDate}</Text>
          </Stack>

          <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
            <Text fontWeight='600' fontSize="1em">Report Generated For</Text>
            <Text fontWeight='bold' fontSize="1em">{section_0_org_name}</Text>
            <Text fontWeight='400' fontSize="1em">{section_0_address}</Text>
            <Text fontWeight='400' fontSize="1em">{section_0_address_city_state_zip}</Text>
            <Spacer />
            <Hide>
              <Button bg="tangerine.500" size='sm'>Edit Organisation Address</Button>
            </Hide>
          </Stack>

          <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
            <Text fontWeight='600' fontSize="1em">Carbon Analysis By</Text>
            <Image src="/images/logos/mark-and-text-white3x.png" width="200px" className="noPrint" />
            <Image src="/images/logos/mark-colour-text-black3x.png" width="200px" className="onlyPrint" />
          </Stack>
        </Box>

    </>
  }


  {/* BEGIN SECTION 1 -- DESCRIPTIVE INFORMATION */}
  {/* This section is mostly populated by the organisation's team members */}
  const Section1 = () => {
    return <>
      {/* TODO ADD PAGE BREAK FOR PRINTING */}
      {/* TODO CHANGE FONT COLORS ETC FOR PRINTING */}

      <a name="descriptiveInformation"></a>
      <Box className="reportSection printPageBreak">
        <Box>
          <Stack display='flex' alignItems='left' justifyContent='left' width='100%'>
            <Text fontWeight='600' fontSize="2em">Descriptive Information</Text>
            <Spacer />
            <Hide>
              <Button bg='tangerine.500' size='sm' maxWidth="300px;">Edit Descriptive Information</Button>
            </Hide>
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">COMPANY NAME</Text>
            <Text fontWeight='600' fontSize="1em">{section_1_org_name ? section_1_org_name : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">DESCRIPTION OF THE COMPANY</Text>
            <Text fontWeight='600' fontSize="1em">{section_1_org_description ? section_1_org_description : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">CHOSEN CONSOLIDATION APPROACH (EQUITY SHARE, OPERATIONAL CONTROL OR FINANCIAL CONTROL)</Text>
            <Text fontWeight='600' fontSize="1em">{section_1_consolidation_approach ? section_1_consolidation_approach : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">DESCRIPTION OF THE BUSINESSES AND OPERATIONS INCLUDED IN THE COMPANY’S ORGANIZATIONAL BOUNDARY</Text>
            <Text fontWeight='600' fontSize="1em">{section_1_org_description_businesses_operations ? section_1_org_description_businesses_operations : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">THE REPORTING PERIOD COVERED</Text>
            <Text fontWeight='600' fontSize="1em">Jan 1, {yearReport} &ndash; Dec 31, {yearReport}</Text>
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">A LIST OF SCOPE 3 ACTIVITIES INCLUDED IN THE REPORT</Text>

            {section_1_scope_3_categories_included_count > 0 &&
              <>
                <Text fontWeight='600' fontSize="1em">
                  <UnorderedList>
                    {orgEmissionsScope3CategoriesDisplay.map((category) => [
                      (category.emissions_total_tonnes > 0) ? <ListItem>Category { category.primary_category_id }: { category.primary_category }</ListItem> : ""
                    ])}
                  </UnorderedList>
              </Text>
              </>
            }

            <Text fontWeight='600' fontSize="1em">{section_1_activities_included ? section_1_activities_included : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">A LIST OF SCOPE 1, SCOPE 2, AND SCOPE 3 ACTIVITIES EXCLUDED FROM THE REPORT WITH JUSTIFICATION FOR THEIR EXCLUSION</Text>

            {section_1_scope_3_categories_excluded_count > 0 &&
              <>
                <Text fontWeight='600' fontSize="1em">
                  <UnorderedList>
                    {orgEmissionsScope3CategoriesDisplay.map((category) => [
                      (category.emissions_total_tonnes <= 0) ? <ListItem>Category { category.primary_category_id }: { category.primary_category }</ListItem> : ""
                    ])}
                  </UnorderedList>
                </Text>
              </>
            }
            <Text fontWeight='600' fontSize="1em">{section_1_activities_excluded ? section_1_activities_excluded : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">THE YEAR CHOSEN AS BASE YEAR AND RATIONALE FOR CHOOSING THE BASE YEAR</Text>
            <Text fontWeight='600' fontSize="1em">{section_1_baseline_emissions_year_rationale ? section_1_baseline_emissions_year_rationale : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>

          <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='30px'>
            <Text fontWeight='600' fontSize="1em" textTransform="uppercase">ONCE A BASE YEAR HAS BEEN ESTABLISHED, THE CHOSEN BASE YEAR EMISSIONS RECALCULATION POLICY. IF BASE YEAR EMISSIONS HAVE BEEN RECALCULATED, THE CONTEXT FOR ANY SIGNIFICANT EMISSIONS CHANGES THAT TRIGGERED THE RECALCULATION.</Text>
            <Text fontWeight='600' fontSize="1em">{yearBaseline ? "The established baseline year is " + yearBaseline + "." : 'A baseline year has not been established.'}</Text> {/* TODO ENABLE USER TO AMEND */}
            <Text fontWeight='600' fontSize="1em">{section_1_baseline_emissions_year_recalculated ? section_1_baseline_emissions_year_recalculated : '\u2014'}</Text> {/* TODO ENABLE USER TO AMEND */}
          </Stack>
        </Box>
      </Box>
    </>
  }

  {/* BEGIN SECTION 2 -- GREENHOUSE GAS EMISSIONS DATA */}
  {/* This section is mostly populated by Expect */}
  const Section2 = () => {
    return <>
      <a name="ghgEmissionsData"></a>
      <Box className="reportSection printPageBreak">
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%'>
          <Text fontWeight='600' fontSize="2em">Greenhouse Gas Emissions Data</Text>
          <Text fontWeight='400' fontSize="1em">This section is automatically populated by emissions data available within Expect.</Text>
        </Stack>

        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Overall CO<sub>2</sub>e Emissions</Text>
          <Text fontWeight='500' fontSize="1.2em">{orgEmissionsOverallDisplay} tonnes</Text>
        </Stack>

        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Emissions by Scope</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scopes and Categories</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Scope 1 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope1Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 2 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope2Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 3 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope3Display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>


        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Scope 3 Upstream Emissions</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 1</Td>
                <Td>Purchased Goods and Services</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[1].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 2</Td>
                <Td>Capital Goods</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[2].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 3</Td>
                <Td>Fuel- and Energy-Related Activities</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[3].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 4</Td>
                <Td>Upstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[4].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 5</Td>
                <Td>Waste Generated in Operations</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[5].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 6</Td>
                <Td>Business Travel</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[6].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 7</Td>
                <Td>Employee Commuting</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[7].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 8</Td>
                <Td>Upstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[8].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>


        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Scope 3 Downstream Emissions</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 9</Td>
                <Td>Downstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[9].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 10</Td>
                <Td>Processing of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[10].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 11</Td>
                <Td>Use of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[11].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 12</Td>
                <Td>End-of-Life Treatment of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[12].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 13</Td>
                <Td>Downstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[13].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 14</Td>
                <Td>Franchises</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[14].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 15</Td>
                <Td>Investments</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[15].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        {orgEmissionsCompounds}

      </Box>
    </>
  }

  {/* BEGIN SECTION 3 -- BIOGENIC CO2 EMISSIONS DATA */}
  {/* This section will need to be populated by consultants or the organisation. Eventually Expect will provide this data. */}
  const Section3 = () => {
    return <>
      <a name="biogenicEmissions"></a>
      <Box className="reportSection printPageBreak">
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%'>
          <Text fontWeight='600' fontSize="2em">Biogenic CO<sub>2</sub> Emissions Data</Text>
          <Spacer />
          <Hide>
            <Button bg='tangerine.500' size='sm' maxWidth="300px;">Amend Biogenic Emissions Data</Button>
          </Hide>
          <Spacer />
          <Text fontWeight='400' fontSize="1em">Biogenic emissions are not applicable to this organisation's business activities.</Text>  {/* TODO ENABLE USER TO AMEND */}
      </Stack>
      </Box>
    </>
  }


  const { methodologies, ready } = useMethodologies(auth.token, auth.orgId, yearReport)
  {/* BEGIN SECTION 4 -- DESCRIPTION OF METHODOLOGIES AND DATA USED */}
  {/* This section should be mostly populated by Expect, with a bit of help from the organisation and consultants as needed. */}
  const Section4 = () => {
    return <>
      <a name="methodologiesAndData"></a>
      <MethodologiesBreakdown methodologies={methodologies} ready={ready} />
      {/* TODO ADD METHODOLOGIES AND DATA USED FOR ALL SCOPES FOR SECTION 4 - SEE X1 FOR IMPLEMENTATION FRAMEWORK */}
    </>
  }

  {/* BEGIN SECTION 5 -- BASELINE YEAR GREENHOUSE GAS EMISSIONS */}
  {/* This section is entirely populated by Expect, with the addition of amended commentary at the top of the section from the organisation as needed. */}
  const Section5 = () => {
    return <>
      {/* TODO Only show section 5 if the org has a baseline year. Show a note about the baseline year not being available otherwise. */}

      <a name="baselineData"></a>
      <Box className="reportSection printPageBreak">
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%'>
          <Text fontWeight='600' fontSize="2em">Baseline Year Greenhouse Gas Emissions Data &mdash; {yearBaseline}</Text>
          <Text fontWeight='400' fontSize="1em">This section is automatically populated by emissions data available within Expect.</Text>
          <Spacer />
          <Hide>
            <Button bg='tangerine.500' size='sm' maxWidth="300px;">Amend Baseline Year Emission Data</Button>
          </Hide>
        </Stack>


        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Baseline Overall Emissions</Text>
          <Text fontWeight='500' fontSize="1.2em">{orgEmissionsBaselineOverallDisplay} tonnes</Text>
        </Stack>

        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Baseline Emissions by Scope</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scopes and Categories</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Scope 1 Emissions</Td>
                <Td isNumeric>{orgEmissionsBaselineScope1Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 2 Emissions</Td>
                <Td isNumeric>{orgEmissionsBaselineScope2Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 3 Emissions</Td>
                <Td isNumeric>{orgEmissionsBaselineScope3Display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>


        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Baseline Scope 3 Upstream Emissions</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 1</Td>
                <Td>Purchased Goods and Services</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[1].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 2</Td>
                <Td>Capital Goods</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[2].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 3</Td>
                <Td>Fuel- and Energy-Related Activities</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[3].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 4</Td>
                <Td>Upstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[4].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 5</Td>
                <Td>Waste Generated in Operations</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[5].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 6</Td>
                <Td>Business Travel</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[6].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 7</Td>
                <Td>Employee Commuting</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[7].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 8</Td>
                <Td>Upstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[8].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>


        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Baseline Scope 3 Downstream Emissions</Text>
        </Stack>

        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 9</Td>
                <Td>Downstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[9].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 10</Td>
                <Td>Processing of Sold Products</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[10].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 11</Td>
                <Td>Use of Sold Products</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[11].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 12</Td>
                <Td>End-of-Life Treatment of Sold Products</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[12].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 13</Td>
                <Td>Downstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[13].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 14</Td>
                <Td>Franchises</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[14].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 15</Td>
                <Td>Investments</Td>
                <Td isNumeric> {orgEmissionsBaselineScope3CategoriesDisplay[15].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>

        { orgEmissionsBaselineCompounds }

      </Box>
    </>
  }

  return (
    <>
      <Header />
      <Summary />
      <CoverPage />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
    </>
  )

}

export default GHGProtocol