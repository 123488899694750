import { Button, Tooltip } from '@chakra-ui/react'
import React from 'react'

const TooltipLink = (props) => {

  if (!props.link)
    return <Button variant={'unstyled'} disabled>
      {props.children}
    </Button>

  return (
    <Tooltip label={props.link}>
      <a href={props.link} target='_blank' rel='noreferrer'>
        <Button variant={'solid'} h={props.h} w={props.h} borderRadius='12px' onClick={(e) => e.stopPropagation()}>
          {props.children}
        </Button>
      </a>
    </Tooltip>
  )
}

export default TooltipLink