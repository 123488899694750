const formatCarbon = (carbon, precision = 2, unit) => {
  carbon = Number(carbon)

  if (unit === 'g' || (!unit && ((carbon >= 0 && carbon < 1) || (carbon <= 0 && carbon > -1) || carbon === 0))) {
    return round(carbon * 1000) // Grams
  }
  else if (unit === 'kg' || (!unit && ((carbon >= 1 && carbon < 1000) || (carbon <= -1 && carbon > -1000)))) {
    return round(carbon, precision) // Kilograms
  }
  else if (unit === 't' || (!unit && ((carbon >= 1000 && carbon < 1000000) || (carbon <= -1000 && carbon > -1000000)))) {
    return round(carbon / 1000, precision) // Tons
  }
  else if (unit === 'kt' || (!unit && ((carbon >= 1000000 && carbon < 1000000000) || (carbon <= -1000000 && carbon > -1000000000)))) {
    return round(carbon / 1000000, precision) // Kilotonne
  }
  else if (unit === 'Mt' || (!unit && ((carbon >= 1000000000 && carbon < 1000000000000) || (carbon <= -1000000000 && carbon > -1000000000000)))) {
    return round(carbon / 1000000000, precision) // Megatonne
  }
  else if (unit === 'Gt' || (!unit && ((carbon >= 1000000000000 && carbon < 1000000000000000) || (carbon <= -1000000000000 && carbon > -1000000000000000)))) {
    return round(carbon / 1000000000000, precision) // Gigatonne
  }

  return ''
}

export const formatCarbonDescriptor = (carbon) => {
  if (carbon == null)
    return ''

  carbon = Number(carbon)

  if ((carbon >= 0 && carbon < 1) || (carbon <= 0 && carbon > -1) || carbon === 0) {
    return 'g' // Grams
  }
  else if ((carbon >= 1 && carbon < 1000) || (carbon <= -1 && carbon > -1000)) {
    return 'kg' // Kilograms
  }
  else if ((carbon >= 1000 && carbon < 1000000) || (carbon <= -1000 && carbon > -1000000)) {
    return 't' // Tonnes
  }
  else if ((carbon >= 1000000 && carbon < 1000000000) || (carbon <= -1000000 && carbon > -1000000000)) {
    return 'kt' // Kilotonne
  }
  else if ((carbon >= 1000000000 && carbon < 1000000000000) || (carbon <= -1000000000 && carbon > -1000000000000)) {
    return 'Mt' // Megatonne
  }
  else if ((carbon >= 1000000000000 && carbon < 1000000000000000) || (carbon <= -1000000000000 && carbon > -1000000000000000)) {
    return 'Gt' // Gigatonne
  }

  return ''
}

export const displayCarbon = (carbon, precision = 2, unit) => {
  if (carbon == null) return null
  return formatCarbon(carbon, precision, unit) + '\u202F' + (unit || formatCarbonDescriptor(carbon))
}

export const displayCost = (cost, precision = 2, absolute = false, descriptor = null, currency = 'USD') => {
  if (cost == null) {
    return null
  }
  return formatMoney(cost, precision, absolute, descriptor, currency) + (descriptor || formatMoneyDescriptor(cost))
}

export const formatMoney = (money, precision = 1, absolute = false, descriptor = null, currency = 'USD') => {
  const currencies = {
      'USD': "$",
      'GBP': "£",
      'EUR': "€",
  };

  let formattedMoney = null;

  if (descriptor === '' || (money >= 0 && money < 1000) || (money <= 0 && money > -1000)) {
      formattedMoney = round(money, precision);
  }
  else if (descriptor === 'k' || (money >= 1000 && money < 1000000) || (money <= -1000 && money > -1000000)) {
      formattedMoney = round(money / 1000, precision);
  }
  else if (descriptor === 'm' || (money >= 1000000 && money < 1000000000) || (money <= -1000000 && money > -1000000000)) {
      formattedMoney = round(money / 1000000, precision);
  }
  else if (descriptor === 'b' || (money >= 1000000000 && money < 1000000000000) || (money <= -1000000000 && money > -1000000000000)) {
      formattedMoney = round(money / 1000000000, precision);
  }
  else {
      return '';
  }

  if (absolute)
    formattedMoney = Math.abs(formattedMoney)

  if (absolute || money >= 0)
      return currencies[currency] + formattedMoney.toString();
  return '-' + currencies[currency] + (formattedMoney * -1).toString();
}

export const formatMoneyDescriptor = (money) => {

  if ((money >= 0 && money < 1000) || (money <= 0 && money > -1000)) {
      return '';
  }
  else if ((money >= 1000 && money < 1000000) || (money <= -1000 && money > -1000000)) {
      return 'k';
  }
  else if ((money >= 1000000 && money < 1000000000) || (money <= -1000000 && money > -1000000000)) {
      return 'm';
  }
  else if ((money >= 1000000000 && money < 1000000000000) || (money <= -1000000000 && money > -1000000000000)) {
      return 'b';
  }
  return null;
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const capitalise = (text) => {
  if (typeof text != 'string')
    return
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const round = (value, decimalPlaces = 0) => {
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(value * factorOfTen) / factorOfTen || 0
}
