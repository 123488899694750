import { Button, Center, Flex, Select, Spinner, Stat, StatLabel } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import Card from '../../General/Card'
import EditableICP from './EditableICP'
import { setCarbonPrice, setCurrency as setOrgCurrency } from '../../../services/APICalls'

const CarbonPriceControl = (props) => {

  const auth = useContext(props.context)

  const oldCarbonPrice = auth.orgData.carbon_price
  const oldCurrency = auth.orgData.carbon_price_currency

  const [newPrice, setNewPrice] = useState(oldCarbonPrice)
  const [newCurrency, setCurrency] = useState(oldCurrency)

  const [ready, setReady] = useState(true)

  const changeHasBeenMade = () => {
    return (!newPrice || newPrice.toString() === oldCarbonPrice.toString()) && (!newCurrency || newCurrency === oldCurrency)
  }

  const update = () => {
    setReady(false)
    Promise.all([
      setCarbonPrice(auth.token, auth.orgId, newPrice || oldCarbonPrice),
      setOrgCurrency(auth.token, auth.orgId, newCurrency || oldCurrency),
    ]).then(() => setReady(true))
  }

  if (!oldCarbonPrice || !oldCurrency)
    return <Spinner />

  return (
    <Card w='100%'>
      <Center>
        <Flex direction={'column'} gap='2em'>
          <Stat>
            <StatLabel fontSize={"16px"}> Your internal carbon price </StatLabel>
            <Flex alignItems={'center'} gap='0.5em' width='600px'>
              <EditableICP default={oldCarbonPrice} currencySymbol={oldCurrency} update={setNewPrice} />
            </Flex>
          </Stat>

          <Flex direction='column' gap='0.5em'>
            Currency
            <Select defaultValue={oldCurrency} onChange={e => setCurrency(e.target.value)} variant='filled'>
              <option value='USD'> $ - United States Dollars </option>
              <option value='EUR'> € - Euros </option>
              <option value='GBP'> £ - Great British Pounds </option>
            </Select>
          </Flex>

          <Button disabled={changeHasBeenMade()} colorScheme={'sky'} onClick={update} isLoading={!ready}>
            Update internal carbon price
          </Button>
        </Flex>
      </Center>
    </Card>
  )
}

export default CarbonPriceControl