import { Box, Button, Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'

const ProgressCard = (props) => {

  const baselineYear = props.org.year_baseline

  const reduction = props.lastYearEmissions && props.baselineEmissions ?
    Math.round((1 - props.lastYearEmissions / props.baselineEmissions) * 100) : null

  const getChangeDescriptor = () => {
    if (Number(reduction) < 0)
      return "Increased"
    return "Reduced"
  }

  const getMessage = () => {
    if (reduction == null && baselineYear)
      return "Let's get going 👏"
    if (baselineYear && baselineYear < new Date().getFullYear() - 1) {
      if (0 < Number(reduction))
        return "Celebrate every step of the way 👏"
      return "There's time to get on track 👏"
    }
    return "Let's get going 👏"
  }

  const Content = () => {
    if (baselineYear)
      return <>
        <Flex alignItems={"start"}>
          <Box>
            <Text
              textTransform={"uppercase"}
              lineHeight={"20px"}
            > {getMessage()} </Text>
            <Text
              fontSize={"14px"}
              textColor={"sky.100"}
              lineHeight={"20px"}
            > {getChangeDescriptor()} emissions since {baselineYear} </Text>
          </Box>

          <Spacer />
        </Flex>

        <Spacer />

        <Text
          mt={"8px"}
          fontSize={"32px"}
          lineHeight={"40px"}
          color={"ice.50"}
        >
          {reduction != null ? Math.abs(reduction) : <>&mdash;</>}%
        </Text>

        <Spacer />
      </>
    return <Flex direction={"column"} width="fit-content" h={"100%"}>
      <Text
        textTransform={"uppercase"}
        lineHeight={"20px"}
      > {getMessage()} </Text>
      <Text
        fontSize={"14px"}
        textColor={"sky.200"}
        lineHeight={"20px"}>
        Declare your baseline year
      </Text>

      <Spacer />

      <a href="mailto:enable@expectai.com" target={"_blank"}>
        <Button width={"fit-content"}>
          Contact the data enablement team
        </Button>
      </a>
    </Flex>

  }

  return (
    <Flex
      h="100%" w="100%"
      borderRadius={"8px"}
      padding={"20px"}
      direction={"column"}

      backgroundColor={"sky.500"}
      backgroundPosition={"90%"}

      backgroundRepeat={"no-repeat"}
      backgroundSize={"129px auto"}
      backgroundImage={"/images/dashboard/winners.svg"}
    >
      <Content />
    </Flex>
  )
}
export default ProgressCard
