import { Box, Center, Flex, SimpleGrid, Spacer, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useEmissions, useInitiatives, usePopulatedYears, useTargets } from '../../services/APIHooks'
import Card from '../General/Card'
import ProgressCard from './Navigate/ProgressCard'
import OverallTargetCard from './Navigate/OverallTargetCard'
import MiniScopeOverview from './ScopeOverview/MiniScopeOverview'
import { Link } from 'react-router-dom'
import { showForDemoOnly } from '../../helpers/demoManagement'
import MiniInitiativeCard from '../Roadmaps/Initiatives/MiniInitiativeCard'
import ScrollableInsights from './WhatsNew/ScrollableInsights'

const Dashboard = (props) => {

  const auth = useContext(props.context)

  // const Welcome = () => {
  //   return <Flex alignItems={'center'}>
  //       <Text> Welcome, {auth.name.split(' ')[0]} </Text>
  //       <Spacer/>
  //       <OrgLogo orgId={auth.orgId}/>
  //     </Flex>
  // }

  const Navigate = () => {
    const targets = useTargets(auth.token, auth.orgId)
    const baselineEmissions = useEmissions(auth.token, auth.orgId, undefined, auth.orgData.year_baseline)
    const lastYear = new Date().getFullYear() - 1
    const lastYearEmissions = useEmissions(auth.token, auth.orgId, undefined, lastYear)

    return <Card padding={0}>
      <Box padding={"20px"}>
        <Text> Navigate </Text>
        <Text className='subtitle' mt="8px"> Chart your course to achieving Net Zero </Text>

        <SimpleGrid columns={2} mt='20px' gap={"24px"} h="170px">
          <OverallTargetCard targetData={targets} />
          <ProgressCard org={auth.orgData}
            baselineEmissions={baselineEmissions.emissions_total}
            lastYearEmissions={lastYearEmissions.emissions_total}
          />
        </SimpleGrid>
      </Box>
    </Card >
  }

  const EmissionsScopeBreakdown = () => {

    const populatedYears = usePopulatedYears(auth.token, auth.orgId)

    let yearToShow = null
    if (populatedYears !== null) {
      const lastYear = new Date().getFullYear() - 1
      if (populatedYears.length === 0)
        yearToShow = -1
      else if (populatedYears.includes(lastYear))
        yearToShow = lastYear
      else
        yearToShow = Math.max(...populatedYears)
    }

    const YearsLoadedContent = () => {
      const emissions = useEmissions(auth.token, auth.orgId, null, yearToShow)

      return <Link to={"/emissions"}>
        <MiniScopeOverview year={yearToShow} emissions={emissions} currency={auth.orgData.carbon_price_currency} />
      </Link>
    }

    if (yearToShow !== -1)
      return <YearsLoadedContent />

    return <Card style={{ height: "440px" }}>
      <Center h="100%">
        <Flex direction={"column"} gap="1em" textAlign={"center"}>

          <Text>
            Emissions data is not available
          </Text>
          <Text>
            Contact <u><a href="mailto:enable@expectai.com" target={"_blank"} rel="noreferrer">enable@expectai.com</a></u> to
            add the necessary data for this area
          </Text>

        </Flex>
      </Center>
    </Card>
  }

  const NewInitiatives = () => {
    let { initiativeData } = useInitiatives(auth.token, auth.orgId)

    return <Box>
      <Flex>
        <Text> Your partners released new initiatives </Text>
        <Spacer />
      </Flex>

      <Flex width={"100%"} overflowX="scroll" gap={"24px"} pb="20px">
        {initiativeData?.slice(30, 40).map((initiative, index) =>
          <Box key={index} minW={"380px"} w={"380px"}>
            <MiniInitiativeCard
              currency={auth.orgData.carbon_price_currency}
              initiative={Object(initiative).Initiative}
              url={'/roadmap/'}
            />
          </Box>
        )}
      </Flex>
    </Box>
  }

  return (
    <Flex direction={'column'} gap='2em'>
      {/* <Welcome/> */}
      <Navigate />
      <EmissionsScopeBreakdown />
      <ScrollableInsights auth={auth} />
      {showForDemoOnly(<>
        <NewInitiatives /></>, auth
      )}
    </Flex>
  )
}

export default Dashboard