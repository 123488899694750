import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { Box, Center, Heading, Image, SimpleGrid } from '@chakra-ui/react'
import Card from '../General/Card'

const Reports = (props) => {

  const auth = useContext(props.context);
  
  const showForDemoOnly = (element) => {
    if (auth.orgData.is_demo)
      return element
    return
  }

  return (
    <Box>
      <Box>
        <Center marginTop='100px'>
          <Box fontWeight='bold' fontSize="2em">Select a report to generate</Box>
        </Center>
      </Box>
      <SimpleGrid  minChildWidth='400px' spacing="40px" marginTop='100px'>        
        <Link to='/reports/ghg-protocol'>
          <Card padding={"30px"} textAlign={"center"}>
            <Heading size='h6'>Greenhouse Gas Protocol</Heading>
            <Center>
              <Image marginTop='25px' src='/images/reports/ghg-protocol.png' alt='Greenhouse Gas Protocol' height='100px' />
            </Center>
          </Card>
        </Link>
        <Link to={'/reports/TCFD'} >
          <Card padding={"30px"} textAlign='center'>
            <Heading size='h6'>Task Force for Climate-Related Financial Disclosures</Heading>
            <Center>
              <Image marginTop='25px' src='/images/reports/tcfd.png' alt='TCFD: Task Force for Climate-Related Financial Disclosures' height='100px' />
            </Center>
          </Card>
        </Link>
        
        {
          showForDemoOnly(<>
            <Link to={'/reports/SECR'}>
              <Card padding={"30px"} textAlign='center'>
                <Heading size='h6'>Streamlined Energy and Carbon Reporting</Heading>
                <Center>
                  <Image marginTop='25px' src='/images/reports/uk.png' alt='SECR: Streamlined Energy and Carbon Reporting' height='100px' />
                </Center>
              </Card>
            </Link>
            <Link to={'/reports/NFRD'}>
              <Card padding={"30px"} textAlign='center'>
                <Heading size='h6'>Non-Financial Reporting Directive</Heading>
                <Center>
                  <Image marginTop='25px' src='/images/reports/eu.png' alt='NFRD: Non-Financial Reporting Directive' height='100px' />
                </Center>
              </Card>
            </Link>         
            <Link to={'/reports/GLEC'}>
              <Card padding={"30px"} textAlign='center'>
                <Heading size='h6'>Global Logisitics Emissions Council</Heading>
                <Center>
                  <Image marginTop='25px' src='/images/reports/glec.png' alt='GLEC: Global Logisitics Emissions Council Framework' height='100px' />
                </Center>
              </Card>
            </Link>                   
            <Link to='/reports/pcaf'>
              <Card padding={"30px"} textAlign={"center"}>
                <Heading size='h6'>Partnership for Carbon Accounting Financials</Heading>
                <Center>
                  <Image marginTop='25px' src='/images/reports/pcaf.png' alt='Partnership for Carbon Accounting Financials' height='100px' />
                </Center>
              </Card>
            </Link>
          </>
          )
        }          

      </SimpleGrid>
    </Box>
  )

}

export default Reports