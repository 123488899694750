import { IconButton, Flex, ListItem, UnorderedList, Image } from '@chakra-ui/react'
import React from 'react'
import { deleteFileFromDB } from '../../../services/APICalls'

const FileList = (props) => {

  const auth = props.auth
  const files = props.files

  const deleteFile = (file) => {
    deleteFileFromDB(auth.token, auth.orgId, file.original_name || file.name, file.actual_name).then(response => {
      if (typeof response === 'string' && response.startsWith('Deleted ')) {
        const copy = [...files].filter(emissionsFile => emissionsFile.actual_name !== file.actual_name)
        props.setFiles(copy)
        props.alertSuccess(true)
      }
      else
        props.alertError(true)
    })
  }

  const Icon = (props) => {
    return <IconButton
      aria-label='delete'
      icon={<Image src='/icons/smallCancel.svg' />}
      onClick={() => deleteFile(props.file)}
      variant='ghost'
      margin='12px'
      h='24px'
      minW='24px'
    />
  }

  return <Flex direction={'column'} padding='1em'>
    { !files.length ? <>&mdash;</> : <></> }
    <UnorderedList>
      {files.map((file, index) => (<ListItem key={index}>
        {file.name || file.original_name}
        <Icon file={file}/>
      </ListItem>))}
    </UnorderedList>
  </Flex>

}

export default FileList