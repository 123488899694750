import React, { useState } from 'react'
import { Box, Button, Divider, Flex, Select, SimpleGrid, Spacer, Spinner, useDisclosure } from '@chakra-ui/react'
import { displayCarbon } from '../../helpers/formatValues'
import DataTable from '../General/DataTable'
import Card from '../General/Card'
import TopEmittersChart from './Charts/TopEmittersChart'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'
import DataNotAvailable from '../Blank/DataNotAvailable'
import ExpandModalButton from '../General/ExpandModalButton'
import AccuracyModal from './Accuracy/AccuracyModal'

const EmissionsSourceBreakdown = (props) => {

  const [accuracyData, setAccuracyData] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  let sourceData = props.sourceData

  if (typeof sourceData == "number") {
    return <DataNotAvailable/>
  }

  sourceData = sourceData.filter(source => source.emissions_total)
    .sort((a, b) => {
      return b.emissions_total - a.emissions_total
    })

  let links
  if (!props.scope) {
    sourceData.map(source => 'suppliers/' + source.name?.toLowerCase())
  } else if (props.scope !== 3) {
    sourceData.map(source => 'sources/' + source.name?.toLowerCase())
  } else {
    sourceData.map(source => 'categories/' + source.primary_category_id)
  }

  return (
    <SimpleGrid gap={"20px"} minChildWidth="440px">
      <Card>
        {sourceData.length ?
          <TopEmittersChart
            scope={props.scope}
            sourceData={sourceData}
            type={props.type}
          />
            :
          <Flex minH={"523px"} justifyContent="center" alignItems={"center"}>
            <Spinner/>
          </Flex>
        }
      </Card>

      <Card padding={0}>
        <Flex direction={"column"} height={"100%"}>
          <Box padding={"20px 20px 0 20px"}>
            <DataTable
              firstHeadingIsTitle
              headings={['Emissions sources', 'CO2e', 'Cost', '']}
              rows={sourceData.slice(0, 4).map((source) => [
                source.emissions_source_name || source.name,
                displayCarbon(source.emissions_total),
                source.emissions_total_cost_display,
                <ExpandModalButton enable onClick={() => {setAccuracyData(source); onOpen()}}/>
              ])}
              links={links}
            />
          </Box>

          <Spacer />

          <Divider color={"ui.300"} margin={"20px 0"}/>
          <Box padding={"0 20px 20px 20px"}>

            <Flex>
              <Select display={"none"} width={"150px"} border={0} >
                <option>Monthly</option>
              </Select>
              <Spacer />
              <Link to={"categories"}>
                <Button
                variant={"ghost"} color={"rgba(0, 168, 240, 0.9)"} rightIcon={<ChevronRightIcon />}>
                  View all categories
                </Button>
              </Link>
            </Flex>

          </Box>
          </Flex>


      </Card>
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose}/>
    </SimpleGrid>
  )
}

export default EmissionsSourceBreakdown