import React, { useContext } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { displayCarbon } from '../../helpers/formatValues'
import { useTopEmissionSources } from '../../services/APIHooks'
import DataTable from '../General/DataTable'

const AllScopeCategories = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const params = useParams()

  const emissionsSources = useTopEmissionSources(auth.token, auth.orgId, params.scope, outletContext.year, 'ghgprotocol')

  // const links = emissionsSources.map(source => '../scope' + params.scope + '/categories/' + source.primary_category_id)

  return <DataTable
    headings={['Category', 'CO2e', 'Cost']}
    rows={emissionsSources.map((source) => [
      source.emissions_source_name || source.name,
      displayCarbon(source.emissions_total),
      source.emissions_total_cost_display
    ])}
  />
}

export default AllScopeCategories