import { Flex, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import NavigationBreadcrumbs from '../Navigation/NavigationBreadcrumbs'
import CompanyCard from './Connections/CompanyCard'
import { demoAdapters } from './demoAdapters'

const DataAdapters = (props) => {

  const adapters = demoAdapters

  return (<Flex direction="column" gap="2em">
    <NavigationBreadcrumbs/>

    <SimpleGrid minChildWidth={"425px"} gap="24px">
      {adapters.map((adapter, index) =>
        <CompanyCard key={index} company={adapter}/>
      )}
    </SimpleGrid>
  </Flex>
  )
}

export default DataAdapters