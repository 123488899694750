import React from 'react'
import Highcharts, { chart } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Flex, Text } from '@chakra-ui/react'
import { defaultDonutChartSettings } from '../../../helpers/emissionsChartProvider'
import { displayCarbon, round } from '../../../helpers/formatValues'
import theme from '../../../theme'
import { objectToArray } from '../../../helpers/objectToArray'

const TopEmittersChart = (props) => {

  const sourceData = props.sourceData

  const sumEmissions = (total, emitter) => total + (emitter.emissions_total ?? emitter.y)

  const total = sourceData.map(source => source.emissions_total).reduce((total, a) => total + a, 0)
  const minimumProportionOfTotal = 0.025

  const allEmitters = []
  const topEmitters = []
  const other = []
  sourceData.forEach(source => {
    if(source.emissions_total > (minimumProportionOfTotal * total))
      topEmitters.push({
        name: source.name,
        y: source.emissions_total
      })
    else
      other.push(source)
  })

  allEmitters.push(...topEmitters)
  if (other.length) {
    const otherEmittersTotal = other.reduce(sumEmissions, 0)

    allEmitters.push({
      name: 'Other',
      y: otherEmittersTotal,
      // emissions_total_cost: otherEmittersTotalCost
    })
  }

  const seriesTotal = allEmitters.reduce(sumEmissions, 0)
  const percentageContribution = round(100 * topEmitters.reduce(sumEmissions, 0) / total, 1).toString() + '%'

  const chartOptions = defaultDonutChartSettings(objectToArray(props.scope ? theme.colors['scope' + props.scope] : theme.colors.ice))
  // chartOptions.chart.height = 350
  chartOptions.series = [{
    data: allEmitters
  }]

  chartOptions.legend.align = 'left'
  chartOptions.legend.verticalAlign = 'middle'

  // chartOptions.title.text = '<span style="font-size: 32px;">' +
  //   displayCarbon(seriesTotal) + '</span><br/><span style="font-size: 16px; color: rgba(255, 255, 255, 0.42);">'
  //   + '<br/><span style="font-size: 120px; visibility: hidden">spacinghack</span>'
  //   // TODO: find a way to keep title centered

  chartOptions.legend.useHTML = true
  chartOptions.legend.labelFormatter = function () {
    return '<p class="legendTitle">'
      + this.name + '</p> <p class="legendText">'
      + displayCarbon(this.y)
      + ' &mdash; '
      + round(100 * this.y / total, 1)
      + '%</p>'
  }

  chartOptions.plotOptions.pie.size = '200px'
  chartOptions.plotOptions.pie.center = ['50%', '105']

  const nameOfInstances = Number(props.scope) !== 3 ? 'sources' : 'categories'

  return (
    <>
      {props.type ?
        <>
          <Text>Suppliers CO2e emissions categories</Text>
          <Text className='subtitle'> A breakdown of your top suppliers by category </Text>
        </>
        :
        <>
          <Text> Scope {props.scope} top {nameOfInstances} </Text>
          <Text className='subtitle'> {topEmitters.length} {nameOfInstances} account for {percentageContribution} of your scope {props.scope} emissions </Text>
        </>
      }
      <Flex height={"100%"} w="100%" alignItems={"center"} justifyContent={"center"}>
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </Flex>
    </>
  )
}

export default TopEmittersChart