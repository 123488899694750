import { Flex, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { displayCarbon, displayCost } from '../../../helpers/formatValues'
import { useAssets, useAssetTransparency } from '../../../services/APIHooks'
import theme from '../../../theme'
import CenteredSpinner from '../../Blank/CenteredSpinner'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import DataTable from '../../General/DataTable'
import ExpandModalButton from '../../General/ExpandModalButton'
import AccuracyModal from '../Accuracy/AccuracyModal'
import BarChart from '../Charts/BarChart'

const LocationsOverview = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const { assetData, ready } = useAssets(auth.token, auth.orgId, outletContext.year)

  const locations = assetData.sort((a, b) => b.emissions_total - a.emissions_total)

  const Chart = () => {
    const chartData = locations.map(location => [
      location.location_name, location.emissions_total
    ])

    return <BarChart data={chartData} colours={theme.colors.aurora} />
  }

  const Table = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    // const [accuracyData, setAccuracyData] = useState({})

    const [openLocationId, setOpenLocationId] = useState()
    const {transparency, transparencyReady} = useAssetTransparency(auth.token, auth.orgId, outletContext.year, openLocationId)

    return <>
      <DataTable
        headings={['Location name', 'CO2e', 'Cost', '']}
        rows={locations.map(location => [
          location.location_name, displayCarbon(location.emissions_total),
          displayCost(location.emissions_total_cost),
          <ExpandModalButton onClick={() => { setOpenLocationId(location.location_id); onOpen() }}
              enable={location.accuracy} ready={ready}/>
        ])}
        links={locations.map(location => location.location_id.toString())}
      />
      <AccuracyModal isOpen={isOpen} data={transparency} loading={!transparencyReady} onClose={onClose} />
    </>
  }

  if (!locations.length && !ready)
    return <CenteredSpinner />

  if (Number(locations) === 204)
    return <DataNotAvailable />

  return <Flex direction={'column'} gap='2em'>
    <Chart />
    <Table />
  </Flex>
}

export default LocationsOverview