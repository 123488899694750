import React, { useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { defaultChartSettings } from '../../../helpers/roadmapChartProvider'
import { getEmissionsSeries } from '../../../helpers/emissionsChartProvider'
import { Flex, Spinner } from '@chakra-ui/react'

const EmissionsChart = (props) => {

  const [chartRef, setChartRef] = useState()

  const chartOptions = defaultChartSettings(props.period)

  chartOptions.series = getEmissionsSeries(props.lineData, props.scope, props.label || 'Supplier', props.type)
  chartOptions.legend.useHTML = true
  chartOptions.legend.labelFormatter = function () {
    return '<p class="legendTitle">'
    + this.name + '</p>'
  }
  // @ts-ignore
  // chartOptions.yAxis.min = null

  return props.lineData?.length ?
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}

      // @ts-ignore
      ref={setChartRef} />
    :
    <Flex h="400px" alignItems={"center"} justifyContent={"center"}>
      <Spinner />
    </Flex >
}

export default EmissionsChart