import { Flex, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { round } from '../../../helpers/formatValues'
import AlertIcon from './AlertIcon'

const WhatsNewCard = (props) => {

  const info = props.info

  // let borderColor = ''
  let background = 'ui.50'
  let accent = 'white'

  switch (info.type) {
    case 'success':
      background = 'success.low'
      // borderColor = 'success.medium'
      accent='green.200'
      break
    case 'warning':
      background = 'warning.low'
      // borderColor = 'warning.medium'
      accent = 'canary.200'
      break
    case 'danger':
      background = 'danger.low'
      // borderColor = 'danger.medium'
      accent = 'cherry.200'
      break
    default:
    case 'info':
      background = 'info.low'
      // borderColor = 'info.medium'
      accent = 'ice.200'
      break
  }

  return (
    <Flex
      minW="424px"
      w="424px"
      h="170px"

      bg={background}

      // borderWidth='1px'
      // borderColor={borderColor}
      borderRadius="8px"
      padding="20px"

      overflowY={'auto'}
    >
      <AlertIcon type={info.type} />
      <Flex direction={'column'} gap='20px'>

        <Flex direction={'column'}>
          <Text textTransform={'uppercase'} fontFamily='SF Pro Display' fontSize={'12px'} lineHeight='14px'>
            {info.title}
          </Text>

          <Text
            mt="4px"
            maxHeight={"48px"}
            fontSize='16px'
            textColor={accent}
          >
            {info.content}
          </Text>
        </Flex>

        <Flex>
          <Spacer />

          {info.measurement ?
            <Text
              fontSize={"32px"}
              lineHeight={"40px"}
              textColor={accent}
            >
              {round(info.measurement, 1)}{info.measurement_uom}
            </Text> : <></>
          }
        </Flex>
      </Flex>
    </Flex>
  )
}

export default WhatsNewCard