import React, { useContext } from 'react'
import { Box, Flex, Heading, Spacer, Select, SimpleGrid, Spinner, Stat, StatHelpText, StatNumber, Text, Table, Thead, Tr, Td, Tbody, Th } from '@chakra-ui/react'
import DataTable from '../General/DataTable'
import Card from '../General/Card'
import KPI from '../General/KPI'
import { useInternalCarbonPrices, useOrganisation } from '../../services/APIHooks'
import { capitalise, displayCost } from '../../helpers/formatValues'
import IndustryICPChart from './Charts/IndustryICPChart'
import LinkExternalButton from '../General/LinkExternalButton'

const InsightsCarbonPrices = (props) => {

  const auth = useContext(props.context)

  // Grab the current
  const organisation = useOrganisation(auth.token, auth.orgId)
  const organisationInternalCarbonPrice = (organisation.carbon_price) ? "$" + organisation.carbon_price + " " + organisation.carbon_price_currency + "/tCO2e" : "—"

  // TODO make industry id dynamic using organisation.industry_id
  const internalCarbonPrices = useInternalCarbonPrices(auth.token, 1)
  var totalPrice = 0
  var numPrices = 0
  internalCarbonPrices.internalCarbonPricesData.forEach((icp) => {
    if (icp.carbon_price_2020 > 0) {
      totalPrice = totalPrice + icp.carbon_price_2020
      numPrices++
    }
  })
  const averageCarbonPrice = (numPrices > 0) ? "$" + Math.round(totalPrice / numPrices) + " USD/tCO2e in 2020" : "—"

  // The scope isn't straight-forward in the ICP tables, so this will translate the array values.
  const scopeRef = new Array(); scopeRef[1] = "1"; scopeRef[2] = "1 + 2"; scopeRef[3] = "1 - 3"

  const Header = () => {
    return <>
      <Flex>
        <Box>
          <Heading> Internal Carbon Prices </Heading>
        </Box>

        <Spacer />

        <Box width={"15em"}>
          <Select defaultValue="Energy">
            <option>Energy</option>
          </Select>
        </Box>
      </Flex>

      <Text marginTop="20px">
        Internal Carbon Pricing is a mechanism for you to put a value on your organistion's CO2e emissions
        in order to calculate future carbon risk into your business decisions.
      </Text>
    </>
  }

  const OrgICP = () => {
    return <>
      <SimpleGrid columns={3} spacing={"20px"} marginTop="40px">
        <Box/>
        <KPI
          label={"Current Internal Carbon Price"}
          value={organisationInternalCarbonPrice}
        />
        <Box/>
      </SimpleGrid>
    </>
  }

  const BenchmarkICPs = () => {
    return <>
      <SimpleGrid columns={3} spacing={"20px"} marginTop="40px">
        <KPI
          label={"United Nations Global Compact"}
          value="$100 USD/tCO2e in 2020"
          url="https://www.unglobalcompact.org/news/3361-04-22-2016"
          urlType="external"
        />
        <KPI
          label={"NGFS"}
          value="$159 USD/tCO2e by 2030"
          url="https://www.ngfs.net/ngfs-scenarios-portal/"
          urlType="external"
        />
        <KPI
          label={"Industry average"}
          value={averageCarbonPrice}
        />
      </SimpleGrid>
    </>
  }

  const IndustryICPsGraph = () => {
    return <Box mt="2em">
      <Card>
        <Text> Internal Carbon Prices in the Energy Industry </Text>
        <IndustryICPChart icpData={internalCarbonPrices.internalCarbonPricesData}/>
      </Card>
    </Box>
  }

  const IndustryICPsTable = () => {
    return <>
      <Box mt="2em">
          {internalCarbonPrices.internalCarbonPricesData.length ?
            <DataTable
              headings={['Company', 'Carbon Price Type', '2020', '2030', '2040', '2050', 'Scope', 'Source']}
              rows={internalCarbonPrices.internalCarbonPricesData.map((icp) => [
                icp.name,
                capitalise(icp.carbon_price_type),
                displayCost(icp.carbon_price_2020),
                displayCost(icp.carbon_price_2030),
                displayCost(icp.carbon_price_2040),
                displayCost(icp.carbon_price_2050),
                (icp.scope > 1) ? scopeRef[icp.scope] : "—",
                <LinkExternalButton url={icp.source} />
              ])}
            />
            :
            <Flex w="100%" justifyContent={"center"} alignItems="center">
              <Spinner />
            </Flex>
          }
      </Box>
    </>
  }

  return (
    <>
      <Header />
      <OrgICP />
      <BenchmarkICPs />
      <IndustryICPsGraph />
      <IndustryICPsTable />
    </>
  )

}

export default InsightsCarbonPrices
