import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { useAssetBreakdown, useAssets } from '../../../services/APIHooks'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsKPIs from '../EmissionsKPIs'

const EmissionsAssets = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const params = useParams()

  const locationId = params.locationId
  const systemId = params.systemId
  const unitId = params.unitId
  const equipmentId = params.equipmentId

  const {assetData} = useAssets(auth.token, auth.orgId, outletContext.year, locationId, systemId, unitId, equipmentId)

  const Overview = () => {
    return <Box mt="2em">
      <EmissionsKPIs
        currency={auth.orgData.carbon_price_currency}
        emissions={assetData?.emissions_total}
        cost={assetData?.emissions_total_cost_display}
        accuracy={assetData?.accuracy || <>&mdash;</>}
        noNBA
      />
    </Box>
  }

  const Chart = () => {
    if (!assetData)
      return <></>
    return <Card>
      <Flex>
        <Box>
          <Text> Monthly CO2e emissions for {assetData.name || <>&mdash;</>}</Text>
        </Box>
      </Flex>
      <EmissionsChart
        period='month'
        label={assetData.name || 'asset'}
        type={'location'}
        scope={props.scope}
        lineData={Object(assetData).emissions_monthly || []}
      />
    </Card>
  }

  const Breakdown = () => {

    const assets = useAssetBreakdown(auth.token, auth.orgId, outletContext.year, locationId, systemId, unitId)
    if (equipmentId) return <></>

    let assetType = ''
    if (assets[0])
      assetType = assets[0].equipment_name ? 'Equipment' : 'System'

    let links
    if (equipmentId)
      links = null
    else if (unitId)
      links = assets.map(asset => 'equipment' + asset.equipment_id)
    else if (systemId)
      links = assets.map(asset => 'unit' + asset.unit_id)
    else if (locationId)
      links = assets.map(asset => 'systems' + asset.system_id)

    return <Box mt="2em">
      <DataTable
        headings={[assetType, 'CO2e', 'Cost']}
        rows={assets.map(asset => [
          asset.unit_name || asset.equipment_name,
          asset.emissions_total_display,
          asset.emissions_total_cost_display
        ])}
        links={links}
      />
    </Box>
  }

  return <Flex direction={"column"} gap="2em">
    <Overview />
    <Chart />
    <Breakdown />
  </Flex>
}

export default EmissionsAssets