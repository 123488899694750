import React from 'react'
import { SimpleGrid, useDisclosure } from '@chakra-ui/react'
import MiniInitiativeCard from "../Roadmaps/Initiatives/MiniInitiativeCard"
import { displayCarbon } from '../../helpers/formatValues'
import KPI from '../General/KPI'
import AccuracyModal from './Accuracy/AccuracyModal'


const EmissionsKPIs = (props) => {

  const { isOpen, onClose } = useDisclosure()

  let emissions = props.emissions
  const cost = props.cost

  if (typeof (emissions) == 'number')
    emissions = displayCarbon(emissions)

  const nextBestAction = props.noNBA || !props.nextBestAction? <></> :
    <MiniInitiativeCard
      currency={props.currency}
      initiative={props.nextBestAction}
      url={props.nextBestActionLink}
    />

  return (
    <>
      <SimpleGrid minChildWidth={"340px"} spacing={"20px"} textAlign={"center"}>
        <KPI
          label={"CO2e emissions"}
          value={emissions}
        />

        <KPI
          label={"Potential CO2e cost"}
          value={cost || <>&mdash;</>}
        />

        {props.extras?.map((extra, index) => (
          <KPI
            key={index}
            label={extra.label}
            value={extra.value}
          />
        ))}

        {nextBestAction}
      </SimpleGrid>
      <AccuracyModal isOpen={isOpen} onClose={onClose}/>
    </>
  )
}

export default EmissionsKPIs