import { Text } from '@chakra-ui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SubLink = (props) => {
  const location = useLocation()
  const isActive = location.pathname.endsWith(props.to)

  return (
    <Link to={props.to}>
      <Text
        background={isActive ? "primary.soft" : ""}

        fontSize={"15px"}
        paddingTop={"2px"}
        paddingBottom={"2px"}
        paddingLeft={"60px"}
        borderRadius={"4px"}
        color={isActive ? "onSurface.primary.high" : "grayscale.300"}
      >
        {props.children}
      </Text>
    </Link>
  )
}

export default SubLink