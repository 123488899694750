import React, { useContext } from 'react'
import { safeLog } from '../helpers/logging'
import { useScope3Emissions, useScope3Emitters,  useAssetEmissionBreakdown, useTopEmitters,
         useTopEmissionSources, useSourceEmissions, useSourcebreakdown, useSpecificAsset, useAssetBreakdown,
         useAssetTopInitiatives, useReportInfo, useReportTypes, useIntensities, useSuppliers, useVendors, useOrganisationPartners} from '../services/APIHooks'


const APITest = (props) => {

  const auth = useContext(props.context)
  const orgId = auth.orgId

  // const emissionsData = useMonthlyEmissions(auth.token, orgId, 1,2020)
  // safeLog('Asset Data ====', emissionsData)

  // const { S3emissionData } = useScope3Emissions(auth.token, orgId, 'ghgprotocol', 2020)
  // safeLog('Scope 3 Emissions ------------', S3emissionData)


  // const { S3emitterData } = useScope3Emitters(auth.token, orgId, 'ghgprotocol', 2020, 11, 1)
  // safeLog('Scope 3 Emitters ------------', S3emitterData)

  // const { assetData } = useAssetEmissionBreakdown(auth.token, orgId, 2020, 1, 18, 53)
  // safeLog('Asset Emissions Breakdown ------------', assetData)

  // const { emitterData } = useTopEmitters(auth.token, orgId, 1, 2020)
  // safeLog('Top equipment Emitters ------------', emitterData)

  // const { emissionSourceData } = useTopEmissionSources(auth.token, orgId, 2, 2020)
  // safeLog('Top Emission Sources ------------', emissionSourceData)

  // const { sourceTimeseriesData } = useSourceEmissions(auth.token, orgId, 1, 2020, 'combustion')
  // safeLog('Source Monthly Emissions ------------', sourceTimeseriesData)

  // const { sourceBreakdownData } = useSourcebreakdown(auth.token, orgId, 1, 2020, 'combustion')
  // safeLog('Source Top Emitters ------------', sourceBreakdownData)

  // const { assetInformation } = useSpecificAsset(auth.token, orgId, 1,18,53,18041)
  // safeLog('Asset Information ------------', assetInformation)

  // const { assetBreakdown } = useAssetBreakdown(auth.token, orgId, 1,18,53)
  // safeLog('Asset sub-asset breakdown ------------', assetBreakdown)

  // const { assetTopInitiatives } = useAssetTopInitiatives(auth.token, orgId, 1,18,53,18041)
  // safeLog('Asset Top Initiatives ------------', assetTopInitiatives)

  // const { reportDetails } = useReportInfo(auth.token, orgId, 'ghg_protocol',2020)
  // safeLog('Report Information ------------', reportDetails)

  // const { reportTypes } = useReportTypes(auth.token, orgId,)
  // safeLog('Available Report Types ------------', reportTypes)

  // const intensityData = useIntensities(auth.token, orgId, 2020,1,18,53)
  // safeLog('Asset Intensities ------------', intensityData)

  // const { supplierData } = useSuppliers(auth.token, orgId, 83)
  // safeLog('Supplier Data ------------', supplierData)

  // const { vendorData } = useVendors(auth.token, orgId, 49)
  // safeLog('Vendor Data ------------', vendorData)

  // const { partnerData } = useOrganisationPartners(auth.token, orgId,)
  // safeLog('Partner Data ------------', partnerData)

  // const orgEmissionData = useOrgEmissions(auth.token, orgId, 2020)
  // safeLog('Org Overview Data ------------', orgEmissionData)

  return (
    <div>APITest</div>
  )
}

export default APITest