import theme from '../theme'
import { displayCarbon, formatCarbonDescriptor, formatMoney, formatMoneyDescriptor } from './formatValues'

export const defaultChartSettings = (period, minRange) => {
  return {
    accessibility: {enabled: false},
    chart: {
      height: '400px',
      style: {
        fontFamily: 'Poppins',
        color: "white"
      },
      zoomType: 'x',
      type: 'spline',
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      itemStyle: {
        fontFamily: 'Poppins',
        color: 'white'
      },
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      lineColor: 'transparent',
      gridLineColor: theme.colors.ui['300'],
      gridLineWidth: '1px',

      labels: {
        enabled: true,
        style: {
          color: "#FFFFFF",
          fontFamily: "Poppins"
        },
      },
      minorTickLength: 0,
      tickLength: 0,
      title: {
        text: ''
      },
      minRange: 1,
    },
    yAxis: {
      visible: true,
      lineColor: 'transparent',
      gridLineColor: theme.colors.ui['300'],
      gridLineWidth: '1px',
      title: {
        text: '',
        enabled: false
      },
      labels: {
        enabled: true,
        style: {
          color: '#FFFFFF',
          fontFamily: 'Poppins'
        },
        formatter: yAxisFormatter,
      },
      min: 0,
      minRange: minRange || 100,
    },
    tooltip: {
      followPointer: true,
      formatter: getTooltipFormatter(period),
    },
    plotOptions: {
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        }
      },
      series: {
        marker: {
          enabled: false
        },
        label: {
          connectorAllowed: false
        },
        pointInterval: 1, // one year
      },
    },
  }
}

export const getTrajectorySeries = (roadmapData, scope) => {

  const roadmapLine = scope ? roadmapData['s' + scope] : roadmapData['combined']

  if (!roadmapLine.length)
    return []

  return [
    {
      name: scope ? 'Current scope ' + scope + ' trajectory' : 'Current Trajectory',
      color: '#FFAA00',
      dashStyle: 'dash',
      lineWidth: 2,
      visible: true,
      data: roadmapLine.map(point => {
        return {
          x: new Date(point.date),
          y: Number(point.base_emissions)
        }
      })
    },
    {
      name: scope ? 'Scope ' + scope + ' roadmap trajectory' : 'Roadmap Trajectory',
      color: '#00C3FF',
      visible: true,
      lineWidth: 3,
      data: roadmapLine.map(point => {
        return {
          x: new Date(point.date),
          y: Number(point.planned_emissions)
        }
      })
    },
  ]
}

export const getScopeTargets = (targetData, scope) => {
  if (scope)
    return targetData.filter(target => target.scope === scope)
  return []
}

const yAxisFormatter = (event) => {
  if (event.chart.costMode) {
    // @ts-ignore
    return formatMoney(event.value) + formatMoneyDescriptor(event.value)
  }
  const chartUnit = formatCarbonDescriptor(event.chart.yAxis[0].max)
  return displayCarbon(event.value, 2, chartUnit)
}

const getTooltipFormatter = (period) => {
  return function tooltipFormatter() {
    let tooltipHtml = new Date(this.x).toLocaleDateString('en-gb', {
      day: (period === 'day') ? 'numeric' : undefined,
      month: 'short', year: 'numeric'
    })

    const allSeries = this.series.chart.series

    const valueFormatter = this.series.chart.costMode ?
      // @ts-ignore
      (value) => formatMoney(value) + formatMoneyDescriptor(value)
      :
      (value) => displayCarbon(value)

    allSeries.forEach((series, index) => {
      const closestDataPoint = series.data.reduce((prevPoint, currentPoint) => {
        return (Math.abs(currentPoint.x.getTime() - this.x)
          < Math.abs(prevPoint.x.getTime() - this.x.getTime())) ?
          currentPoint : prevPoint
      })
      const yDataValue = closestDataPoint.y

      if (this.series.index === index || this.series.options.index === index) {
        tooltipHtml += '<br/> <b>' + series.name + ': ' + valueFormatter(yDataValue) + '/' + (period || 'month') + '</b>'
      } else if (this.x < series.xData[series.xData.length - 1]) {
        tooltipHtml += '<br/>' + series.name + ': ' + valueFormatter(yDataValue) + '/' + (period || 'month')
      }
    })

    return tooltipHtml
  }
}
