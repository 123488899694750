import { Box, Center, Circle, Flex, Image, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import PercentageDonut from '../../General/PercentageDonut'

const DataMethod = (props) => {
  return (
    <Box w='100%' border='1px solid' borderColor={'ui.500'} borderRadius='6px' padding='10px'>
      <Flex gap='1em' alignItems={'center'}>
        <Circle size='50px' border='3px solid' borderColor={'ui.500'}>
          {props.index}
        </Circle>
        <Text> {props.name} </Text>

        <Spacer />

        <SimpleGrid columns={3}>
          <VStack>
            <Text> Accuracy </Text>
            <PercentageDonut value={90}/>
          </VStack>

          <VStack>
            <Text> Format </Text>
            <Center h='100px'>
              <Image h='50px' src='/icons/filetypes/csv.svg'/>
            </Center>
          </VStack>

          <VStack>
            <Text> Example </Text>
            <Center h='100px'>
              <Image h='50px' src='/icons/filetypes/excel.svg'/>
            </Center>
          </VStack>
        </SimpleGrid>
      </Flex>
    </Box>
  )
}

export default DataMethod