import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { showForDemoOnly } from '../../helpers/demoManagement'
import OrgLogo from './OrgLogo'
import SideNavLink from './SideNavLink'
import SubLink from './SubLink'

const SideNav = (props) => {

  const auth = useContext(props.context)
  const location = useLocation()

  // Manage the accordion
  const accordionItems = [
    '/emissions',
    '/roadmap',
    '/reports',
    '/insights',
    '/marketplace',
    '/data'
  ]
  let startIndex = -1
  accordionItems.forEach((item, index) => {
    if (location.pathname.includes(item))
      startIndex = index
  })
  const [accordionIndex, setAccordion] = useState(
    startIndex
  )

  return (
    <Flex
      direction={"column"}
      h="100%"
      position="fixed"
      bg="ui.100"
      width="230px"
      color={"white"}
      overflowY={"auto"}
      overflowX={"hidden"}
      p="5px"
      className="sideNav"
    >
      {/* <Box paddingLeft={"8px"} paddingTop={"16px"} paddingBottom={"32px"}>
        <Image src="/wideLogo.svg" />
      </Box> */}

      <OrgLogo orgId={auth.orgId} expect />

      <Accordion index={accordionIndex}>

        <AccordionItem border={0} onClick={() => setAccordion(-1)}>
          <SideNavLink url={"/dashboard"} icon={'/icons/navigation/dashboard.svg'}> Dashboard </SideNavLink>
        </AccordionItem>

        <AccordionItem border={0} onClick={() => setAccordion(0)}>
          <AccordionButton padding={0} >
            <Box width={"100%"} textAlign='left'>

              <SideNavLink url={"/targets"} icon={'/icons/navigation/targets.svg'}> Targets </SideNavLink>
            </Box>
          </AccordionButton>
          <AccordionPanel padding={0}>
            {/* <SubLink to="/targets"> Targets </SubLink> */}
            <SubLink to="/targets/carbon-price"> Carbon price </SubLink>
          </AccordionPanel>
        </AccordionItem>


        <AccordionItem border={0} onClick={() => setAccordion(1)}>
          <h2>
            <AccordionButton padding={0} >
              <Box width={"100%"} textAlign='left'>
                <SideNavLink url={"/emissions"} icon={'/icons/navigation/emissions.svg'}>
                  Emissions
                </SideNavLink>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <SubLink to="/emissions/scope1"> Scope 1 </SubLink>
            <SubLink to="/emissions/scope2"> Scope 2 </SubLink>
            <SubLink to="/emissions/scope3"> Scope 3 </SubLink>
            <SubLink to="/emissions/locations"> Locations </SubLink>
            <SubLink to="/emissions/products"> Products </SubLink>
            <SubLink to="/emissions/suppliers"> Suppliers </SubLink>
            {showForDemoOnly(<SubLink to="/emissions/customers"> Customers </SubLink>, auth)}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border={0} onClick={() => setAccordion(2)}>
          <h2>
            <AccordionButton padding={0}>
              <Box flex='1' textAlign='left'>
                <SideNavLink url={"/roadmap"} icon={'/icons/navigation/roadmap.svg'}> Roadmaps </SideNavLink>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <SubLink to="/roadmap/scope1"> Scope 1 </SubLink>
            <SubLink to="/roadmap/scope2"> Scope 2 </SubLink>
            <SubLink to="/roadmap/scope3"> Scope 3 </SubLink>
            <SubLink to="/roadmap/targets"> Targets </SubLink>
            <SubLink to="/roadmap/locations"> Locations </SubLink>
            {
              showForDemoOnly(<>
                <SubLink to="/roadmap/products"> Products </SubLink>
                <SubLink to="/roadmap/suppliers"> Suppliers </SubLink>
                <SubLink to="/roadmap/customers"> Customers </SubLink>
              </>
                , auth)
            }
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border={0} onClick={() => setAccordion(3)}>
          <h2>
            <AccordionButton padding={0} >
              <Box flex='1' textAlign='left'>
                <SideNavLink url={"/reports"} icon={'/icons/navigation/reports.svg'}> Reports </SideNavLink>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <SubLink to="/reports/ghg-protocol"> GHG Protocol </SubLink>
            <SubLink to="/reports/tcfd"> TCFD </SubLink>
            {showForDemoOnly(<SubLink to="/reports/secr"> SECR </SubLink>, auth)}
            {showForDemoOnly(<SubLink to="/reports/nfrd"> NFRD </SubLink>, auth)}
            {showForDemoOnly(<SubLink to="/reports/glec"> GLEC </SubLink>, auth)}
            {showForDemoOnly(<SubLink to="/reports/pcaf"> PCAF </SubLink>, auth)}
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem border={0} onClick={() => setAccordion(4)}>
          <h2>
            <AccordionButton padding={0} >
              <Box flex='1' textAlign='left'>
                <SideNavLink url={"/insights"} icon={'/icons/navigation/insights.svg'}> Insights </SideNavLink>
              </Box>
            </AccordionButton>
          </h2>
          <AccordionPanel padding={0}>
            <SubLink to="/insights/carbon-index"> Carbon Index </SubLink>
            {showForDemoOnly(<SubLink to="/insights/carbon-prices"> Carbon Prices </SubLink>, auth)}
          </AccordionPanel>
        </AccordionItem>

        {
          showForDemoOnly(<>
            <AccordionItem border={0} onClick={() => setAccordion(5)}>
              <AccordionButton padding={0}>
                <Box flex='1' textAlign={'left'}>
                  <SideNavLink url={"/marketplace"} icon={'/icons/navigation/marketplace.svg'}> Marketplace </SideNavLink>
                </Box>
              </AccordionButton>
            </AccordionItem>
            <AccordionItem border={0} onClick={() => setAccordion(6)}>
              <AccordionButton padding={0}>
                <Box flex='1' textAlign={'left'}>
                  <SideNavLink url={"/data"} icon={'/icons/navigation/data.svg'}> Data </SideNavLink>
                </Box>
              </AccordionButton>
              <AccordionPanel pl={0} pr={0}>
                <Box flex='1' textAlign='left'>
                  <SubLink to={"/data/upload"}> Upload data </SubLink>
                  <SubLink to={"/data/data-adapters"}> Data adapters </SubLink>
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </>
            , auth)
        }

      </Accordion>


      {/* <Spacer /> */}

      {/* <User user={auth.user} name={auth.name}/> */}

    </Flex>
  )
}

export default SideNav