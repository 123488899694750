import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { getCompassColourScheme } from './compassScoreColour'

const CompassScore = (props) => {

  const score = typeof props.score == "number" ? Math.round(props.score) : <>&mdash;</>

  const colourScheme = getCompassColourScheme(score)

  return (
    <Flex
      bg={colourScheme.bg}

      justifyContent={"center"}
      alignItems={"center"}
      w={"45px"}
      h={"45px"}
      borderRadius={"6px"}
    >
      <Text
        color={colourScheme.text}

        fontFamily={"SF Pro Display"}
        fontWeight={500}
        fontSize={"24px"}
        line-height={"20px"}
      >
        { score }
      </Text>
    </Flex>
  )
}

export default CompassScore