import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React from 'react'
import { defaultChartSettings } from '../../../helpers/roadmapChartProvider'
import { Box, Spinner } from '@chakra-ui/react'
import { displayCost } from '../../../helpers/formatValues'

const IndustryICPChart = (props) => {

  const chartOptions = defaultChartSettings()

  chartOptions.yAxis.labels.formatter = (event) => displayCost(event.value)

  chartOptions.series = props.icpData.map(org => {
    return {
      name: org.name,
      lineWidth: 1,
      data: [2020, 2030, 2040, 2050].map(year => {
        return {
          x: new Date(year, 0),
          y: org['carbon_price_' + year] || 0,
        }
      })
    }
  })

  chartOptions.tooltip.formatter = function tooltipFormatter () {

    let tooltipHtml = '<b>' + this.x.getFullYear() + '</b>'
    const getCurrentPoint = (series) => series.data.find(point => this.x.getFullYear() == point.x.getFullYear())

    const allSeries = this.series.chart.series

    allSeries.forEach((series, index) => {
      const closestDataPoint = getCurrentPoint(series)
      const yDataValue = closestDataPoint.y

      if (this.series.index === index || this.series.options.index === index) {
        tooltipHtml += '<br/> <b>' + series.name + ': ' + (displayCost(yDataValue) || '—') + '</b>'
      } else {
        tooltipHtml += '<br/>' + series.name + ': ' + (displayCost(yDataValue) || '—')
      }
    })

    return tooltipHtml
  }

  return (
    <>
      {(true) ?
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        :
        <Box height="400px" display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Spinner size='xl' />
        </Box>
      }
    </>
  )
}

export default IndustryICPChart