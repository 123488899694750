import { Box, Spinner } from '@chakra-ui/react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { getKPIObject } from '../../../helpers/calculateKPIs'
import { defaultChartSettings, getScopeTargets, getTrajectorySeries } from '../../../helpers/roadmapChartProvider'

const RoadmapChart = (props) => {

  const chartOptions = defaultChartSettings('year');

  const calculateKPIs = (event) => {
    return getKPIObject(event.target.chart, props.carbonPrice, event.max? new Date(event.max) : null)
  }

  chartOptions.chart.events = {
    load: function (event) {
      props.updateKPIs(getKPIObject(event.target, props.carbonPrice))
      this.axes[0].setExtremes(new Date(props.baselineYear, 0).getTime(), new Date(props.finalYear, 0).getTime())
    },
  }

  chartOptions.xAxis.events = {
    setExtremes: (e) => {
      props.updateKPIs(calculateKPIs(e));
    },
  }

  chartOptions.series = getTrajectorySeries(props.roadmapLineData, props.scope)

  if (!props.scope && !props.isLocationView && props.combinedTargetData.length > 1) {
    chartOptions.series.push(
      {
        name: 'Compound target',
        color: '#00E7A9',
        // @ts-ignore
        type: 'area',
        lineWidth: 2,
        fillOpacity: 0.1,
        dashStyle: 'dot',
        data: props.combinedTargetData.map(point => {
          return {
            x: new Date(point.date),
            y: Number(point.target_emissions)
          }
        }),
      }
    )
  }

  getScopeTargets(props.targetData, props.scope).forEach(target => {
    chartOptions.series.push({
      name: target.name,
      color: '#00E7A9',
      type: 'area',
      lineWidth: 2,
      fillOpacity: 0.1,
      dashStyle: 'dot',
      data: target.years?.map(year => {
        return {
          x: new Date(year.date),
          y: year.target_emissions
        }
      }),
    })
  })

  if (props.isTargetView) {
    chartOptions.xAxis.max = new Date(props.targetData[0]?.year, 0)
  }

  const ready = (props.roadmapLineData.combined.length > 0 || props.roadmapLineData.s1.length > 0)

  return (
    <>
      {(ready) ?
        <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={props.chartRef} />
          :
        <Box height="400px" display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Spinner size='xl' />
        </Box>
      }
    </>
  )
}

export default RoadmapChart