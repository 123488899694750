import React from 'react';
import { safeLog } from '../../helpers/logging';
import ErrorPage from '../Blank/ErrorPage';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      errorFound: false
    }
  }

  componentDidCatch(error, info) {
    this.setState({
      errorFound: true
    })
    safeLog('error: ', error)
    safeLog('info: ', info)
  }

  render() {
    if (this.state.errorFound)
      return <ErrorPage/>

    return this.props.children
  }
}

export default ErrorBoundary