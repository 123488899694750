import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'

const CenteredSpinner = () => {
  return (
      <Center h={"100%"}>
        <Spinner/>
      </Center>
  )
}

export default CenteredSpinner