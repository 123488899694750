import { Button, Divider, Flex, IconButton, Image, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import Card from '../General/Card'
import ExpertMap from './ExpertMap'
import FlippableContainer from '../General/Flippable/FlippableContainer'
import TooltipLink from '../General/TooltipLink'
import { RepeatIcon } from '@chakra-ui/icons'

const ExpertCard = (props) => {

  const data = props.data

  const getImageSrc = () => {
    return '/experts/' + data.name.toLowerCase().replaceAll(' ', '-') + '.png'
  }

  const top = <Flex padding={"22px"} bg='ui.200' gap='14px' alignItems={'center'}>
    <Flex gap='14px' alignItems={'center'}>
      <Image src={getImageSrc()} h='64px' w='64px' borderRadius={'12px'} />

      <Flex direction={'column'}>
        <Text> {data.name} </Text>
        <Text maxHeight='40px' overflowY={'auto'}
          className='subtitle' mt="8px"> {data.industries.join(', ')} </Text>
      </Flex>
    </Flex>

    <Spacer />

    <Flex gap='14px' minW='142px' alignItems={'center'} justifyContent='end'>

      <TooltipLink link={data.website} h='64px'>
        <Image src='/icons/link.svg' w='44px' minW='44px' className='whiteIcon' />
      </TooltipLink>

      <TooltipLink link={data.linkedIn} h='64px'>
        <Image src='/icons/linkedIn.svg' w='44px' minW='44px' />
      </TooltipLink>
    </Flex>
  </Flex>

  const front =
    <Card height='fit-contents' padding={0}>

      {top}

      <Divider />

      <ExpertMap location={data.location} lat={data.lat} lon={data.lon}/>
      <Flex w='100%' position={'absolute'} bottom='18px' right='20px'>
        <Spacer/>
        <IconButton aria-label='flip card' icon={<RepeatIcon/>} bg='ui.200' _hover={{bg: 'ui.500'}} />
      </Flex>
    </Card>

  const back = <Card padding={0}>
    {top}
    <Flex padding='20px' direction={'column'} h='246px' gap='0.5em'>

      {
        data.size > 1 ?
        <Text fontSize='14px'><i> ~{data.size} employees </i></Text>
        : typeof data.size === 'string' ?
        <Text fontSize='14px'><i> {data.size} employees </i></Text>
        : <></>
      }

      <Text overflowY='auto' fontSize='14px'>
        {data.description}
      </Text>

      <Spacer />

      <Flex gap='12px' alignItems={'center'}>
        <Spacer />
        <Button size='sm' onClick={e => e.stopPropagation()}> Chat with expert </Button>
        <Button size='sm' onClick={e => e.stopPropagation()}> Share data and start work </Button>
        <IconButton aria-label='flip card' icon={<RepeatIcon/>} bg='ui.200' _hover={{bg: 'ui.500'}}/>
      </Flex>
    </Flex>
  </Card>

  return <FlippableContainer front={front} back={back} h='355px' />
}

export default ExpertCard