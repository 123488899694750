import { Flex, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import React from 'react'
import Card from '../../General/Card'
import MiniScopeChart from './MiniScopeChart'
import ScopeEmissionsTable from './ScopeEmissionsTable'

const MiniScopeOverview = (props) => {

  const emissions = props.emissions

  const ScopeChart = () => {
    if (emissions.emissions_scope1 || emissions.emissions_scope2 || emissions.emissions_scope3) {
      return <MiniScopeChart emissions={props.emissions} />
    }
    return <Spinner />
  }

  if (!props.year)
    return <></>

  return <SimpleGrid columns={2} gap={"20px"}>
    <Card>
      <Text lineHeight={"20px"}>
        {props.year === (new Date().getFullYear() - 1) ? "Last year's" : props.year} emissions
      </Text>
      <Text className='subtitle' mt="8px"> A breakdown by scope </Text>

      <Flex w="100%" h="350px" alignItems={"center"} justifyContent={"center"}>
        <ScopeChart />
      </Flex>

    </Card>

    <Card>
      <Flex h="100%" alignItems={""}>
        <ScopeEmissionsTable emissions={emissions} currency={props.currency} />
      </Flex>
    </Card>
  </SimpleGrid>
}

export default MiniScopeOverview