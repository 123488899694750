import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, Flex, Heading, Image, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { uploadEmissionsFile } from '../../../services/APICalls'
import Card from '../../General/Card'
import UploadDragDrop from '../UploadDragDrop'
import FileList from '../UploadSteps/FileList'
import UploadAlert from '../UploadSteps/UploadAlert'
import DataMethod from './DataMethod'

const DataChecklistCard = (props) => {

  const auth = props.auth

  const [files, setFiles] = useState([])
  const [alerts, setAlerts] = useState([])

  useEffect(() => {
    setFiles(props.files)
  }, [props.files])

  const uploadFile = (file) => {
    if (props.category)
      uploadEmissionsFile(auth.token, auth.orgId, file, props.category).then(response => {
        if (response['filename uploaded as: ']) {
          alertSuccess()
          file.actual_name = response['filename uploaded as: ']
        }
        else
          alertError()
      })
  }

  const alertSuccess = (isDelete) => {
    const copy = [...alerts]
    if (isDelete)
      copy.push({ type: 'success', message: 'Deleted a file' })
    else
      copy.push({ type: 'success', message: 'Uploaded a file' + (files.length > 1 ? 's' : '') })
    // @ts-ignore
    setAlerts(copy)
  }

  const alertError = (isDelete) => {
    const copy = [...alerts]
    if (isDelete)
      copy.push({ type: 'error', message: 'Failed to delete file' })
    else
      copy.push({ type: 'error', message: 'Failed to upload file' })
    // @ts-ignore
    setAlerts(copy)
  }

  return (
    <AccordionItem border={0}>
      <Card>
        {alerts.map((alert, index) => <UploadAlert type={Object(alert).type} message={Object(alert).message} key={index} />)}
        <AccordionButton gap='1em'>
          <Image src='/icons/insights/info.svg' />
          <VStack alignItems={'start'}>
            <Heading size='md'> Scope {props.scope} {props.category ? '- Category ' + props.category : ''} </Heading>
            <Text textAlign={'left'}> {props.description} </Text>
          </VStack>

          <Spacer />

          <Button
            onClick={(e) => e.stopPropagation()} color='black'
            minW='176px'
            colorScheme={'sky'} bg='sky.500'
          >
            Mark as complete
          </Button>
          <AccordionIcon color='sky.500' />
        </AccordionButton>

        <AccordionPanel display={'flex'} flexDirection='column' gap='1em'>
          <SimpleGrid columns={files.length ? 2 : 1} gap='1em'>
            <UploadDragDrop files={files} setFiles={setFiles} alerts={alerts} setAlerts={setAlerts} onUpload={uploadFile} />

            {
              files.length ?
                <SimpleGrid>
                  <div>
                    <Text> Files Uploaded </Text>
                    <FileList auth={auth} files={files} setFiles={setFiles} alertSuccess={alertSuccess} alertError={alertError}/>
                  </div>
                  <div>
                    <Text> Data connected </Text>
                    <FileList files={[]} />
                  </div>
                </SimpleGrid> : <></>
            }

          </SimpleGrid>

          <Flex direction='column' gap='0.5em'>
            <Text> Options for formatting your data </Text>

            <VStack>
              <DataMethod index={1} name={'Fuel based method'} />
              <DataMethod index={2} name={'Spend based method'} />
            </VStack>

          </Flex>
        </AccordionPanel>

      </Card>
    </AccordionItem>
  )
}

export default DataChecklistCard