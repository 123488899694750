import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { defaultBarChartSettings } from '../../../helpers/emissionsChartProvider'
import { Flex, Spinner } from '@chakra-ui/react'
import theme from '../../../theme'

const BarChart = (props) => {

  const chartOptions = defaultBarChartSettings()

  chartOptions.series = [{
    name: 'Emissions',
    data: props.data,
  }]

  if (props.horizontal) {
    chartOptions.chart.type = 'bar'
    chartOptions.xAxis.gridLineWidth = 1
    chartOptions.xAxis.gridLineColor = theme.colors.ui['300']

  }
  chartOptions.xAxis.labels.formatter = props.yAxisFormatter

  chartOptions.colors = props.colours || [theme.colors.mint['600']]

  return props.lineData?.length || true ?
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
    />
    :
    <Flex h="400px" alignItems={"center"} justifyContent={"center"}>
      <Spinner />
    </Flex >
}

export default BarChart