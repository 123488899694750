import { Box, Text, Button, Spacer, Flex, Image } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useInitiatives, useSupplierCategories, useSuppliersEmissions, useSuppliers } from '../../../services/APIHooks'
import Card from '../../General/Card'
import EmissionsKPIs from '../EmissionsKPIs'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsSourceBreakdown from '../EmissionsSourceBreakdown'
import DataTable from '../../General/DataTable'
import { displayCarbon, displayCost } from '../../../helpers/formatValues'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link, useOutletContext } from 'react-router-dom'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import BubbleMap from '../Charts/BubbleMap'
import CarbonMaturity from './CarbonMaturity'
import { showForDemoOnly } from '../../../helpers/demoManagement'

const EmissionsSuppliers = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())

  const supplierEmissions = useSuppliersEmissions(auth.token, auth.orgId, outletContext.year)

  if (Number(supplierEmissions) === 204) {
    return <DataNotAvailable year={outletContext.year} description={"suppliers"} />
  }

  const Overview = () => {
    const { initiativeData } = useInitiatives(auth.token, auth.orgId, 3)
    const nextBestAction = initiativeData.sort((initiativeA, initiativeB) => Object(initiativeB).Initiative.compass_score - Object(initiativeA).Initiative.compass_score)[0]

    return <>
      <Box mb={"2em"}>
        <EmissionsKPIs
          currency={auth.orgData.carbon_price_currency}
          emissions={supplierEmissions.emissions_total}
          cost={supplierEmissions.emissions_total_cost_display}
          nextBestAction={nextBestAction?.Initiative}
          nextBestActionLink={'/roadmap/scope3'}
        />
      </Box>

      <Card style={{ marginBottom: "2em" }}>
        <Text>Total supplier CO2e emissions</Text>
        <Text className="subtitle">Your supplier emissions overview. You can drag on the chart to zoom in.</Text>
        <EmissionsChart
          scope={props.scope}
          lineData={supplierEmissions.emissions_monthly}
        />
      </Card>
    </>
  }

  const CategoryBreakdown = () => {
    const supplierCategories = useSupplierCategories(auth.token, auth.orgId, outletContext.year, 'procurement')

    return <Box marginBottom={"2em"}>
      <EmissionsSourceBreakdown
        type={"suppliers"}
        sourceData={supplierCategories}
      />
    </Box>

  }

  const SupplierRegions = () => {

    const testSupplierData = [
      { locale: { code: 'DE', name: 'Germany', flagSrc: '/countries/germany.svg' }, maturity: 'A', supplierCount: 1, emissions: 1234200 },
      { locale: { code: 'AT', name: 'Austria', flagSrc: '/countries/belgium.svg' }, maturity: 'B', supplierCount: 12, emissions: 523450 },
      { locale: { code: 'US', name: 'United States', flagSrc: '/countries/USA.svg' }, maturity: 'D', supplierCount: 3, emissions: 21431200 },
      { locale: { code: 'CA', name: 'Canada', flagSrc: '/countries/canada.svg' }, maturity: 'B', supplierCount: 2, emissions: 59873 },
      { locale: { code: 'EG', name: 'Egypt', flagSrc: '/countries/egypt.svg' }, maturity: 'A', supplierCount: 2, emissions: 2345200 },
    ]

    return <Card style={{ marginBottom: "2em" }}>

      <Flex direction={"column"} gap="1em">
        <div>
          <Text> Supplier regions </Text>
          <Text className='subtitle'> Your supplier emissions divided by region </Text>
        </div>

        <BubbleMap data={testSupplierData}/>

        <DataTable
          headings={['Location', 'Average carbon maturity', 'n. of suppliers', 'CO2e', 'Cost']}
          rows={testSupplierData.map(location => [
            <Flex gap='0.5em' alignItems={'end'}><Image src={location.locale.flagSrc}/> {location.locale.name} </Flex>,
            <CarbonMaturity value={location.maturity} />,
            location.supplierCount.toString(),
            displayCarbon(location.emissions),
            displayCost(location.emissions * (auth.orgData.carbon_price || 0))
          ])}
        />
      </Flex>

    </Card>
  }

  const SuppliersBreakdown = () => {
    const suppliers = useSuppliers(auth.token, auth.orgId, outletContext.year)

    return <Flex direction={"column"} gap="2em">
      <DataTable
        headings={['Supplier name', 'CO2e', 'Cost', 'Type']}
        rows={suppliers.slice(0, 10).map(supplier => [
          supplier.name, displayCarbon(supplier.emissions_total),
          supplier.emissions_total_cost_display, supplier.supplier_type
        ])}
        links={suppliers.slice(0, 10).map(supplier => supplier.supplier_id.toString())}
      />
      <Flex>
        <Spacer />
        <Link to="all">
          <Button variant={"ghost"} color={"rgba(0, 168, 240, 0.9)"} rightIcon={<ChevronRightIcon />}>
            View all suppliers
          </Button>
        </Link>
      </Flex>
    </Flex>
  }

  return (
    <>
      <Overview />

      <CategoryBreakdown />

      {showForDemoOnly(
        <SupplierRegions />, auth
      )}

      <SuppliersBreakdown />
    </>
  )
}

export default EmissionsSuppliers