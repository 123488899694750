import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

const LinkArrowButton = (props) => {

  return (
    <Link to={props.url || ''}>
      <IconButton
        disabled={!props.url}
        aria-label= {props.ariaLabel}
        height={"40px"}
        icon={<ChevronRightIcon />}
        color={"rgba(0, 168, 240, 0.9)"}
        borderColor={"rgba(0, 168, 240, 0.9)"}
        variant={"outline"}
      />
    </Link>
  )
}

export default LinkArrowButton