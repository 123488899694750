export const describeAccuracy = (accuracy) => {
  accuracy = Math.round(accuracy)
  if (accuracy == 1)
    return 'Very low accuracy'
  if (accuracy == 2)
    return 'Low accuracy'
  if (accuracy == 3)
    return 'Medium-low accuracy'
  if (accuracy == 4)
    return 'Medium accuracy'
  if (accuracy == 5)
    return 'Medium-high accuracy'
  if (accuracy == 6)
    return 'High accuracy'
  if (accuracy == 7)
    return 'Very high accuracy'
  return ''
}