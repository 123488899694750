import { Select } from '@chakra-ui/react'
import React from 'react'

const YearRangeSelect = (props) => {

  const fiveYears = props.baselineYear + 5
  const modulus = fiveYears % 5
  let firstYearOption = fiveYears - modulus
  if (modulus >= 3)
    firstYearOption += 5

  const thisYear = new Date().getFullYear();

  const rangeOptions = (start, end) => {
    const options = []
    for(let year = firstYearOption; year <= props.finalYear; year+=5) {
      options.push(<option key={year} value={[thisYear, year].toString()}> To {year} </option>)
    }
    return options
  }

  return (
    <Select onChange={props.onChange} variant='filled'>
      <option key='option1' value={[props.baselineYear, props.finalYear].toString()}>
        {props.baselineYear} to {props.finalYear}
      </option>
      <option key='option2' value={[thisYear, thisYear + 1].toString()}> One year </option>
      <option key='option3' value={[thisYear, thisYear + 2].toString()}> Two year </option>
      {rangeOptions(firstYearOption, props.finalYear)}
    </Select>
  )
}

export default YearRangeSelect