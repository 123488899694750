import { RepeatIcon } from '@chakra-ui/icons'
import { Box, Button, Center, Flex, Heading, Image, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import OrgLogo from '../Navigation/OrgLogo'

const ErrorPage = () => {
  return (
    <Flex h={'100vh'} direction='column'>

      <a href='/'>
        <Box p='32px' position={'absolute'}>
          <OrgLogo />
        </Box>
      </a>

      <Spacer />

      <Center>
        <Box textAlign={'center'}>
          <Heading>
            Error occurred
          </Heading>
          <Text fontSize={'24px'}> An unknown error occurred </Text>

          <Flex>
            <Spacer />
            <Flex gap='24px' mt='50px'>
              <Button leftIcon={<RepeatIcon />} onClick={() => document.location.reload()}>
                Refresh
              </Button>

              <a href='/'>
                <Button leftIcon={<Image src='/icons/navigation/dashboard.svg' />}>
                  Return home
                </Button>
              </a>
            </Flex>
            <Spacer />
          </Flex>

        </Box>
      </Center>

      <Spacer />
    </Flex>
  )
}

export default ErrorPage