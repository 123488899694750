import { Button, Center, Flex, Heading, Text } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from '../General/Card'
import ChangeOrganisation from './ChangeOrganisation'

const UserProfile = (props) => {

  const auth = useContext(props.context)

  const navigate = useNavigate()
  const signOut = () => {
    auth.signOut(() => {
      navigate('/login', { replace: true })
    })
  }

  return (
    <Center mt='4em'>
      <Flex direction={'column'} gap='2em' width={"500px"}>
        <Card>
          <Flex direction={"column"} alignItems={"center"} gap="2em">
            <Heading> Profile </Heading>

            <Text> {auth.name} </Text>

            <Text> {auth.orgData.org_name} </Text>


            <Button onClick={signOut}> Sign out </Button>
          </Flex>
        </Card>

        <Card>
          <ChangeOrganisation context={props.context} />
        </Card>
      </Flex>
    </Center>
  )
}

export default UserProfile