import { authenticate, getUserInfo, setUserYear } from './APICalls'

const authProvider = {

  isAuthenticated: true,
  token: '',

  signin(username, password, callback) {
    const runAuthentication = () => authenticate(username, password).then(response => {
      if (response.token) {
        authProvider.isAuthenticated = true
        authProvider.token = response.token

        localStorage.setItem('username', username)
        localStorage.setItem('token', response.token)

        getUserInfo('Bearer ' + response.token, username).then(userResponse => {

          const originalOrgId = userResponse.original_organisation_id
          const orgOverrideId = userResponse.organisation_override_id

          localStorage.setItem('name', userResponse.name)
          localStorage.setItem('year', userResponse.year)
          localStorage.setItem('orgId', orgOverrideId || originalOrgId)
          localStorage.setItem('originalOrgId', originalOrgId)

          callback(response.token, orgOverrideId || originalOrgId, userResponse.year, userResponse.name, userResponse.original_organisation_id)
        })

      } else {
        return response
      }
    })

    return runAuthentication()
  },

  signout(callback) {
    authProvider.isAuthenticated = false
    localStorage.clear()
    callback()
  },

  updateYear(token, email, newYear) {
    localStorage.setItem('year', newYear)
    setUserYear(token, email, newYear) // call API
  },
}

export { authProvider }