import React, { useContext, useEffect, useState } from 'react'
import EmissionsKPIs from '../EmissionsKPIs'
import { Box, Flex, Spacer, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsSourceBreakdown from '../EmissionsSourceBreakdown'
import TopEmitters from '../TopEmitters'
import { useEmissions, useInitiatives, useTopEmissionSources } from '../../../services/APIHooks'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import { displayCarbon } from '../../../helpers/formatValues'
import ExpandModalButton from '../../General/ExpandModalButton'
import AccuracyModal from '../Accuracy/AccuracyModal'
import { useOutletContext } from 'react-router-dom'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import BarChart from '../Charts/BarChart'
import theme from '../../../theme'
import ScrollableInsights from '../../Dashboard/WhatsNew/ScrollableInsights'

const EmissionsScope = (props) => {

  const auth = useContext(props.context)

  const outletContext = Object(useOutletContext())

  const [noData, setNoData] = useState(false)

  const Overview = () => {
    const emissionsResponse = useEmissions(
      auth.token,
      auth.orgId,
      props.scope,
      outletContext.year,
      null, null, null, null,
      props.scope === 1 ? 'daily' : null
    )

    useEffect(() => {
      if (Number(emissionsResponse) === 204)
        setNoData(true)
    }, [emissionsResponse])


    const { initiativeData } = useInitiatives(auth.token, auth.orgId, props.scope)
    const nextBestAction = initiativeData.sort((initiativeA, initiativeB) => Object(initiativeB).Initiative.compass_score - Object(initiativeA).Initiative.compass_score)[0]

    const [accuracyData, setAccuracyData] = useState({})
    const { isOpen, onOpen, onClose } = useDisclosure()

    if (Number(emissionsResponse) === 204)
      return <></>

    return <>
      <Box mb={"2em"}>
        <EmissionsKPIs
          currency={auth.orgData.carbon_price_currency}
          emissions={emissionsResponse.emissions_total}
          cost={emissionsResponse.emissions_total_cost_display}
          nextBestAction={nextBestAction?.Initiative}
          nextBestActionLink={'/roadmap/scope' + props.scope}
          accuracy={emissionsResponse.accuracy}
        />
      </Box>

      <Card style={{ marginBottom: "2em" }}>
        <Flex>
          <Box>
            <Text>{props.scope === 1 ? 'Daily' : 'Monthly'} scope {props.scope} CO2e emissions</Text>
            <Text className="subtitle">Your scope {props.scope} emissions overview. You can drag on the chart to zoom in.</Text>
          </Box>

          <Spacer />

          <Flex alignItems={"center"}>
            <ExpandModalButton
              enable={emissionsResponse.accuracy}
              ready={emissionsResponse.emissions_monthly?.length}
              onClick={() => { setAccuracyData(emissionsResponse); onOpen() }}
            />
          </Flex>
        </Flex>
        <EmissionsChart
          period={props.scope === 1 ? 'day' : 'month'}
          scope={props.scope}
          lineData={emissionsResponse.emissions_monthly}
        />
      </Card>
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
    </>
  }

  const Sources = () => {
    const emissionsSources = useTopEmissionSources(auth.token, auth.orgId, props.scope, outletContext.year, 'ghgprotocol')

    if (Number(emissionsSources) === 204) {
      return <DataNotAvailable h="200px" year={outletContext.year} description={"Scope " + props.scope + " sources"} />
    }

    return <Box marginBottom={"2em"}>

      {props.scope === 3 ?
        <Card>
          {emissionsSources.length ?
            <Flex direction={'column'} gap='1em'>
              <Box>
                <Text> Emissions by GHG Protocol category </Text>
                {/* <Text className='subtitle'> a subtitle </Text> */}
              </Box>

              <BarChart
                colours={[theme.colors.scope3['500']]}
                data={emissionsSources.map(category => [category.name, category.emissions_total])}
              />
            </Flex>
            :
            <Flex minH={"523px"} justifyContent="center" alignItems={"center"}>
              <Spinner />
            </Flex>
          }
        </Card>
        :
        <EmissionsSourceBreakdown
          scope={props.scope}
          sourceData={emissionsSources}
        />
      }
    </Box>
  }

  const Equipment = () => {

    if (props.scope === 3)
      return <S3CategoryTable />

    return <TopEmitters
      scope={props.scope}
      token={auth.token}
      orgId={auth.orgId}
      year={outletContext.year}
    />
  }

  const S3CategoryTable = () => {
    const categories = useTopEmissionSources(auth.token, auth.orgId, props.scope, outletContext.year, 'ghgprotocol')

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [accuracyData, setAccuracyData] = useState({})

    if (Number(categories) === 204) {
      return <></>
    }

    return <>
      <DataTable
        headings={['Category', 'CO2e', 'Cost', '']}
        rows={categories.map((category) => [
          category.name,
          displayCarbon(category.emissions_total),
          category.emissions_total_cost_display,
          <ExpandModalButton onClick={() => { setAccuracyData(category); onOpen() }}
            enable={category.emissions_total !== null} ready={categories.length}/>
        ])}
        links={categories.map((category) => 'category' + category.primary_category_id)}
      />
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
    </>
  }

  if (noData) {
    return <DataNotAvailable year={outletContext.year} description={"Scope " + props.scope} />
  }

  return (
    <>
      <Overview />

      <ScrollableInsights auth={auth} scope={props.scope}/>

      <Sources />

      <Equipment />
    </>
  )
}

export default EmissionsScope