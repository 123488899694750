import { Flex, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import NavigationBreadcrumbs from '../Navigation/NavigationBreadcrumbs'
import ExpertCard from './ExpertCard'
import { experts } from './expertdata'

const Experts = (props) => {

  return (
    <Flex direction={'column'} gap='2em'>

      <NavigationBreadcrumbs />

      <Text>
        Connect with experts for deeper insight on managing and reducing your emissions. Click on an expert to learn more and connect.
      </Text>

      <SimpleGrid minChildWidth={'450px'} gap='2em'>
        {experts.map((expert, index) => <ExpertCard data={expert} key={index} />)}
      </SimpleGrid>

    </Flex>
  )
}

export default Experts