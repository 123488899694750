import theme from '../theme'
import { displayCarbon, formatCarbonDescriptor, round } from './formatValues'
import { objectToArray } from './objectToArray'

export const defaultDonutChartSettings = (colours) => {

  const order = [6, 4, 5, 3, 7, 2, 8, 1, 9, 0] // In the future we may want to generate this programatically
  const orderedColours = colours ?
    order.map(n => colours[n])
    :
    order.map(n => objectToArray(theme.colors.ice)[n])

  return {
    legend: {
      align: 'center',
      verticalAlign: 'bottom',
      enabled: true,
      itemStyle: {
        fontFamily: 'Poppins',
        color: 'white'
      },
    },
    chart: {
      // spacingTop: '10px',
      style: {
        fontFamily: 'Poppins',
        color: "white"
      },
      backgroundColor: 'transparent',
      plotBorderWidth: 0,
      plotShadow: false,
      type: 'pie',
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
      verticalAlign: 'middle',
      floating: true,
      style: {
        color: '#ffffff',
        fontFamily: 'Poppins',
        fontSize: 64,
        fontWeight: 500,
      }
    },
    tooltip: {
      enabled: false,
    },
    accessibility: {
      enabled: false,
      point: {
        valueSuffix: '%'
      }
    },
    plotOptions: {
      pie: {
        borderWidth: 1,
        borderColor: theme.colors.ui['100'],
        colors: orderedColours,
        innerSize: '80%',
        showInLegend: true,
        dataLabels: {
          enabled: false,
          connectorColor: '#ffffff',
          connectorWidth: '2px',
          connectorShape: 'straight',
          format: '{point.name} &mdash; {point.percentage:.1f}%',
          style: {
            color: '#ffffff',
            fontFamily: 'Poppins',
            fontSize: '14pt',
            fontWeight: 600
          },
          valueDecimals: 1
        }
      }
    },
  }
}

export const defaultBarChartSettings = () => {
  return {
    chart: {
      height: 500,
      style: {
        fontFamily: 'Poppins',
        color: "white"
      },
      backgroundColor: 'transparent',
      plotBorderWidth: 0,
      plotShadow: false,
      type: 'column',
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b><br/>' + displayCarbon(this.y)
      }
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    title: {
      text: '',
    },
    accessibility: {
      enabled: false
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: '14px'
        },
      },
      lineWidth: 0,
      categories: {}
    },
    yAxis: {
      visible: true,
      lineColor: 'transparent',
      gridLineColor: theme.colors.ui['300'],
      gridLineWidth: '1px',
      title: {
        text: '',
        enabled: false
      },
      labels: {
        enabled: true,
        style: {
          color: '#FFFFFF',
          fontFamily: 'Poppins'
        },
        formatter: (event) => {
          const chartUnit = formatCarbonDescriptor(event.chart.yAxis[0].max)
          return displayCarbon(event.value, 2, chartUnit)
        },
      },
      minRange: 100,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderRadius: 6,
      },
      bar: {
        borderWidth: 0,
        borderRadius: 6,
      }
    },
  }
}

export const getEmissionsSeries = (data, scope, label = '', type = '') => {

  const getColour = (scope, type) => {
    if (scope)
      return theme.colors['scope' + scope][500]
    if (type === 'location')
      return theme.colors.fuchsia['500']
    return theme.colors.ice['500']
  }

  const seriesOptions = {
    name: scope ? 'Scope ' + scope + ' emissions' : label + ' emissions',
    visible: true,
    color: getColour(scope, type),
    data: data?.map(point => {
      // Highcharts + Safari js does not like dates formatted YYYY-MM-DD (only happens with daily emissions, not monthly emissions)
      var date = (scope === 1) ? new Date(point.date) : new Date(point.date.replace(/-/g, "/"));
      return {
        x: date,
        y: point.emissions_total
      }
    }),
  }

  return [seriesOptions]
}

export function getLegendFormatter() {
  return '<p class="legendTitle">'
    + (this.name || '') + '</p> <p class="legendText">'
    + displayCarbon(this.y * 1000)
    + ' &mdash; '
    + round(this.percentage || 0, 1)
    + '%</p>';
}