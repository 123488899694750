import React, { useState } from 'react'
import { displayCarbon } from '../../helpers/formatValues'
import DataTable from '../General/DataTable'
import { Button, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { useTopEmitters } from '../../services/APIHooks'
import ExpandModalButton from '../General/ExpandModalButton'
import AccuracyModal from './Accuracy/AccuracyModal'
import { Link } from 'react-router-dom'
import { checkProxy } from '../../helpers/checkProxy'

const TopEmitters = (props) => {

  const [accuracyData, setAccuracyData] = useState({})

  const emittersData = useTopEmitters(props.token, props.orgId, props.scope, props.year)

  const { isOpen, onOpen, onClose } = useDisclosure()

  if (Number(emittersData) === 204) {
    return <></>
  }

  const sortFunction = (a, b) => { return b.emissions_total - a.emissions_total }
  emittersData.sort(sortFunction)

  const topEmitters = emittersData.slice(0, 10).filter(emitter => emitter.emissions_total)

  return (
    <>
      <Text> Top emitting equipment </Text>
      <DataTable
        headings={['Equipment', 'Location', 'Unit', 'System', 'CO2e', 'Cost', '']}
        rows={topEmitters.map((emitter) => [
          checkProxy(emitter, 'equipment'),
          emitter.location_name,
          checkProxy(emitter, 'unit'),
          checkProxy(emitter, 'system'),
          displayCarbon(emitter.emissions_total),
          emitter.emissions_total_cost_display,
          <ExpandModalButton enable onClick={() => {setAccuracyData(emitter); onOpen()}}/>
        ])}
        links={topEmitters.map(emitter => '/emissions/locations/' + emitter.location_id + '/system'
          + emitter.system_id + '/unit' + emitter.unit_id + '/equipment' + emitter.equipment_id)}
      />
      <Flex>
        <Spacer/>
        <Link to="equipment">
          <Button variant={"ghost"} color={"onSurface.primary.high"} rightIcon={<ChevronRightIcon />}>View all equipment</Button>
        </Link>
      </Flex>
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose}/>
    </>
  )
}

export default TopEmitters