import React from 'react'
import {
  Flex,
  SimpleGrid,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react'
import { displayCarbon, formatMoney, formatMoneyDescriptor } from '../../helpers/formatValues'
import Card from '../General/Card'

const RoadmapKPIs = (props) => {

  return (
    <SimpleGrid minChildWidth={"300px"} spacing={"1em"}>
      <Card padding={0}>
        <Stat padding={"26px"}>
          <StatLabel> {!isNaN(props.KPIData.year) ? props.KPIData.year : <>&mdash;</>} roadmap trajectory </StatLabel>
          <StatNumber>
            {displayCarbon(props.KPIData.trajectory) || <>&mdash;</>}
          </StatNumber>
        </Stat>
      </Card>

      { props.noTarget ? <></> :
        <Card padding={0}>
          <Stat padding={"26px"}>
            <StatLabel> {props.KPIData.targetYear || <>&mdash;</>} target trajectory </StatLabel>
            <StatNumber>
              {displayCarbon(props.KPIData.target) || <>&mdash;</>}
            </StatNumber>
          </Stat>
        </Card>
      }

      { props.noTarget ? <></> :
        <Card padding={0}>
          <Stat padding={"26px"}>
            <Flex fontSize={"20px"}>
              <StatLabel mr={"0.5em"}> {props.KPIData.deltaCarbon < 0 ? 'Hit' : 'Missed'} {props.KPIData.targetYear} target by </StatLabel>
              <Spacer />
              <StatLabel textAlign={"right"}> {props.KPIData.deltaCarbon < 0 ? 'Savings' : 'Missed savings'} </StatLabel>
            </Flex>
            <Flex>
              <StatNumber mr={"0.5em"}>
                {props.KPIData.deltaCarbon != null ? displayCarbon(Math.abs(props.KPIData.deltaCarbon)) : <>&mdash;</>}
              </StatNumber>
              <Spacer />
              <StatNumber>
                {formatMoney(Math.abs(props.KPIData.deltaCost), undefined, undefined, undefined, props.currency) || <>&mdash;</>}
                {formatMoneyDescriptor(props.KPIData.deltaCost)}
              </StatNumber>
            </Flex>
          </Stat>
        </Card>
      }

    </SimpleGrid>
  )
}

export default RoadmapKPIs