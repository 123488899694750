import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

const options = {
  config,

  shadows: {
    outline: '0 0 0 2px rgba(0, 168, 240, 0.5)',
  },

  styles: {
    global: () => ({
      body: {
        bg: '#15161A', // ui.200
        color: 'white',
      },
      h1: {
        fontSize: '32px',
        lineHeight: '30px',
        weight: 400,
      },
      h2: {
        fontSize: '28px',
        lineHeight: '32px',
        weight: 400,
      },
      h3: {
        fontSize: '24px',
        lineHeight: '32px',
        weight: 400,
      },
      h4: {
        fontSize: '20px',
        lineHeight: '24px',
        weight: 400,
      },
      h5: {
        fontSize: '18px',
        lineHeight: '20px',
        weight: 400,
      },
      h6: {
        fontSize: '16px',
        lineHeight: '20px',
        weight: 400,
      },
    })
  },

  colors: {
    ui: {
      500: '#5A5B63',
      400: '#43444B',
      300: '#27282E',
      200: '#232429',
      100: '#1B1C21',
      50: '#111114',
    },
    grayscale: {
      400: '#B1B2B4',
      300: '#D6D6D8',
      50: '#111114',
    },
    warning: {
      high: '#FFAA00',
      medium: 'rgba(255, 170, 0, 0.35)',
      low: 'rgba(255, 170, 0, 0.16)',
    },
    success: {
      high: '#00E7A9',
      medium: 'rgba(0, 231, 169, 0.35)',
      low: 'rgba(0, 231, 169, 0.15)',
    },
    danger: {
      high: '#ff0024',
      medium: 'rgba(255, 0, 36, 0.35)',
      low: 'rgba(255, 0, 36, 0.15)',
    },
    info: {
      high: '#00FFFF',
      medium: 'rgba(0, 255, 255, 0.35)',
      low: 'rgba(0, 255, 255, 0.15)',
    },

    onSurface: {
      primary: {
        high: 'rgba(0, 168, 240, 0.9)',
        medium: 'rgba(0, 168, 240, 0.7)',
        low: 'rgba(0, 168, 240, 0.42)',
        disabled: 'rgba(0, 168, 240, 0.32)',
      },
      light: {
        high: 'rgba(255, 255, 255, 0.9);',
        medium: 'rgba(255, 255, 255, 0.7)',
        low: 'rgba(255, 255, 255, 0.42)',
        disabled: 'rgba(255, 255, 255, 0.32)',
      },
      dark: {
        high: '#181819',
      },
    },

    expect: {
      sky: '#00c3ff',
    },
    primary: {
      primary: '#00A8F0',
      soft: 'rgba(0, 168, 240, 0.16)',
      hover: 'rgba(0, 168, 240, 0.08)',
      softPrimaryDefault: 'rgba(0, 168, 240, 0.04)',
    },

    aloe: {
      50:  '#E6FEEC',
      100: '#CCFCD8',
      200: '#99FAB1',
      300: '#66F78B',
      400: '#33F564',
      500: '#00F23D',
      600: '#00C231',
      700: '#009125',
      800: '#006118',
      900: '#00300C',
    },
    aurora: {
      50:  '#FFE6FF',
      100: '#FFCCFF',
      200: '#FF99FF',
      300: '#FF66FF',
      400: '#FF33FF',
      500: '#FF00FF',
      600: '#CC00CC',
      700: '#990099',
      800: '#660066',
      900: '#330033',
    },
    canary: {
      50:  '#FFFCE6',
      100: '#FFFACC',
      200: '#FFF499',
      300: '#FFEF66',
      400: '#FFE933',
      500: '#FFE400',
      600: '#CCB600',
      700: '#998900',
      800: '#665B00',
      900: '#332E00',
    },
    canyon: {
      50:  '#FFEFE6',
      100: '#FFDFCC',
      200: '#FF9E66',
      300: '#FF667C',
      400: '#FF7D33',
      500: '#FF5D00',
      600: '#CC4A00',
      700: '#993800',
      800: '#662500',
      900: '#331300',
    },
    cherry: {
      50:  '#FFE6E9',
      100: '#FFCCD3',
      200: '#FF99A7',
      300: '#FF667C',
      400: '#FF3350',
      500: '#FF0024',
      600: '#CC001D',
      700: '#990016',
      800: '#66000E',
      900: '#330007',
    },
    fuchsia: {
      50:  '#FEE6EF',
      100: '#FDCCE0',
      200: '#FB99C1',
      300: '#F966A1',
      400: '#F73382',
      500: '#F50063',
      600: '#C4004F',
      700: '#93003B',
      800: '#620028',
      900: '#310014',
    },
    gray: {
      50:  '#F8F8FA',
      100: '#F0F1F2',
      200: '#E6E7E9',
      300: '#D6D6D8',
      400: '#B1B2B4',
      500: '#919294',
      600: '#696A6B',
      700: '#565758',
      800: '#38383A',
      900: '#181819',
    },
    ice: {
      50:  '#E6FFFF',
      100: '#CCFFFF',
      200: '#99FFFF',
      300: '#66FFFF',
      400: '#33FFFF',
      500: '#00FFFF',
      600: '#00CCCC',
      700: '#009999',
      800: '#006666',
      900: '#003333',
    },
    lavender: {
      50:  '#F2E6FF',
      100: '#E4CCFF',
      200: '#CA99FF',
      300: '#AF66FF',
      400: '#9533FF',
      500: '#7A00FF',
      600: '#6200CC',
      700: '#490099',
      800: '#310066',
      900: '#180033',
    },
    lime: {
      50:  '#F8FEE6',
      100: '#F1FECC',
      200: '#E2FD9A',
      300: '#D4FB67',
      400: '#C5FA35',
      500: '#B7F902',
      600: '#92C702',
      700: '#6E9501',
      800: '#496401',
      900: '#253200',
    },
    mint: {
      50:  '#E6FDF6',
      100: '#CCFAEE',
      200: '#99F5DD',
      300: '#66F1CB',
      400: '#33ECBA',
      500: '#00E7A9',
      600: '#00B987',
      700: '#008B65',
      800: '#005C44',
      900: '#002E22',
    },
    ocean: {
      50:  '#E6E6FF',
      100: '#CCCCFF',
      200: '#9999FF',
      300: '#6666FF',
      400: '#3333FF',
      500: '#0000FF',
      600: '#0000CC',
      700: '#000099',
      800: '#000066',
      900: '#000033',
    },
    sky: {
      50:  '#E6F9FF',
      100: '#CCF3FF',
      200: '#99E7FF',
      300: '#66DBFF',
      400: '#33CFFF',
      500: '#00c3ff',
      600: '#009CCC',
      700: '#007599',
      800: '#004E66',
      900: '#002733',
    },
    tangerine: {
      50:  '#FFF7E6',
      100: '#FFEECC',
      200: '#FFDD99',
      300: '#FFCC66',
      400: '#FFBB33',
      500: '#FFAA00',
      600: '#CC8800',
      700: '#996600',
      800: '#664400',
      900: '#332200',
    },
  },

  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },

  components: {
    Button: {
      baseStyle: {
        fontWeight: '500',
        // fontFamily: 'SF Pro Display'
      }
    },
    Stat: {
      sizes: {
        md: {
          label: { fontSize: "20px" },
          number: { fontSize: "28px" },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'onSurface.light.high'
      }
    },
    Tooltip: {
      baseStyle: {
        color: 'onSurface.light.high',
        backgroundColor: 'ui.300',
        borderRadius: '8px',
        fontSize: '18px',
      }
    }
  },
}

options.colors.scope1 = options.colors.lavender
options.colors.scope2 = options.colors.ocean
options.colors.scope3 = options.colors.aurora

const theme = extendTheme(options)

export default theme
