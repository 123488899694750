import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Flex, IconButton, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const NavigationBreadcrumbs = (props) => {

  const location = useLocation()

  const pages = location?.pathname.split('/')
    .filter(page => page !== '')
    .map(page => page.charAt(0).toUpperCase() + page.slice(1))
  const links = pages.map((_link, index) => '/' + pages.slice(0, index + 1).join('/'))
    .map(link => link.toLowerCase())

  const backButtonLink = links[links.length - 2] || ''

  // Prevent the navigation from rendering if the page names are going to be overwritten
  if (props.willOverwrite && !props.overwrite.length)
    return <Flex marginBottom={"2em"}>
      <Link to={backButtonLink}>
        <IconButton
          disabled={!backButtonLink}
          aria-label='go back'
          icon={<ChevronLeftIcon />}
          variant={"ghost"}
          color={"#00A8F0"}
        />
      </Link>
    </Flex>

  // Apply any overwrite for the names of the links
  if (props.overwrite)
    props.overwrite.reverse().forEach((keyName, index) => {
      pages[pages.length - index - 1] = keyName
    })

  return (
    <Flex marginBottom={props.mb ?? "2em"}>

      <Link to={backButtonLink}>
        <IconButton
          disabled={!backButtonLink}
          aria-label='go back'
          icon={<Image src="/icons/navigation/goBack.svg"/>}
          variant={"ghost"}
          color={"#00A8F0"}
        />
      </Link>

      {pages.map((page, index) => (
        <Flex key={index} alignItems={"center"}>
          <Text
            color={index === pages.length - 1 ? "white" : "rgba(255, 255, 255, 0.42)"}
            fontSize={"16px"}
          >
            {index !== 0 ? '/' : ''}
            <>&nbsp;</>
            <Link to={links[index]}>
              {page.replace(/([^a-zA-Z-_])/g, ' $1').replace('-', ' ')}
            </Link>
            <>&nbsp;</>
          </Text>
        </Flex>
      ))}

    </Flex>
  )
}

export default NavigationBreadcrumbs