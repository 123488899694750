export const compassScoreColourScheme = {
  red: {
    text: 'danger.high',
    bg: 'danger.low',
  },
  yellow: {
    text: 'warning.high',
    bg: 'warning.low',
  },
  green: {
    text: 'success.high',
    bg: 'success.low',
  },
}

export const getCompassColourScheme = (compassScore) => {
  if (compassScore > 66)
    return compassScoreColourScheme.red
  if (compassScore > 33)
    return compassScoreColourScheme.yellow
  return compassScoreColourScheme.green
}