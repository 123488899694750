import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { defaultDonutChartSettings } from '../../../helpers/emissionsChartProvider'
import { displayCarbon, round } from '../../../helpers/formatValues'
import theme from '../../../theme'

const ScopeOverviewChart = (props) => {

  const chartOptions = defaultDonutChartSettings()

  chartOptions.legend.align = 'left'
  chartOptions.legend.verticalAlign = 'middle'
  chartOptions.legend.layout = 'vertical'
  chartOptions.legend.useHTML = true
  chartOptions.legend.floating = true

  // chartOptions.chart.width = 292
  chartOptions.chart.height = 292

  chartOptions.legend.itemMarginTop = 20
  chartOptions.legend.itemMarginBottom = 20
  // chartOptions.legend.labelFormatter = getLegendFormatter()

  chartOptions.legend.labelFormatter = function () {
    return '<p class="legendTitle">'
      + this.name + '</p> <p class="legendText">'
      + displayCarbon(this.y * 1000)
      + ' &mdash; '
      + round(this.percentage, 1)
      + '%</p>';
  }

  chartOptions.chart.events = {
    render: function () {
      let chart = this
      let left = chart.plotWidth / 2 + chart.plotLeft
      let top = chart.plotHeight / 2 + chart.plotTop

      if (!chart.customText) {
        chart.customText = chart.renderer
          .text(props.emissions_total + '<span style="font-size: 28px; font-weight: 100">' + props.emissions_total_uom + '</span>' , left, top, true)
          .attr({
            align: 'center'
          })
          .css({
            'font-size': '48px',
            'font-weight': '500'
          })
          .addClass('customTitle').add()
      }
      chart.customText.attr({
        x: left,
        y: top
      });
    }
  }

  chartOptions.series = [{
    name: 'Emissions by Scope',
    colorByPoint: true,
    percentageDecimals: 1,
    valueDecimals: 1,
    startAngle: 90,
    data: [
      { name: 'Scope 1', y: props.scope1, color: theme.colors.scope1[500] },
      { name: 'Scope 2', y: props.scope2, color: theme.colors.scope2[500] },
      { name: 'Scope 3', y: props.scope3, color: theme.colors.scope3[500] },
    ]
  }]

  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  )
}

export default ScopeOverviewChart