import { Button, FormControl, FormErrorMessage, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { safeLog } from '../../helpers/logging'
import { addEditDeleteTarget } from '../../services/APICalls'
import ConfirmModal from '../General/ConfirmModal'
import TargetForm from './TargetForm'

const TargetModal = (props) => {

  const addOrEdit = props.target.new ? 'Add' : 'Edit'
  const [newData, setNewData] = useState(props.target)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setNewData(props.target)
    setIsInvalid(false)
    setIsLoading(false)
    setDeleteLoading(false)
  }, [props.target])

  const [isInvalid, setIsInvalid] = useState(false)

  const onSave = () => {
    if (! (0 < newData.reduction_percentage && newData.reduction_percentage <= 100)) {
      setIsInvalid(true)
      return
    }

    setIsLoading(true)

    newData.org_id = props.orgId
    newData.active = true

    if (addOrEdit === 'Add') {
      delete newData.new
      return addEditDeleteTarget(props.token, props.orgId, undefined, newData).then(response => {
        newData.target_id = response.target_id
        newData.scope = Number(newData.scope)
        props.targets.push(newData)
        props.setTargets(props.targets)
        props.onClose()
        setIsLoading(false)
        return
      })
    }

    const toEdit = props.targets.find(target => target.target_id === props.target.target_id)
    // Overwrite target with values from form
    Object.entries(newData).forEach(([property, value]) => {
      toEdit[property] = value ?? toEdit[property]
    })
    props.setTargets(props.targets)
    setIsLoading(false)
    props.onClose()
    addEditDeleteTarget(props.token, props.orgId, props.target.target_id, newData)
    return
  }

  const [deleteLoading, setDeleteLoading] = useState(false)
  const onDelete = () => {
    setDeleteLoading(true)
    addEditDeleteTarget(props.token, props.orgId, props.target.target_id).then((response) => {
      safeLog('delete response: ', response)
      props.setTargets(props.targets.filter(target => target.target_id !== props.target.target_id))
      setDeleteLoading(false)
      props.onClose()
    })
  }

  const validate = (value) => {
    setNewData(value)
    setIsInvalid(!(0 < newData.reduction_percentage && newData.reduction_percentage <= 100))
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} preserveScrollBarGap isCentered>
      <ModalOverlay />
      <ModalContent bg="ui.200" minW="800px">
        <ModalHeader> {addOrEdit === 'Edit' ? "Edit target" : 'Add a target'} </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={isInvalid}>
            <TargetForm
              target={props.target}
              baselineYear={props.baselineYear}
              finalYear={props.finalYear}

              newData={newData}
              setNewData={validate}
            />
            <FormErrorMessage> Invalid reduction percentage </FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>

          {
            addOrEdit === "Add" ? <></> :
              <ConfirmModal
                openModal={<IconButton aria-label='delete' bg="danger.high" _hover={{ bg: "danger.medium" }}
                  icon={<Image src="/icons/delete.svg" />} />}
                prompt={<> Are you sure you want to remove this target? </>}
                continuePrompt="Yes, remove the target"
                onContinue={onDelete}
                loading={deleteLoading}
              />
          }

          <Spacer />

          <Button mr={3} variant={"ghost"} onClick={props.onClose}>
            Cancel
          </Button>
          <Button isDisabled={isInvalid} isLoading={isLoading}
            bg={"primary.primary"} _hover={{ bg: "sky.700" }} onClick={onSave}>
            {addOrEdit === "Add" ? "Add target" : "Update target"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TargetModal