import React, { useContext } from 'react'
import { Outlet, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Box, Button, ButtonGroup, Center, Heading, Flex, Hide, Image, Link, ListItem, Select, SimpleGrid, Spacer, Stack, Text, UnorderedList } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { safeLog } from '../../../helpers/logging'
import { printWindow } from '../../../helpers/helpers'
import { useEmissions, useOrganisation, useReport, useTargets, useTopEmissionSources } from '../../../services/APIHooks'


const TCFD = (props) => {

  const auth = useContext(props.context)
  const navigate = useNavigate()
  const params = useParams()

  // Report type
  const reportTitle = 'TCFD';
  const reportType = 'tcfd';

  // Prepare the organisation data
  const organisation = useOrganisation(auth.token, auth.orgId)
  const organisationInternalCarbonPrice = (organisation.carbon_price) ? "$" + organisation.carbon_price + " " + organisation.carbon_price_currency + "/tCO2e" : "—"
  const orgImage = "/images/brands/" + organisation.slug + ".png";
  const orgName = organisation.org_name

  // Determine the reporting year and the baseline year (if available)
  const date = new Date();
  const currentDay = date.toLocaleString('default', { day: '2-digit' });
  const currentMonth = date.toLocaleString('default', { month: 'short' });
  const currentYear = date.toLocaleString('default', { year: 'numeric' });
  const currentDate = currentMonth + " " + currentDay + ', ' + currentYear;
  const yearEarliest = 2020; {/* TODO Replace with the organisation's first year that we have data for */}
  const yearBaseline = (parseInt(organisation.year_baseline)) ? organisation.year_baseline : null;
  if (params.yearReport) {
    var yearReport = params.yearReport;
  } else if (auth.year > 2000) {
    var yearReport = auth.year;
  } else {
    var yearReport = currentYear - 1;
  }

  // Event used to change year of the report that is generated
  const reportYearChange = (event) => {
    navigate('/reports/' + reportType.replace('_', '-') + '/years/' + event.target.value)
  }

  // Dropdown for the Reports Header to change the report year
  var years = new Array();
  for (let y = yearEarliest; y <= currentYear; y++) {
    years.push(y);
  }
  let YearsDropdown = () => {
    return <>
      <Box width={"10em"}>
        <Select variant={"filled"} onChange={reportYearChange} defaultValue={yearReport}>
          {years.map((y, index) => (
            <option key={index} value={y} > {y} </option>
          ))}
        </Select>
      </Box>
    </>
  }

  // Fetch the reporting data that has been customised and amended by the organisation's user
  const report = useReport(auth.token, auth.orgId, reportType, yearReport)

  // Prepare the data for the Cover Page
  const section_0_org_name = (report?.section_0_org_name) ? report.section_0_org_name : organisation.org_name;
  const section_0_org_address_1 = (report?.section_0_org_address_1) ? report.section_0_org_address_1 : organisation.address_1;
  const section_0_org_address_2 = (report?.section_0_org_address_2) ? report.section_0_org_address_2 : organisation.address_2;
  const section_0_org_address_3 = (report?.section_0_org_address_3) ? report.section_0_org_address_3 : organisation.address_3;
  const section_0_org_city = (report?.section_0_org_city) ? report.section_0_org_city : organisation.city;
  const section_0_org_state_code = (report?.section_0_org_state_code) ? report.section_0_org_state_code : organisation.state_code;
  const section_0_org_zip_code = (report?.section_0_org_zip_code) ? report.section_0_org_zip_code : organisation.zip_code;
  const section_0_org_country_name = (report?.section_0_org_country_name) ? report.section_0_org_country_name : organisation.country_code;
  var section_0_address = (section_0_org_address_1) ? section_0_org_address_1 : '';
        section_0_address += (section_0_org_address_2) ? ", " + section_0_org_address_2 : ''; // TODO replace comma with line breaks (\r\n not working)
        section_0_address += (section_0_org_address_3) ? ", " + section_0_org_address_3 : ''; // TODO replace comma with line breaks (\r\n not working)
  var section_0_address_city_state_zip = (section_0_org_city) ? section_0_org_city + ", " : '';
        section_0_address_city_state_zip += (section_0_org_state_code) ? " " + section_0_org_state_code : '';
        section_0_address_city_state_zip += (section_0_org_zip_code) ? " " + section_0_org_zip_code : '';

  // Prepare the data for Section 4 - Metrics and Targets
  const section_4_icp_show = (organisation.carbon_price > 0) ? true : false;
  const section_4_icp_display = organisation.carbon_price + organisation.carbon_price_currency;

  // Fetch the emission data for Scope 1, 2, and 3 for the reporting year
  const orgEmissions = useEmissions(auth.token, auth.orgId, null, yearReport)
  const orgEmissionsOverall = (orgEmissions?.emissions_total_tonnes) ? Math.round(orgEmissions.emissions_total_tonnes) : 0;
  const orgEmissionsOverallDisplay = orgEmissionsOverall.toLocaleString();
  const orgEmissionsScope1  = (orgEmissions?.emissions_scope1_tonnes) ? Math.round(orgEmissions.emissions_scope1_tonnes) : 0;
  const orgEmissionsScope1Display = orgEmissionsScope1.toLocaleString();
  const orgEmissionsScope2  = (orgEmissions?.emissions_scope2_tonnes) ? Math.round(orgEmissions.emissions_scope2_tonnes) : 0;
  const orgEmissionsScope2Display = orgEmissionsScope2.toLocaleString();
  const orgEmissionsScope3  = (orgEmissions?.emissions_scope3_tonnes) ? Math.round(orgEmissions.emissions_scope3_tonnes) : 0;
  const orgEmissionsScope3Display = orgEmissionsScope3.toLocaleString();

  // Fetch the Scope 3 categorical emission data for the reporting year
  const orgEmissionsScope3Categories = useTopEmissionSources(auth.token, auth.orgId, 3, yearReport, 'ghgprotocol')
  var orgEmissionsScope3CategoriesPrep = new Array();
  for (var c = 1; c <= 15; c++) {
    orgEmissionsScope3CategoriesPrep[c] = new Object
    orgEmissionsScope3CategoriesPrep[c].emissions_tonnes_display = 0;
    if (orgEmissionsScope3Categories.length){
      orgEmissionsScope3Categories.forEach(el => {
        if (c == el.primary_category_id) {
          orgEmissionsScope3CategoriesPrep[c] = el;
  
          var emissions_tonnes_rounded = (el.emissions_total_tonnes) ? Math.round(el.emissions_total_tonnes) : 0;
          orgEmissionsScope3CategoriesPrep[c].emissions_tonnes_display = emissions_tonnes_rounded.toLocaleString();
        }
      });
    }
  }
  const orgEmissionsScope3CategoriesDisplay = orgEmissionsScope3CategoriesPrep;

  // Fetch the organisation's targets
  const targets = useTargets(auth.token, auth.orgId)
  var section_4_targets_show = (targets.targetData.length > 0) ? true : false;
  if (section_4_targets_show > 0) {
    var targetsOrdered = new Array();
    targets.targetData.forEach(el => {
      var key = el.year + el.scope;
      targetsOrdered[key] = el;
    });
    targetsOrdered.sort()
  }

  // Generate the header for the page
  const Header = () => {
    return <>
      <Box className="noPrint">
        <Box>
          <Flex>
            <Box>
              <Heading> { reportTitle } </Heading>
            </Box>
            <Spacer/>
            <YearsDropdown />
          </Flex>
        </Box>
        <Text marginTop="20px">This { reportTitle } report template is pre-populated with your organisation's carbon data from Expect, where available and applicable.</Text>
      </Box>
    </>
  }

  // Add the summary boxes, each of which when clicked expose the appropriate section
  const Summary = () => {
    return <>
      <SimpleGrid minChildWidth='225px' minChildHeight='250px' spacing="40px" marginTop='60px' >        
        <Box className="reportSummary">
          <Link href="#coverPage" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185" >
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>1</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Cover Page</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Design the cover page for your report</Text>
            </Box>
          </Link>
        </Box>    
        <Box className="reportSummary">
          <Link href="#descriptiveInformation" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>2</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Introduction</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Describe your organisation and how it pertains to sustainability</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#governance" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>3</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Governance</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Disclose governance around climate-related risks and opportunities</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#strategy" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>4</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Strategy</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Disclose the impacts of climate-related risks and opportunities</Text>
            </Box>
          </Link>
        </Box>                            
        <Box className="reportSummary">
          <Link href="#riskManagement" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>5</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Risk Management</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Disclose how the organisation manages climate-related risks and opportunities</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link href="#metricsAndTargets" style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>6</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Metrics and Targets</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Disclose the metrics and targets used to manage climate-related risks and opportunities</Text>
            </Box>
          </Link>
        </Box>
        <Box className="reportSummary">
          <Link onClick={printWindow} style={{ textDecoration: 'none' }}>
            <Box padding={"20px"} textAlign={"left"} bg={"info.low"} borderColor={"ice.200"} borderRadius={"8px"} borderWidth="0px" height="185">
              <Text fontSize="32px" lineHeight="40px" fontWeight="500" textDecoration="none !important" color={"ice.200"}>7</Text>
              <Text fontSize="16px" lineHeight="20px" fontWeight="600" marginTop="5px" color={"ice.200"}>Export</Text>
              <Text fontSize="14px" lineHeight="18px" marginTop="20px" color="#fff">Print or download your prepared report</Text>
            </Box>
          </Link>
        </Box>                                
      </SimpleGrid>
    </>
  }

  // Generate the Cover Page at the top of the report
  const CoverPage = () => {
    return <>
      <a name="coverPage"></a>
      <Box className="reportSection">

        <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
          <Image src={orgImage} width="50px" height="50px" />
          <Text fontWeight='600' fontSize="2em" marginLeft="5px">{ organisation.org_name }</Text>
        </Box>

        <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
          <Text fontWeight='600' fontSize="3em">TCFD Report</Text>
          <Text fontWeight='500' fontSize="1.5em">Jan 1, {yearReport} &ndash; Dec 31, {yearReport}</Text>
        </Stack>

        <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
          <Text fontWeight='600' fontSize="1em">Report Generated On</Text>
          <Text fontWeight='400' fontSize="1em">{currentDate}</Text>
        </Stack>

        <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
          <Text fontWeight='600' fontSize="1em">Report Generated For</Text>
          <Text fontWeight='bold' fontSize="1em">{section_0_org_name}</Text>
          <Text fontWeight='400' fontSize="1em">{section_0_address}</Text>
          <Text fontWeight='400' fontSize="1em">{section_0_address_city_state_zip}</Text>
          <Spacer />
          <Hide>
            <Button bg="tangerine.500" size='sm'>Edit Organisation Address</Button>
          </Hide>
        </Stack>

        <Stack display='flex' alignItems='center' justifyContent='center' width='100%' marginTop='60px'>
          <Text fontWeight='600' fontSize="1em">Carbon Analysis By</Text>
          <Image src="/images/logos/mark-and-text-white3x.png" width="200px" className="noPrint" />
          <Image src="/images/logos/mark-colour-text-black3x.png" width="200px" className="onlyPrint" />
        </Stack>
      </Box>
    </>
  }

  const Introduction = () => {
    return <>
      <a name="descriptiveInformation"></a>
      <Box className="reportSection printPageBreak">
        <Text fontSize="2em">Introduction</Text>
        <Hide>
          <Button bg="tangerine.500" size='sm' margin="20px 0">Edit Introduction</Button>
        </Hide>
        <Text margin="20px 0">&mdash;</Text>
      </Box>
    </>
  }

  const Governance = () => {
    return <>
      <a name="governance"></a>
      <Box className="reportSection printPageBreak">
        <Text fontSize="2em">Governance</Text>
        <Hide>
          <Button bg="tangerine.500" size='sm' margin="20px 0">Edit Governance Details</Button>
        </Hide>
        <Text fontStyle="Italic">Disclose the organisation's governance around climate-related risks and opportunities.</Text>
  
        <Text fontSize="1.4em" marginTop="40px">a. Describe the board's oversight of climate-related risks and opportunities.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        <Text fontSize="1.4em" marginTop="40px">b. Describe management's role in assessing and managing climate-related risks and opportunities.</Text>
        <Text margin="20px 0">&mdash;</Text>
      </Box>
    </>
  }

  const Strategy = () => {
    return <>
      <a name="strategy"></a>
      <Box className="reportSection printPageBreak">
        <Text fontSize="2em">Strategy</Text>
        <Hide>        
          <Button bg="tangerine.500" size='sm' margin="20px 0">Edit Strategy Details</Button>
        </Hide>
        <Text fontStyle="Italic">Disclose the actual and potential impacts of climate-related risks and opportunities on the organisation's businesses, strategy, and financial planning where such information is material.</Text>
  
        <Text fontSize="1.4em" marginTop="40px">a. Describe the climate-related risks and opportunities the organisation has identified over the short, medium, and long term.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        <Text fontSize="1.4em" marginTop="40px">b. Describe the impact of climate-related risks and opportunities on the organisation's businesses, strategy, and financial planning.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        <Text fontSize="1.4em" marginTop="40px">c. Describe the resilience of the organisation's staregy, taking into consideration different climate-related scenarios, including a 2°C scenario.</Text>
        <Text margin="20px 0">&mdash;</Text>
      </Box>
    </>
  }

  const RiskManagement = () => {
    return <>
      <a name="riskManagement"></a>
      <Box className="reportSection printPageBreak">
        <Text fontSize="2em">Risk Management</Text>
        <Hide>
          <Button bg="tangerine.500" size='sm' margin="20px 0">Edit Risk Management Details</Button>
        </Hide>
        <Text fontStyle="Italic">Disclose how the organisation identifies, assesses, and manages climate-related risks.</Text>
  
        <Text fontSize="1.4em" marginTop="40px">a. Describe the organisation's processes for identifying and assessing climate-related risks.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        <Text fontSize="1.4em" marginTop="40px">b. Describe the organisation's processes for managing climate-related risks.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        <Text fontSize="1.4em" marginTop="40px">c. Describe how processes for identifying, assessing, and managing climate-related risks are integrated into the organisation's overall risk management.</Text>
        <Text margin="20px 0">&mdash;</Text>
      </Box>
    </>
  }

  const MetricsTargets = () => {
    return <>
      <a name="metricsAndTargets"></a>
      <Box className="reportSection printPageBreak">
        <Text fontSize="2em">Metrics and Targets</Text>
        <Hide>
          <Button bg="tangerine.500" size='sm' margin="20px 0">Edit Metrics and Targets Details</Button>
        </Hide>
        <Text fontStyle="Italic">Disclose the metrics and targets used to assess and manage relevant climate-related risks and opportunities where such information is material.</Text>
  
        <Text fontSize="1.4em" marginTop="40px">Disclose the metrics used by the organisation to assess climate-related risks and opportunities in line with its strategy and risk management process.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        {section_4_icp_show === true &&
          <>
            <Text fontSize="1.2em" margin="20px 0">Internal carbon price</Text>
            <Text>We have an internal carbon price that is applied across our organisation at { section_4_icp_display } t/CO2e.</Text>
          </>
        }
  
        <Text fontSize="1.4em" marginTop="40px">b. Disclose Scope 1, Scope 2, and, if appropriate, Scope 3 greenhouse gas (GHG) emissions, and the related risks.</Text>
        <Text>&mdash;</Text>
  
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='40px'>
          <Text fontWeight='400' fontSize="1em">This section is automatically populated by emissions data available within Expect.</Text>
        </Stack>
  
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Overall CO<sub>2</sub>e Emissions</Text>
          <Text fontWeight='500' fontSize="1.2em">{orgEmissionsOverallDisplay} tonnes</Text>
        </Stack>
  
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Emissions by Scope</Text>
        </Stack>
  
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scopes and Categories</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Scope 1 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope1Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 2 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope2Display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Scope 3 Emissions</Td>
                <Td isNumeric>{orgEmissionsScope3Display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
  
  
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Scope 3 Upstream Emissions</Text>
        </Stack>
  
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 1</Td>
                <Td>Purchased Goods and Services</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[1].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 2</Td>
                <Td>Capital Goods</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[2].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 3</Td>
                <Td>Fuel- and Energy-Related Activities</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[3].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 4</Td>
                <Td>Upstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[4].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 5</Td>
                <Td>Waste Generated in Operations</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[5].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 6</Td>
                <Td>Business Travel</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[6].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 7</Td>
                <Td>Employee Commuting</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[7].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 8</Td>
                <Td>Upstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[8].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
  
  
        <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
          <Text fontWeight='500' fontSize="1.5em">Scope 3 Downstream Emissions</Text>
        </Stack>
  
        <TableContainer>
          <Table variant='simple'>
            <Thead>
              <Tr>
                <Th>Scope 3 Category</Th>
                <Th>Scope 3 Upstream Emissions</Th>
                <Th isNumeric>CO<sub>2</sub>e Emissions</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Category 9</Td>
                <Td>Downstream Transportation and Distribution</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[9].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 10</Td>
                <Td>Processing of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[10].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 11</Td>
                <Td>Use of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[11].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 12</Td>
                <Td>End-of-Life Treatment of Sold Products</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[12].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 13</Td>
                <Td>Downstream Leased Assets</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[13].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 14</Td>
                <Td>Franchises</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[14].emissions_tonnes_display} tonnes</Td>
              </Tr>
              <Tr>
                <Td>Category 15</Td>
                <Td>Investments</Td>
                <Td isNumeric> {orgEmissionsScope3CategoriesDisplay[15].emissions_tonnes_display} tonnes</Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
  
        <Text fontSize="1.4em" marginTop="40px">c. Describe how processes for identifying, assessing, and managing climate-related risks are integrated into the organisation's overall risk management.</Text>
        <Text margin="20px 0">&mdash;</Text>
  
        {section_4_targets_show === true &&
          <>
            <Text fontSize="1.2em" margin="20px 0">Our organisational decarbonisation targets are as follows</Text>
            <UnorderedList>
              {targetsOrdered.map(t => (
                <ListItem>By {t.year}, we will reduce emissions in Scope {t.scope} by {t.reduction_percentage}%</ListItem>
              ))}
            </UnorderedList>
          </>
        }
      </Box>
    </>
  }


  return (
    <>
      <Header />
      <Summary />
      <CoverPage />
      <Introduction />
      <Governance />
      <Strategy />
      <RiskManagement />
      <MetricsTargets />
    </>
  )

}

export default TCFD