import React, { useContext } from 'react'
import { Outlet, Routes, Route, useNavigate, useParams } from 'react-router-dom'
import { Box, Flex, Spacer, Stack, Text } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer } from '@chakra-ui/react'
import { safeLog } from '../../../helpers/logging'
import { useEmissions, useEmissionsCompoundsByScope, useOrganisation, useReport, useTopEmissionSources } from '../../../services/APIHooks'

export const CompoundsTable = (token, orgId, year) => {

  let compounds = new Object();

  const orgEmissionsCompoundsScope1 = useEmissionsCompoundsByScope(token, orgId, 1, parseInt(year));
  safeLog('orgEmissionsCompoundsScope1')
  safeLog(orgEmissionsCompoundsScope1)
  if (orgEmissionsCompoundsScope1?.length) {
    orgEmissionsCompoundsScope1.forEach(function(el) {
      if (el.compound == "CO2") {
        compounds.emissionsScope1CO2 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope1CO2Display = compounds.emissionsScope1CO2.toLocaleString();
        compounds.emissionsScope1CO2ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope1CO2ToCO2eDisplay = compounds.emissionsScope1CO2ToCO2e.toLocaleString();
      } else if (el.compound == "CH4") {
        compounds.emissionsScope1CH4 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope1CH4Display = compounds.emissionsScope1CH4.toLocaleString();
        compounds.emissionsScope1CH4ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope1CH4ToCO2eDisplay = compounds.emissionsScope1CH4ToCO2e.toLocaleString();
      } else if (el.compound == "N20") {
        compounds.emissionsScope1N20 = 5; // Math.round(el.compound_total / 1000)
        compounds.emissionsScope1N20Display = compounds.emissionsScope1N20.toLocaleString();
        compounds.emissionsScope1N20ToCO2e = 6; // Math.round(el.emissions_total / 1000)
        compounds.emissionsScope1N20ToCO2eDisplay = compounds.emissionsScope1N20ToCO2e.toLocaleString();
      } else if (el.compound == "HFC") {
        compounds.emissionsScope1HFC = Math.round(el.compound_total / 1000)
        compounds.emissionsScope1HFCDisplay = compounds.emissionsScope1HFC.toLocaleString();
        compounds.emissionsScope1HFCToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope1HFCToCO2eDisplay = compounds.emissionsScope1HFCToCO2e.toLocaleString();
      } else if (el.compound == "SF6") {
        compounds.emissionsScope1SF6 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope1SF6Display = compounds.emissionsScope1SF6.toLocaleString();
        compounds.emissionsScope1SF6ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope1SF6ToCO2eDisplay = compounds.emissionsScope1SF6ToCO2e.toLocaleString();
      }
    })
  }

  const orgEmissionsCompoundsScope2 = useEmissionsCompoundsByScope(token, orgId, 2, parseInt(year));
  safeLog('orgEmissionsCompoundsScope2')
  safeLog(orgEmissionsCompoundsScope2)
  if (orgEmissionsCompoundsScope2?.length) {
    orgEmissionsCompoundsScope2.forEach(function(el) {
      if (el.compound == "CO2") {
        compounds.emissionsScope2CO2 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope2CO2Display = compounds.emissionsScope2CO2.toLocaleString();
        compounds.emissionsScope2CO2ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope2CO2ToCO2eDisplay = compounds.emissionsScope2CO2ToCO2e.toLocaleString();
      } else if (el.compound == "CH4") {
        compounds.emissionsScope2CH4 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope2CH4Display = compounds.emissionsScope2CH4.toLocaleString();
        compounds.emissionsScope2CH4ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope2CH4ToCO2eDisplay = compounds.emissionsScope2CH4ToCO2e.toLocaleString();
      } else if (el.compound == "N20") {
        compounds.emissionsScope2N20 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope2N20Display = compounds.emissionsScope2N20.toLocaleString();
        compounds.emissionsScope2N20ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope2N20ToCO2eDisplay = compounds.emissionsScope2N20ToCO2e.toLocaleString();
      } else if (el.compound == "HFC") {
        compounds.emissionsScope2HFC = Math.round(el.compound_total / 1000)
        compounds.emissionsScope2HFCDisplay = compounds.emissionsScope2HFC.toLocaleString();
        compounds.emissionsScope2HFCToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope2HFCToCO2eDisplay = compounds.emissionsScope2HFCToCO2e.toLocaleString();
      } else if (el.compound == "SF6") {
        compounds.emissionsScope2SF6 = Math.round(el.compound_total / 1000)
        compounds.emissionsScope2SF6Display = compounds.emissionsScope2SF6.toLocaleString();
        compounds.emissionsScope2SF6ToCO2e = Math.round(el.emissions_total / 1000)
        compounds.emissionsScope2SF6ToCO2eDisplay = compounds.emissionsScope2SF6ToCO2e.toLocaleString();
      }
    })
  }

  safeLog('compounds');
  safeLog(compounds);

  return (
    <>
      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.5em">Greenhouse Gas Emissions by Compound</Text>
      </Stack>

      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.25em">CO<sub>2</sub> &mdash; Carbon Dioxide</Text>
      </Stack>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>CO<sub>2</sub> &mdash; Carbon Dioxide</Th>
              <Th isNumeric>CO<sub>2</sub> Emissions</Th>
              <Th isNumeric>CO<sub>2</sub> Emissions converted to CO<sub>2</sub>e</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Scope 1</Td>
              <Td isNumeric>{ compounds.emissionsScope1CO2 ? compounds.emissionsScope1CO2Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope1CO2ToCO2e ? compounds.emissionsScope1CO2ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
            <Tr>
              <Td>Scope 2</Td>
              <Td isNumeric>{ compounds.emissionsScope2CO2 ? compounds.emissionsScope2CO2Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope2CO2ToCO2e ? compounds.emissionsScope2CO2ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.25em">CH<sub>4</sub> &mdash; Methane</Text>
      </Stack>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>CH<sub>4</sub> &mdash; Methane</Th>
              <Th isNumeric>CH<sub>4</sub> Emissions</Th>
              <Th isNumeric>CH<sub>4</sub> Emissions converted to CO<sub>2</sub>e</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Scope 1</Td>
              <Td isNumeric>{ compounds.emissionsScope1CH4Display ? compounds.emissionsScope1CH4Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope1CH4ToCO2eDisplay ? compounds.emissionsScope1CH4ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
            <Tr>
              <Td>Scope 2</Td>
              <Td isNumeric>{ compounds.emissionsScope2CH4Display ? compounds.emissionsScope2CH4Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope2CH4ToCO2eDisplay ? compounds.emissionsScope2CH4ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.25em">N<sub>2</sub>O &mdash; Nitrous Oxide</Text>
      </Stack>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>N<sub>2</sub>O &mdash; Nitrous Oxide</Th>
              <Th isNumeric>N<sub>2</sub>O Emissions</Th>
              <Th isNumeric>N<sub>2</sub>O Emissions converted to CO<sub>2</sub>e</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Scope 1</Td>
              <Td isNumeric>{ compounds.emissionsScope1N20Display ? compounds.emissionsScope1N20Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope1N20ToCO2eDisplay ? compounds.emissionsScope1N20ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
            <Tr>
              <Td>Scope 2</Td>
              <Td isNumeric>{ compounds.emissionsScope2N20Display ? compounds.emissionsScope2N20Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope2N20ToCO2eDisplay ? compounds.emissionsScope2N20ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.25em">HFCs &mdash; Hydrofluorocarbons</Text>
      </Stack>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>HFC &mdash; Hydrofluorocarbons</Th>
              <Th isNumeric>HFC Emissions</Th>
              <Th isNumeric>HFC Emissions converted to CO<sub>2</sub>e</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Scope 1</Td>
              <Td isNumeric>{ compounds.emissionsScope1HFCDisplay ? compounds.emissionsScope1HFCDisplay : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope1HFCToCO2eDisplay ? compounds.emissionsScope1HFCToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
            <Tr>
              <Td>Scope 2</Td>
              <Td isNumeric>{ compounds.emissionsScope2HFCDisplay ? compounds.emissionsScope2HFCDisplay : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope2HFCToCO2eDisplay ? compounds.emissionsScope2HFCToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>

      <Stack display='flex' alignItems='left' justifyContent='left' width='100%' marginTop='60px'>
        <Text fontWeight='500' fontSize="1.25em">SF<sub>6</sub> &mdash; Sulphur Hexafluoride</Text>
      </Stack>

      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>SF<sub>6</sub> &mdash; Sulphur Hexafluoride</Th>
              <Th isNumeric>SF<sub>6</sub> Emissions</Th>
              <Th isNumeric>SF<sub>6</sub> Emissions converted to CO<sub>2</sub>e</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Scope 1</Td>
              <Td isNumeric>{ compounds.emissionsScope1SF6Display ? compounds.emissionsScope1SF6Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope1SF6ToCO2eDisplay ? compounds.emissionsScope1SF6ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
            <Tr>
              <Td>Scope 2</Td>
              <Td isNumeric>{ compounds.emissionsScope2SF6Display ? compounds.emissionsScope2SF6Display : 0 } tonnes</Td>
              <Td isNumeric>{ compounds.emissionsScope2SF6ToCO2eDisplay ? compounds.emissionsScope2SF6ToCO2eDisplay : 0 } tonnes</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}