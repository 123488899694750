import React, { useContext } from 'react'
import { Box, Spinner, Text } from '@chakra-ui/react'
import OverviewKPIs from './OverviewKPIs'
import ScopeOverviewChart from '../Charts/ScopeOverviewChart'
import { Outlet, useOutletContext } from 'react-router-dom'
import { useIntensities, useEmissions } from '../../../services/APIHooks'
import Card from '../../General/Card'
import { displayCarbon } from '../../../helpers/formatValues'

const Emissions = (props) => {

  const auth = useContext(props.context)

  const outletContext = Object(useOutletContext())

  const intensityData = useIntensities(auth.token, auth.orgId, outletContext.year)
  const orgEmissions = useEmissions(auth.token, auth.orgId, null, outletContext.year)

  const chart = orgEmissions.emissions_total_summary ?
    <ScopeOverviewChart
      emissions_total={orgEmissions.emissions_total_summary}
      emissions_total_uom={orgEmissions.emissions_total_summary_uom}
      scope1={orgEmissions.emissions_scope1_tonnes}
      scope2={orgEmissions.emissions_scope2_tonnes}
      scope3={orgEmissions.emissions_scope3_tonnes}
    />
      :
    <Box h={"350px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Spinner />
    </Box>

  return (
    <>
      <Card style={{ padding: "20px", marginTop: "30px", marginBottom: "20px" }}>
        <Text> Total CO2 emissions </Text>
        <Text className='subtitle'> Your scope 1, 2 and 3 emissions overview </Text>
        {chart}
      </Card>

      <OverviewKPIs
        carbon_price={orgEmissions.carbon_price}
        carbon_price_currency={orgEmissions.carbon_price_currency || ''}
        carbon_cost={orgEmissions.emissions_total_cost_display}
        emissions_scope1={orgEmissions.emissions_scope1_tonnes != null ? displayCarbon(orgEmissions.emissions_scope1_tonnes * 1000) : null}
        emissions_scope2={orgEmissions.emissions_scope2_tonnes != null ? displayCarbon(orgEmissions.emissions_scope2_tonnes * 1000) : null}
        emissions_scope3={orgEmissions.emissions_scope3_tonnes != null ? displayCarbon(orgEmissions.emissions_scope3_tonnes * 1000) : null}
        intensity_carbon={intensityData.intensity_carbon}
        intensity_carbon_uom={intensityData.intensity_carbon_uom}
        intensity_energy={intensityData.intensity_energy}
        intensity_energy_uom={intensityData.intensity_energy_uom}
      />

      <Outlet />

    </>
  )
}

export default Emissions