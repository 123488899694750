import React, { useContext, useEffect, useState } from 'react'
import { Flex, Spacer, Box, Select } from '@chakra-ui/react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import NavigationBreadcrumbs from '../Navigation/NavigationBreadcrumbs'
import { useIdNames, useLocationIdNames, usePopulatedYears } from '../../services/APIHooks'
import { objectToArray } from '../../helpers/objectToArray'
import LoadingOrgYears from '../Blank/LoadingOrgYears'
import DataNotAvailable from '../Blank/DataNotAvailable'

const EmissionsHeader = (props) => {

  const auth = useContext(props.context)

  const populatedYears = usePopulatedYears(auth.token, auth.orgId)
  const [year, setYear] = useState(auth.year)

  useEffect(() => {
    if (populatedYears.length && !populatedYears.includes(Number(year))) {
      setYear(populatedYears[0].toString())
    }
  }, [auth.year, populatedYears, year])

  const location = useLocation()

  const yearSelectChange = (event) => {
    const newYear = Number(event.target.value)
    setYear(newYear)
    auth.updateYear(newYear)
  }

  const YearOptions = () => {
    const lastYear = new Date().getFullYear() - 1

    if (populatedYears?.length) {
      const startYear = populatedYears[0]
      const endYear = Math.max(populatedYears[populatedYears.length - 1], lastYear)
      const years = []
      for (let y = startYear; y <= endYear; y++)
        years.push(<option key={y}> {y} </option>)
      return <> {years} </>
    } else {
      return <option>{ lastYear }</option>
    }
  }

  const Breadcrumbs = () => {
    const params = useParams()

    let section = null

    if (location.pathname.includes('scope3/category'))
      section = 'scope3'
    else if (location.pathname.includes('locations/'))
      section = 'locations'

    const idNames = useIdNames(auth.token, auth.orgId, section,
      params.category, params.subcategory, params.tertiarycategory)

    const locationNames = useLocationIdNames(auth.token, auth.orgId, params.locationId,
      params.systemId, params.unitId, params.equipmentId)

    const overwrite = [...objectToArray(idNames), ...objectToArray(locationNames)]

    return <NavigationBreadcrumbs willOverwrite={Boolean(section)} overwrite={overwrite}/>

  }

  const Content = () => {
    if (!populatedYears)
      return <LoadingOrgYears />

    if (!populatedYears?.includes(Number(year)))
      return <DataNotAvailable year={year}/>

    return <Outlet context={{year}}/>
  }

  return (
    <Box>
      <Flex>
        <Box>
          {<Breadcrumbs/>}
        </Box>

        <Spacer/>

        <Box width={"15em"}>
          <Select value={year} variant="filled" onChange={yearSelectChange}>
            <YearOptions/>
            {/* {
              (populatedYears).map(year => (
                <option key={year}> {year} </option>
              ))
            } */}
          </Select>
        </Box>
      </Flex>

      <Content/>
    </Box>
  )
}

export default EmissionsHeader