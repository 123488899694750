import { Box, Button, Modal, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React from 'react'

const ConfirmModal = (props) => {

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box onClick={onOpen}>
        {props.openModal}
      </Box>

      <Modal isOpen={isOpen} onClose={onClose} preserveScrollBarGap isCentered>
        <ModalOverlay />
        <ModalContent bg="ui.200" >
          <ModalHeader fontSize={"16px"} fontWeight="normal" maxW="400px"> {props.prompt} </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button bg="danger.high" _hover={{ bg: "danger.medium" }} onClick={props.onContinue} isLoading={props.loading}>
              {props.continuePrompt}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModal