/**
 * Return the current environment.
 * 
 * Options: "dev", "staging", "production"
 */
export const env = () => {
  if (window.location.host.includes('localhost')) {
    return 'dev';
  } else if (window.location.host.includes('staging')) {
    return 'staging';
  } else {
    return 'production';
  }
}

/**
 * Print the current window.
 */
export const printWindow = (e) => {
  e.preventDefault();
  window.print();
}
