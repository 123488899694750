import { Flex, Spacer } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import SideNav from '../Navigation/SideNav'
import User from '../Navigation/User'

const DefaultContainer = (props) => {

  const auth = useContext(props.context)

  return (
    <>
      <SideNav context={props.context} />

      <Flex ml="230px" padding={"40px 80px"} minH='100vh' direction={'column'}>
        <Flex gap='1em' mb='2em' alignItems={'center'}>
          <Spacer/>
          <User user={auth.user} name={auth.name} orgId={auth.orgId}/>
        </Flex>
        <Outlet />
      </Flex>
    </>
  )
}

export default DefaultContainer