import { CloseIcon, EditIcon } from '@chakra-ui/icons'
import { ButtonGroup, Editable, EditableInput, EditablePreview, Flex, IconButton, Input, Spacer, Text, useEditableControls } from '@chakra-ui/react'
import React from 'react'

const EditableICP = (props) => {
  const EditableControls = () => {
    const {
      isEditing,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls()

    return isEditing ? (
      <ButtonGroup justifyContent='center' size='lg' gap='0.5em'>
        <IconButton aria-label='cancel edit'
          bg='tangerine.500' _hover={{bg: 'tangerine.600'}}
          icon={<CloseIcon />} {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent='center'>
        <IconButton aria-label='edit' size='lg' icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    )
  }

  return (
    <Editable
      defaultValue={props.default}
      fontSize='32px' alignItems={'center'} maxH='54px'
      padding={0} display='flex' gap='0.2em' w='100%'
      onChange={e => props.update(e)}
    >
      <EditablePreview />

      <Input
        as={EditableInput} textAlign='center' minW='20px' overflow='hidden'
        maxW={'60px'} maxH='54px' fontSize={'16px'} padding={0}
      />

      <Text fontSize={'24px'}>
        {props.currencySymbol}/tonne
      </Text>

      <Spacer />

      <EditableControls />
    </Editable>
  )
}

export default EditableICP