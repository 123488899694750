import { Button, ButtonGroup, Center, Heading, VStack } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Catch404 = () => {
  const navigate = useNavigate()

  const back = () => navigate(-1)

  const home = () => navigate("/")

  return <Center h="100vh">
    <VStack gap="2em">
      <Heading> 404 Page Not Found</Heading>

      <ButtonGroup>
          <Button variant={"outline"} minW="100px" onClick={back}>Go back</Button>
          <Button variant={"outline"} minW="100px" color="expect.sky" onClick={home}>Go home</Button>
      </ButtonGroup>
    </VStack>
  </Center>
}

export default Catch404