import { Flex, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const DataSourceDisplay = (props) => {

  const sources = props.dataSources

  const getSourceIcon = (sourceType) => {
    if (sourceType === "Excel")
      return "/icons/filetypes/excel.svg"
    if (sourceType === "CSV")
      return "/icons/filetypes/csv.svg"
    if (sourceType === "api")
      return "/icons/filetypes/api.svg"
  }

  const dateTimeDisplay = (date) => {
    const dateObject = new Date(date)

    return <>
      {dateObject.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' })} @ {dateObject.getHours()}
      :{dateObject.getMinutes()} GMT {dateObject.getTimezoneOffset() / 60}
    </>
  }

  if (!sources?.length)
    return <>&mdash;</>

  return (
    <VStack align={"flex-start"} overflowY="auto" maxH={"180px"} w="100%" alignItems={'stretch'}>
      {sources.map((source, index) =>
        <Flex gap="12px" key={index}>
          <Image src={getSourceIcon(source[1])} />
          <Flex direction={'column'}>
            <Text>
              {source[0]}
            </Text>

            <Text fontSize={12}>
              {dateTimeDisplay(source[2])}
            </Text>
          </Flex>
        </Flex>
      )}
    </VStack>
  )
}

export default DataSourceDisplay