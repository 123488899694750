import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React from 'react'
import { getMapOptions } from '../../../helpers/mapChartProvider'
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import { displayCarbon } from '../../../helpers/formatValues';

highchartsMap(Highcharts)

const BubbleMap = (props) => {

  if (typeof window !== "undefined") {
    window.proj4 = window.proj4 || proj4;
  }

  const chartOptions = getMapOptions()

  chartOptions.series[0].data = props.data.map(location => {
    return {
      code: location.locale.code,
      name: location.locale.name,
      value: location.emissions
    }
  })
  chartOptions.tooltip.formatter = function () {
    return '<b>' + this.key + ':</b> ' + displayCarbon(this.point.value)
  }

  chartOptions.chart.height = props.height
  chartOptions.chart.width = props.width

  return <HighchartsReact
    constructorType='mapChart'
    highcharts={Highcharts}
    options={chartOptions}
  />
}

export default BubbleMap