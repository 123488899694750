import './flippable.css'
import { Box } from '@chakra-ui/react'
import React, { useState } from 'react'

const FlippableContainer = (props) => {

  const [flip, setFlip] = useState(false)

  const onClick = () => setFlip(!flip)

  return (
    <Box className={'flip-card'} onClick={onClick} cursor='pointer' h={props.h}>
      <Box className={'flip-card-inner' + (flip ? ' flip' : '')}>
        <Box className='flip-card-front'>
          {props.front}
        </Box>

        <Box className='flip-card-back'>
          {props.back}
        </Box>
      </Box>
    </Box>
  )
}

export default FlippableContainer