import { Box, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import InitiativeCard from './InitiativeCard'

const InitiativeGrid = (props) => {

  return (
    <SimpleGrid minChildWidth={"350px"} spacing={6}>

      {props.newInitiatives.map((initiative, index) =>
        <Box minHeight={"246px"} key={index}>
          <InitiativeCard
            initiative={initiative}
            editInitiative={props.editInitiative}

            currency={props.currency}
            baselineYear={props.baselineYear}
            finalYear={props.finalYear}
            initiativeYearChange={props.initiativeYearChange}
          />
        </Box>
      )}

      {props.initiatives.map((initiative, index) =>
        <Box minHeight={"246px"} key={index}>
          <InitiativeCard
            initiative={initiative}
            editInitiative={props.editInitiative}

            currency={props.currency}
            baselineYear={props.baselineYear}
            finalYear={props.finalYear}
            initiativeYearChange={props.initiativeYearChange}
          />
        </Box>
      )}

    </SimpleGrid>
  )
}

export default InitiativeGrid