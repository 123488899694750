import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, { useState } from 'react'
import { defaultDonutChartSettings } from '../../helpers/emissionsChartProvider'
import theme from '../../theme'

const PercentageDonut = (props) => {

  const [ref, setChartRef] = useState() // Chart sometimes doesn't render if ref not stored in state

  const spectrum = [
    theme.colors.cherry['500'],
    theme.colors.canyon['500'],
    theme.colors.canary['500'],
    theme.colors.lime['500'],
    theme.colors.aloe['500'],
  ]
  const pieColor = spectrum[Math.ceil((props.value / 100 * spectrum.length)) - 1]

  const chartOptions = defaultDonutChartSettings()
  chartOptions.series = [{
    data: [props.value || 0, 100 - props.value],
    enableMouseTracking: false
  }]
  chartOptions.chart.height = 100
  chartOptions.chart.width = 100
  chartOptions.legend.enabled = false
  chartOptions.plotOptions.pie.innerSize = '50%'
  chartOptions.plotOptions.pie.borderWidth = 0
  chartOptions.plotOptions.pie.colors = [pieColor, theme.colors.ui['500']]

  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions}
      // @ts-ignore
      ref={setChartRef}
    />
  )
}

export default PercentageDonut