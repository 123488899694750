import { Box, Flex, Spacer, Stat, StatLabel, StatNumber } from '@chakra-ui/react'
import React from 'react'
import Card from './Card'
import ExpandModalButton from './ExpandModalButton'
import LinkArrowButton from './LinkArrowButton'
import LinkExternalButton from './LinkExternalButton'
import PercentageDonut from './PercentageDonut'

const KPI = (props) => {

  let value = props.value
  if (typeof value === 'number' && isNaN(value)) {
    value = null
  }

  const LinkButton = () => {
    if (props.url && props.urlType === 'external')
      return (
        <LinkExternalButton
          url={props.url}
        />
      )
    if (props.url)
      return (
        <LinkArrowButton
          url={props.url}
        />
      )
    if (props.buttonFn)
        return (
          <ExpandModalButton
            onClick={props.buttonFn}
            enable={false}
          />
        )
    return <></>
  }

  const Donut = () => {
    if (props.percentage && value) {
      return <PercentageDonut value={value} />
    }
    if (props.percentage) {
      return <Box w="100px" h="100px"></Box>
    }
    return <></>
  }

  let uom = props.uom ? '\u202F' + props.uom : ''
  if (props.percentage)
    uom = '%'

  return (
    <Card padding={"20px"}>
      <Flex alignItems={"start"} h="100%">

        <Stat>
          <StatLabel fontSize={"16px"}> {props.label} </StatLabel>
          <StatNumber fontSize={"32px"} fontWeight={500} mt="6px">
            {value ?? <>&mdash;</>}{'\u202F'}{uom}
          </StatNumber>
        </Stat>

        <Flex direction={"column"} h="100%">
          <Spacer/>
          <Donut/>
          <LinkButton />
        </Flex>
      </Flex>
    </Card>
  )
}

export default KPI