import React from 'react'
import { Center, Heading } from '@chakra-ui/react'

const ComingSoon = () => {
  return (
    <Center h="100%" flex='1 1 auto'>
      <Heading>
        Coming soon
      </Heading>
    </Center>
  )
}

export default ComingSoon