export const applyInitiative = (chart, startYear, carbonChange, oldStartYear = null, updateKPIFn) => {
  // Reverse previous effect on roadmap
  if (oldStartYear) {
    applyInitiative(chart, oldStartYear, -carbonChange)
  }

  if (startYear) {
    startYear = new Date(startYear, 0)

    let roadmapSeries = chart.series.find(series => series.name.toLowerCase().includes('roadmap trajectory'))

    let newData = roadmapSeries.options.data.map(point => {
      return {
        x: point.x,
        y: (point.x >= startYear) ? point.y + carbonChange : point.y
      }
    })

    roadmapSeries.setData(newData)
  }

  // if (updateKPIFn)
  //   updateKPIFn(getKPIObject(chart, 125, chart.xAxis.max? new Date(chart.xAxis.max) : null))
}