import { Flex, FormControl, FormHelperText, FormLabel, Input, Select, SimpleGrid, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'

const InitiativeForm = (props) => {

  const yearOptions = []
  for (let year = props.baselineYear; year <= props.finalYear; year++)
    yearOptions.push(year)
  const monthOptions = []
  for (let i = 0; i <= 11; i++)
    monthOptions.push(new Date(0, i).toLocaleString('default', { month: 'short' }))

  const currentYear = new Date(props.initiative.carbon_impact_date).getFullYear()
  const currentMonth = new Date(props.initiative.carbon_impact_date).getMonth() || 0

  const [disableMonthSelect, setDisableMonth] = useState(
    !props.initiative.carbon_impact_date
  )

  const getUpdateDataFn = (attributeToUpdate) => {
    return (event) => {
      let newValue = event.target.value
      const dataCopy = props.newData

      if (attributeToUpdate.slice(0, -1) == 'carbon_annual_scope_') {
        // Convert to kg
        newValue *= 1000

        // Recalculate carbon_annual_total
        dataCopy['carbon_annual_total'] = [1, 2, 3].map(n => dataCopy['carbon_annual_scope_' + n] || 0).reduce(
          (prev, current) => Number(prev) + Number(current)
        )
      }

      if (attributeToUpdate.startsWith('carbon_impact_date')) {
        let year = new Date(props.newData.carbon_impact_date).getFullYear()
        let month = currentMonth
        if (attributeToUpdate == 'carbon_impact_date_year')
          year = newValue
        else
          month = newValue

        if (month == NaN)
          month = 0

        if (attributeToUpdate == 'carbon_impact_date_year' && year
        || attributeToUpdate == 'carbon_impact_date_month'
        ) {
          dataCopy['carbon_impact_date'] = year + '/' + (Number(month) + 1) + '/01'
          setDisableMonth(false)
        }
        else {
          dataCopy['carbon_impact_date'] = ''
          setDisableMonth(true)
        }
      }
      else
        dataCopy[attributeToUpdate] = newValue

      props.setNewData(dataCopy)
    }
  }

  return (
    <Flex direction={"column"} gap="1em">
      <FormControl>
        <FormLabel> Initiative name </FormLabel>
        <Input onChange={getUpdateDataFn('initiative_name')} type={'text'} defaultValue={props.initiative.initiative_name} />
      </FormControl>

      <FormControl>
        <FormLabel> Initiative description </FormLabel>
        <Textarea onChange={getUpdateDataFn('initiative_description')} defaultValue={props.initiative.initiative_description} />
      </FormControl>

      <SimpleGrid minChildWidth={'300px'} gap='16px'>
        <FormControl>
          <FormLabel> Implementation year </FormLabel>
          <Select onChange={getUpdateDataFn('carbon_impact_date_year')} defaultValue={String(currentYear)}>
            <option key={0} value=''> &mdash; </option>
            {yearOptions.map(year => (
              <option key={year}> {year} </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel> Implementation month </FormLabel>
          <Select disabled={disableMonthSelect}
            onChange={getUpdateDataFn('carbon_impact_date_month')} defaultValue={String(currentMonth)}>
            <option key={-1} value=''> &mdash; </option>
            {monthOptions.map((month, index) => (
              <option key={month} value={index}> {month} </option>
            ))}
          </Select>
        </FormControl>

      </SimpleGrid>

      <FormControl>
        <FormLabel> Projected Scope 1 reduction </FormLabel>
        <Input onChange={getUpdateDataFn('carbon_annual_scope_1')} type={'number'}
        defaultValue={props.initiative.carbon_annual_scope_1 / 1000 || 0} />
        <FormHelperText> Tonnes </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel> Projected Scope 2 reduction </FormLabel>
        <Input onChange={getUpdateDataFn('carbon_annual_scope_2')} type={'number'}
        defaultValue={props.initiative.carbon_annual_scope_2 / 1000 || 0} />
        <FormHelperText> Tonnes </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel> Projected Scope 3 reduction </FormLabel>
        <Input onChange={getUpdateDataFn('carbon_annual_scope_3')} type={'number'}
        defaultValue={props.initiative.carbon_annual_scope_3 / 1000 || 0} />
        <FormHelperText> Tonnes </FormHelperText>
      </FormControl>

      <FormControl>
        <FormLabel> CAPEX </FormLabel>
        <Input onChange={getUpdateDataFn('cost_capex')} type={'number'} defaultValue={props.initiative.cost_capex || 0} />
        <FormHelperText> {props.currency} </FormHelperText>
      </FormControl>
    </Flex>
  )
}

export default InitiativeForm