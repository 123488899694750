import React from 'react'
import { SimpleGrid } from '@chakra-ui/react'
import KPI from '../../General/KPI'
import { displayCost } from '../../../helpers/formatValues'

const OverviewKPIs = (props) => {
  return (
    <>
      <SimpleGrid columns={3} spacing={"20px"}>

        <KPI
          label={"Scope 1 emissions"}
          value={props.emissions_scope1}
          url={"/emissions/scope1"}
        />

        <KPI
          label={"Scope 2 emissions"}
          value={props.emissions_scope2}
          url={"/emissions/scope2"}
        />

        <KPI
          label={"Scope 3 emissions"}
          value={props.emissions_scope3}
          url={"/emissions/scope3"}
        />

        <KPI
          label={"CO2e intensity"}
          value={props.intensity_carbon}
          uom={props.intensity_carbon_uom}
        />

        <KPI
          label={"Energy intensity"}
          value={props.intensity_energy}
          uom={props.intensity_energy_uom}
        />

        <KPI
          label={
            props.carbon_price != 0 ?
              <>Potential cost at {(displayCost(props.carbon_price, undefined,
                undefined, undefined, props.carbon_price_currency))} {(props.carbon_price_currency)}/tonne CO2e</>
              : <>Potential cost of CO2e</>
          }
          value={props.carbon_price != 0 ? props.carbon_cost : <>&mdash;</>}
        />
      </SimpleGrid>
    </>
  )
}

export default OverviewKPIs