import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React from 'react'
import { defaultDonutChartSettings } from '../../../helpers/emissionsChartProvider'
import { displayCarbon, round } from '../../../helpers/formatValues'
import { safeLog } from '../../../helpers/logging'
import theme from '../../../theme'

const MiniScopeChart = (props) => {
  const emissions = props.emissions
  safeLog(emissions)

  const chartOptions = defaultDonutChartSettings()

  chartOptions.chart.width=350
  chartOptions.chart.height=350

  // Legend
  chartOptions.legend.useHTML = true
  chartOptions.legend.style = {
    width: '100%'
  }

  // Legend formatter
  chartOptions.legend.labelFormatter = function () {
    return '<p class="legendTitle">'
      + this.name + '</p> <p class="legendText">'
      + round(this.percentage, 1)
      + '%</p>';
  }

  // chartOptions.title.text = '<span style="font-size: 32px;">' +
  //   displayCarbon(emissions.emissions_total)
  //   + '</span></br>' +
  //   '<span style="font-size: 70px; visibility: hidden">spacinghack</span>'

  chartOptions.chart.events = {render: function () {
    let chart = this
    let left = chart.plotWidth / 2 + chart.plotLeft
    let top = chart.plotHeight / 2 + chart.plotTop

    if (!chart.customText) {
      chart.customText = chart.renderer
        .text(displayCarbon(emissions.emissions_total), left, top, true)
        .attr({
          align: 'center'
        })
        .css({
          'font-size': '32px'
        })
        .addClass('customTitle').add()
    }
    chart.customText.attr({
      x: left,
      y: top
    });
  }}

  chartOptions.series =  [{
      name: 'Emissions by Scope',
      colorByPoint: true,
      percentageDecimals: 1,
      valueDecimals: 1,
      startAngle: 90,
      data: [
        { name: 'Scope 1', y: emissions.emissions_scope1, color: theme.colors.scope1[500] },
        { name: 'Scope 2', y: emissions.emissions_scope2, color: theme.colors.scope2[500] },
        { name: 'Scope 3', y: emissions.emissions_scope3, color: theme.colors.scope3[500] },
      ]
  }]

  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions} />
  )
}

export default MiniScopeChart