import React, { useContext, useState } from 'react'
import { Box, Flex, Heading, Spacer, Select, SimpleGrid, Spinner, Text, InputGroup, InputLeftElement, Input } from '@chakra-ui/react'
import { CheckIcon, SearchIcon } from '@chakra-ui/icons'
import DataTable from '../General/DataTable'
import KPI from '../General/KPI'
import { useIndustryCarbonMaturity } from '../../services/APIHooks'
import LinkExternalButton from '../General/LinkExternalButton'
import NavigationBreadcrumbs from '../Navigation/NavigationBreadcrumbs'
import { safeLog } from '../../helpers/logging'
import { alphabeticalSort } from '../../helpers/sorting'
import { objectToArray } from '../../helpers/objectToArray'

const InsightsindustryData = (props) => {

  const auth = useContext(props.context)
  const [industryId, setIndustryId] = useState(0)
  const { industryData, industryIds } = useIndustryCarbonMaturity(auth.token, industryId)
  safeLog(industryData, industryIds)

  const Header = () => {
    return <>
      <Box>
        <Flex>
          <Box>
            <Heading> Carbon Index </Heading>
          </Box>

          <Spacer />

          <Box width={"20em"}>
            <Select value={industryId} onChange={(e) => setIndustryId(Number(e.target.value))}>
              <option value={0} key={-1}> All industries </option>
              {industryIds
                .sort(alphabeticalSort('name'))
                .filter(industry => industry.name !== 'Construction' && industry.name !== 'Agriculture') // Todo: remove this line after it's removed from the API response
                .map((industry, index) =>
                  (<option key={index} value={industry.industry_id}> {industry.name} </option>)
                )}
            </Select>
          </Box>
        </Flex>
      </Box>
      <Text marginTop="20px">The carbon maturity public positioning
        for {(industryData.length >= 500 ? 'all' : industryData.length) || <>&mdash;</>} Fortune 500
        organisation{industryData.length > 1 ? 's' : ''}
        {
          industryId !== 0 ? (<>
            &nbsp;from the {industryIds.find(industry => industry.industry_id === industryId)?.name} industry
          </>) : null
        }
      </Text>
    </>
  }

  const Stats = () => {

    const getPercentage = (data, flag) => {
      if (data.length) {
        const count = data.filter(item => item[flag]).length
        return Math.round((count / data.length) * 100)
      }
      return null
    }

    const organisations_reports_found_percentage = getPercentage(industryData, 'sustainability_report')
    const organisations_targets_set_percentage = getPercentage(industryData, 'decarbonisation_target')
    const organisations_report_tcfd_percentage = getPercentage(industryData, 'tcfd_report')
    const organisations_report_cdp_percentage = getPercentage(industryData, 'cdp_report')
    const organisations_report_sbt_percentage = getPercentage(industryData, 'science_based_target')
    const organisations_report_icp_percentage = getPercentage(industryData, 'internal_carbon_price')

    return <>
      <SimpleGrid columns={3} spacing={"20px"} marginTop="40px">
        <KPI
          label={"Published a sustainability report"}
          value={organisations_reports_found_percentage}
          percentage
        />
        <KPI
          label={"Set a decarbonisation target"}
          value={organisations_targets_set_percentage}
          percentage
        />
        <KPI
          label={"Committed to a Science-based target"}
          value={organisations_report_sbt_percentage}
          percentage
        />
        <KPI
          label={"Published a CDP report"}
          value={organisations_report_cdp_percentage}
          percentage
        />
        <KPI
          label={"Published a TCFD report"}
          value={organisations_report_tcfd_percentage}
          percentage
        />
        <KPI
          label={"Set an Internal Carbon Price"}
          value={organisations_report_icp_percentage}
          percentage
        />
      </SimpleGrid>
    </>
  }

  const Table = () => {

    const [searchTerm, setSearchTerm] = useState('')

    const filterRow = row => {
      const searchCell = objectToArray(row)[0]?.toLowerCase()
      return searchCell?.split(' ').map(word =>
        word.startsWith(searchTerm.toLowerCase()))
          .includes(true) || searchCell?.startsWith(searchTerm.toLowerCase())
    }

    return <>
      <Box mt="2em">
        <Flex>
          <Spacer />
          <InputGroup w="15em">
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='onSurface.light.medium' />}
            />
            <Input
              value={searchTerm} onChange={e => setSearchTerm(e.target.value)}
              type='text' placeholder='Search'
            />
          </InputGroup>
        </Flex>

        {industryData.length ?
          <DataTable
            center
            filterFunction={filterRow}
            headings={['Organisation name', 'Emissions Published', 'Commitment type', 'Commitment Year',
              'Science based Target', 'CDP', 'TCFD', 'Internal Carbon Price', 'Source']}
            rows={industryData.sort(alphabeticalSort('organisation_name'))
              // .filter(filterRow)
              .map((company) => [
                company.organisation_name,
                company.sustainability_report ? <CheckIcon /> : null,
                <Text fontSize={"13px"} key={company.commitment_type}>
                  {company.commitment_type || <>&mdash;</>}
                </Text>,
                (company.commitment_year && company.commitment_year < 2101) ? String(company.commitment_year) : null,
                company.science_based_target ? <CheckIcon /> : null,
                company.cdp_report ? <CheckIcon /> : null,
                company.tcfd_report ? <CheckIcon /> : null,
                company.internal_carbon_price ? <CheckIcon /> : null,
                company.sustainability_report_url ? <LinkExternalButton url={company.sustainability_report_url} /> : null,
              ])}
          />
          :
          <Flex w="100%" justifyContent={"center"} alignItems="center">
            <Spinner />
          </Flex>
        }
      </Box>
    </>
  }

  return (
    <>
      <NavigationBreadcrumbs />
      <Header />
      <Stats />
      <Table />
    </>
  )

}

export default InsightsindustryData
