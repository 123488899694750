import { Flex, Image, Spacer, Text, VStack, Box } from '@chakra-ui/react'
import React from 'react'
import Card from '../../General/Card'
import CompassScore from './CompassScore'
import YearSelect from '../YearSelect'
import { displayCarbon, displayCost, round } from '../../../helpers/formatValues'
import TrendIcon from '../../General/TrendIcon'

const InitiativeCard = (props) => {

  const initiative = props.initiative.Initiative
  const slug = props.initiative.slug

  return (
    <Card padding={"0"}>
      <Flex
        cursor={"pointer"}
        onClick={props.onClick || (() => props.editInitiative(initiative))}
        h={"100%"}
        direction={"column"}
        alignItems={"stretch"}
      >

        <Flex w={"100%"} padding={"26px 26px 0 26px"}>
          <CompassScore score={initiative.compass_score}/>
          <Spacer/>
          <Flex alignItems={"center"}>
            <YearSelect
              initiative={initiative}
              variant={'unstyled'}
              baselineYear = {props.baselineYear}
              finalYear = {props.finalYear}
              initiativeYearChange = {props.initiativeYearChange}
            />
          </Flex>
        </Flex>

        <Spacer/>

        <Flex alignItems={"center"}>
          <Text
            padding={"26px"}
            w={"100%"}
            fontWeight={400}
            fontSize={"20px"}
            lineHeight={"120%"}
          >
            {initiative.initiative_name}
          </Text>
          <Spacer/>
          <Box border="2px solid" borderRadius={"6px"} borderColor={"ui.300"} marginRight={"24px"} >
            <Image w="32px" src={'/images/brands/' + slug + '.png'}/>
          </Box>

        </Flex>


        <Spacer/>

        <Flex
        w={"100%"}
        bg={"ui.200"}
        padding={"16px"}
        >
          <VStack alignItems={"left"}>
            <Box> Carbon </Box>
            <Flex gridGap={"8px"}>
              {displayCarbon(Math.abs(initiative.carbon_annual_total))}
              <TrendIcon value={-round(initiative.carbon_annual_total, 2)} />
            </Flex>
          </VStack>

          <Spacer/>

          <VStack alignItems={"left"}>
            <Box> Benefit </Box>
            <Flex gridGap={"8px"}>
              {displayCost(initiative.cost_annual_carbon || null, undefined, true, undefined, props.currency) || <>&mdash;</>}
              <TrendIcon invert value={-round(initiative.cost_annual_carbon, 2)} />
            </Flex>
          </VStack>

          <Spacer/>

          <VStack alignItems={"right"} textAlign={"right"}>
            <Box> Capex </Box>
            <Box>
              {displayCost(initiative.cost_capex, undefined, undefined, undefined, props.currency) || <>&mdash;</>}
            </Box>
          </VStack>

          {/* COST */}
          {/* <VStack alignItems={"left"}>
            <Box textAlign={"right"}> Cost </Box>
            <Flex gridGap={"8px"}>
              {initiative.cost_annual_total <= 0 ? '' : '-'}{displayCost(initiative.cost_annual_total, 0, true)}
              <TrendIcon trendUp={initiative.cost_annual_total < 0}/>
            </Flex>
          </VStack> */}
        </Flex>

      </Flex>
    </Card>
  )
}

export default InitiativeCard