import { Box, Button, Center, Divider, Flex, Image, Spacer, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import Card from '../../General/Card'
import ConnectionIcon from './ConnectionIcon'

const CompanyCard = (props) => {

  const company = props.company

  const [connected, setConnected] = useState(company.connected)

  const ConnectionButton = () => {
    if (company.comingSoon)
      return <Button minW={"142px"} variant={"outline"} _hover={{}} _active={{}} _focus={{}} cursor={"default"}>
        Coming soon
      </Button>
    if (connected)
      return <Button minW={"142px"}
        bg={"primary.primary"}
        color={"onSurface.dark.high"}
        padding="10px"
        _hover={{}}

        onClick={() => setConnected(false)}>
        Connected
      </Button>

    return <Button minW={"142px"}
      bg={"primary.softPrimaryDefault"}
      color="onSurface.primary.high"
      padding="10px"
      variant="ghost"

      onClick={() => setConnected(true)}
    >
      Connect
    </Button>
  }

  return (
    <Card padding={0}>
      <Flex padding={"22px"} gap="14px">
        <ConnectionIcon connected={connected} />
        <Box>
          <Text> {company.name} </Text>
          <Text className='subtitle' mt="8px"> {company.category} </Text>
        </Box>
      </Flex>

      <Divider />

      <Box padding="22px">
        <Center>
          <Image h="64px" src={"/images/brands/" + company.slug + ".png"} />
        </Center>
      </Box>

      <Divider />

      <Flex padding={"22px"} alignItems={"center"}>
        <Spacer />
        <ConnectionButton />
      </Flex>

    </Card>
  )
}

export default CompanyCard