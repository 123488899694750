import { Box, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const DataDisplay = (props) => {

  const rows = props.rows

  return (
    <>
      <VStack padding={""} gap="30px">

        {rows.map((row) => (
          <Box key={row} width={"100%"}>
            <Text
              textTransform={"capitalize"}
              fontFamily="SF Pro Display">
              {row[0]}
            </Text>
            <Box fontSize={"14px"} ml="12px" mt="6px">{row[1] || <>&mdash;</>}</Box>
          </Box>
        ))}

      </VStack>
    </>
  )

}

export default DataDisplay