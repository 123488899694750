export const experts = [
  {
    name: 'Ryan Snyder',
    size: 1,
    location: 'London, UK',
    lat: 51.507222,
    lon: -0.1275,
    industries: ['Food', 'Manufacturing'],
    countryFocus: ['UK', 'US'],
    website: 'https://ryansnyder.me',
    linkedIn: 'https://www.linkedin.com/in/ryansnyder/',
    description: 'Hi, I\'m Ryan. I can help you onboard onto Expect, and to achieve 100% data completeness, a high-level of emission accuracy, and auditable emissions.',
    specialties: 'Carbon Accounting, Carbon Reduction'
  },
  {
    name: 'Nicola Smith',
    size: 1,
    location: 'Edinburgh, UK',
    lat: 55.9533,
    lon: -3.1883,
    industries: ['Manufacturing', 'Retail'],
    countryFocus: ['UK'],
    website: '',
    linkedIn: '',
    specialties: 'Carbon Accounting, Carbon Reduction',
    description: 'Nicola Smith is an independent consultant who works with SMEs to quickly determine their carbon emissions across their organisation.',
  },
  {
    name: 'Energy Saving Trust',
    size: 250,
    location: 'London, UK',
    lat: 51.507222,
    lon: -0.1275,
    industries: ['Energy', 'Government', 'Manufacturing', 'Oil and Gas', 'Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['UK', 'EU'],
    website: 'http://www.energysavingtrust.org.uk/',
    linkedIn: 'https://www.linkedin.com/company/energy-saving-trust',
    specialties: ['Carbon Accounting', 'Carbon Reduction', 'Energy Optimisation', 'Carbon Offsetting'],
    description: 'Energy Saving Trust is an independent organisation dedicated to promoting energy efficiency, low carbon transport and sustainable energy use. We aim to address the climate emergency and deliver the wider benefits of clean energy as we transition to net zero. \nWe empower householders to make better choices, deliver transformative programmes for governments and support businesses with strategy, research and assurance – enabling everyone to play their part in building a sustainable future.',
  },
  {
    name: 'The Carbon Trust',
    size: 250,
    location: 'London, UK',
    lat: 51.507222,
    lon: -0.1275,
    industries: ['Agriculture', 'Energy', 'Government', 'Manufacturing', 'Oil and Gas', 'Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['UK', 'EU'],
    website: 'http://www.carbontrust.com/',
    linkedIn: 'https://www.linkedin.com/company/the-carbon-trust/',
    specialties: ['Carbon Accounting', 'Carbon Reduction', 'Energy Optimisation', 'Carbon Offsetting'],
    description: 'We draw on the experience of over 300 experts internationally, accelerating progress and providing solutions to this existential crisis. We have supported over 3,000 organisations in 50 countries with their climate action planning, collaborating with 150+ partners in setting science-based targets, and supporting cities across 5 continents on the journey to Net Zero. ',
  },
  {
    name: 'Carbon Footprint Ltd',
    size: '11-50',
    lat: 51.2665,
    lon: -1.0924,
    location: 'Basingstoke, UK',
    industries: ['Energy', 'Government', 'Manufacturing', 'Oil and Gas', 'Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['UK'],
    website: 'http://www.carbonfootprint.com/',
    linkedIn: 'https://www.linkedin.com/company/carbon-footprint-ltd/',
    specialties: ['Carbon Accounting'],
    description: 'We help organisations meet their business objectives by enabling them to reduce carbon emissions, energy, and costs whilst developing robust environmental credentials.We specialise in carbon emission assessments, LifeCycle Analysis, environmental strategy/planing, carbon/energy reduction, carbon offsetting (we are members of the Government originated Quality Assurance Scheme) as well as commercialisation/marketing of credentials. We also provide leading carbon management software solutions.',
  },
  {
    name: 'Carbon Masters',
    size: '11-50',
    location: 'Bangalore, Karnataka',
    lat: 12.9716,
    lon: 77.5946,
    industries: ['Agriculture', 'Energy', 'Government', 'Manufacturing', 'Oil and Gas', 'Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['UK', 'India'],
    website: 'https://carbonlites.com/',
    specialties: ['Carbon Accounting', 'Carbon Reduction'],
    linkedIn: 'https://www.linkedin.com/company/carbon-masters/about/',
    description: 'Carbon Masters is a climate tech company with offices in the UK and India. We are on a mission to address the climate emergency by replacing fossil fuels consumption with innovative, clean energy, low carbon solutions & products.',
  },
  {
    name: 'Tercero Solutions',
    size: 1,
    location: 'Oakland, California, USA',
    lat: 37.8044,
    lon: -122.2712,
    industries: ['Non-profit', 'Technology', 'Government'],
    countryFocus: ['USA'],
    website: 'https://www.tercerosolutions.com/',
    linkedIn: 'https://www.linkedin.com/company/tercero-solutions/',
    specialties: ['Carbon Accounting'],
    description: '"I am humbled to share that I have launched Tercero Solutions, a consultancy that proudly brings my over 20 years of experience to mission driven organizations looking to optimize their operations and technology.\nTercero Solutions is a consultancy that activates right size solutions for optimal impact. We support non-profit and for-profit organizations with specialization in small to medium sized organizations. We love to ideate and execute in high growth environments."',
  },
  {
    name: 'ClimeTrek',
    size: '11-50',
    location: 'London, UK',
    lat: 51.4339,
    lon: 0.4653,
    industries: ['Agricultural, Energy, Forestry'],
    countryFocus: ['UK, EU, Turkey, Kenya, Nigeria, India, Canada, Philippines'],
    website: 'https://climetrek.com/',
    linkedIn: 'https://www.linkedin.com/company/climetrek/',
    specialties: ['Carbon Offsetting', 'Carbon Trading'],
    description: '"Climetrek is a leading climate change and sustainability consultancy headquartered in London with offices in Turkey, Kenya, Nigeria, India, Canada and the Philippines.\nWhether it is carbon footprinting or life cycle assessment (LCA), Climetrek provides a range of allied sustainability services to support innovative climate-positive business strategies."',
  },
  {
    name: 'Frank McMillen',
    size: 1,
    location: 'Bristol, UK',
    lat: 51.4545,
    lon: -2.5879,
    industries: ['Agriculture', 'Manufacturing', 'Retail' ],
    countryFocus: ['UK'],
    website: '',
    linkedIn: '',
    specialties: ['Carbon Accounting'],
    description: 'I have extensive experience in corporate client facing roles and managing client portfolios, with over seven years’ experience within the carbon consulting industry.\nI specialise in supporting organisations on their journey to Net Zero through mandatory carbon compliance legislation, best practise initiatives and sustainability and carbon management strategy.',
  },
  {
    name: 'Varsha Patel',
    size: 1,
    location: 'Baroda, IN',
    lat: 22.3072,
    lon: 73.1812,
    industries: ['Energy', 'Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['India', 'Middle East'],
    website: 'https://vpatel.com',
    linkedIn: '',
    specialties: ['Carbon Accounting'],
    description: 'I have 2 years of experience in project management, relationship-building, communications and policy analysis of SDGs in the international development sector and disaster risk reduction. I have over 6 years of cross-cultural relationship-building and policy engagement + innovation in the climate and Sustainability sector. ',
  },
  {
    name: 'Grace Wong',
    size: 1,
    location: 'New York, USA',
    lat: 40.7128,
    lon: -74.0060,
    industries: ['Retail', 'Telecommunications', 'Transportation'],
    countryFocus: ['USA', 'Cananda'],
    website: '',
    linkedIn: '',
    specialties: ['Carbon Accounting', 'Carbon Reduction'],
    description: 'I have a varied background, having held roles across carbon markets, energy consulting and engineering. Most recently, I obtained a certificate in Sustainable Finance from Dartmouth. During my Economics MSc, I specialised in financial risk management, economics of financial institutions, and econometrics. ',
  },
  {
    name: 'Vince Vega',
    size: 1,
    location: 'Amsterdam, NL',
    lat: 52.3676,
    lon: 4.9041,
    industries: ['Energy', 'Oil and Gas'],
    countryFocus: ['EU'],
    website: 'https://www.vincevega.com',
    linkedIn: '',
    specialties: ['Carbon Sequestration', 'Carbon Trading'],
    description: 'I am currently supporting and testing resultant biochar and sequestering high volumes of carbon dioxide. An exciting initiative I am developing is a blockchain platform to sell and exchange carbon removals as NFTs, offering the opportunity to purchase carbon credits.',
  },
]