import { Select } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'

const YearSelect = (props) => {

  const initiativeYear = props.initiative.carbon_impact_date ?
    new Date(props.initiative.carbon_impact_date.replace(/-/g, '/')).getFullYear() // replace - with /, safari cannot read dates with -
      :
    ''
  const [value, setValue] = useState(initiativeYear)

  const componentHasMounted = useRef(false)
  useEffect(() => {
    if (componentHasMounted.current) {
      if (initiativeYear != value) {
        props.initiativeYearChange(initiativeYear, value, props.initiative, false)
        setValue(String(initiativeYear))
      }
    } else {
      componentHasMounted.current = true
    }
  }, [initiativeYear])

  const selectChange = (event) => {
    props.initiativeYearChange(event.target.value, value, props.initiative)
    setValue(event.target.value)
  }

  const getOptions = () => {
    const options = [
      <option key={0} value={''}> &mdash; </option>
    ]

    for (let i = props.baselineYear; i <= props.finalYear; i++) {
      options.push(<option key={i}> {i} </option>)
    }

    return options
  }

  return props.initiativeYearChange ?
    <Select
      textAlign={props.variant ? "center" : "left"}
      variant={props.variant || 'outline'}
      value={String(value)}
      onChange={selectChange}
      onClick={(e) => e.stopPropagation()}
    >
      {getOptions()}
    </Select>
      :
    <>{initiativeYear || <>&mdash;</>}</>
}

export default YearSelect