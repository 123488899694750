import { useContext, useRef, useState } from 'react'
import { Box, Flex, Select, Spacer, Spinner } from '@chakra-ui/react'
import RoadmapChart from './Charts/RoadmapChart'
import InitiativesContainer from './InitiativesContainer'
import RoadmapKPIs from './RoadmapKPIs'
import YearRangeSelect from './YearRangeSelect'
import { applyInitiative } from '../../helpers/applyInitiative'
import Card from '../General/Card'
import { useNavigate, useParams } from 'react-router-dom'
import { useAssets, usePopulatedYears, useRoadmap, useSBTis, useTargets } from '../../services/APIHooks'
import React from 'react'
import NavigationBreadcrumbs from '../Navigation/NavigationBreadcrumbs'
import { editInitiative } from '../../services/APICalls'
import { objectToArray } from '../../helpers/objectToArray'
import DataNotAvailable from '../Blank/DataNotAvailable'

const Roadmap = (props) => {

  const params = useParams()
  const navigate = useNavigate()
  const auth = useContext(props.context)
  const chartRef = useRef(null)

  const location = props.isLocationView ? params.locationId || -1 : null

  const { roadmapData } = useRoadmap(auth.token, auth.orgId, location)
  const { SBTIData } = useSBTis(auth.token, auth.orgId)
  const { targetData, combinedTarget, loaded } = useTargets(auth.token, auth.orgId)
  const targetsReady = loaded

  const populatedYears = usePopulatedYears(auth.token, auth.orgId)
  const { assetData, ready } = useAssets(auth.token, auth.orgId, populatedYears[0])
  const locations = assetData
  const locationsReady = ready

  let baselineYear = 2020
  if (roadmapData.combined.length)
    baselineYear = new Date((roadmapData.combined)[0].date).getFullYear()

  // Location logic
  let locationSelect
  if (props.isLocationView) {
    const locationChange = (event) => {
      navigate('/roadmap/locations/' + event.target.value)
    }

    locationSelect =
      <Box paddingBottom={"2em"}>
        {
          locationsReady ?
            <Select value={location || undefined} variant="filled" onChange={locationChange} minW="300px">
              {locations.length ?
                locations.map((location, index) => (
                  <option key={index} value={location.location_id}> {location.location_name} </option>
                )) : <></>
              }
            </Select>
          : <Spinner/>
        }
      </Box>

  }

  // Target logic
  const targetChange = (event) => {
    navigate('/roadmap/targets/' + event.target.value)
  }
  let target
  let targetSelect = <></>

  let allTargets = [...(targetData.sort((targetA, targetB) => targetA.year - targetB.year)), ...SBTIData]
  if (props.isTargetView) {
    if (SBTIData[0] && targetsReady) {
      target = allTargets.find(target => target.target_id.toString() === params.targetId) || allTargets[0]
    }

    targetSelect =
      <Box paddingBottom={"2em"}>
        <Select value={target?.target_id} variant="filled" onChange={targetChange}>
          {allTargets.map((target, index) => (
            <option key={index} value={target.target_id}> {target.name} </option>
          ))}
        </Select>
      </Box>
  }

  const chartScope = props.scope || target?.scope

  const onYearChange = (event) => {
    const value = JSON.parse('[' + event.target.value + ']')
    // @ts-ignore
    chartRef?.current?.chart.xAxis[0].setExtremes(new Date(value[0], 0).getTime(), new Date(value[1], 0).getTime(), true)
  }

  let finalYear = null
  const aRoadmapLine = objectToArray(roadmapData)[0]
  if (aRoadmapLine.length) {
    if (targetData.map(target => target.year > 2030).includes(true))
      finalYear = new Date(aRoadmapLine[aRoadmapLine.length - 1].date).getFullYear() + 1
    else {
      finalYear = 2030
    }
  }

  const [KPIData, setKPIData] = useState(
    {
      year: null,
      trajectory: null,
      target: null,
      deltaCarbon: null,
      deltaCost: null
    }
  )

  const initiativeYearChange = (newYear, oldYear = null, initiative, callAPI = true) => {
    let carbonChange
    switch (chartScope) {
      case 1:
        carbonChange = -Number(initiative.carbon_annual_scope_1)
        break
      case 2:
        carbonChange = -Number(initiative.carbon_annual_scope_2)
        break
      case 3:
        carbonChange = -Number(initiative.carbon_annual_scope_3)
        break
      default:
        carbonChange = -Number(initiative.carbon_annual_total)
    }

    if (newYear)
      initiative.carbon_impact_date = newYear + '-01-01'
    else
      initiative.carbon_impact_date = null

    if (callAPI)
      editInitiative(auth.token, auth.orgId, initiative) // call API

    // @ts-ignore
    applyInitiative(chartRef.current.chart, newYear, carbonChange, oldYear, setKPIData) // apply to chart
  }

  if (props.isLocationView && locationsReady && !locations.length) {
    return <DataNotAvailable description="locations roadmap"/>
  }

  return (
    <>
      <Flex>
        <NavigationBreadcrumbs />

        <Spacer />

        {
          props.isTargetView || props.isLocationView ?
            <></>
            :
            <Box width={"15em"} hidden={props.isTargetView}>
              <YearRangeSelect
                baselineYear={baselineYear}
                finalYear={finalYear}
                onChange={onYearChange}
              />
            </Box>
        }

        { props.isTargetView ? targetSelect : <></> }

        { props.isLocationView ? locationSelect : <></> }
      </Flex>

      <Box paddingBottom={"2em"}>
        <RoadmapKPIs KPIData={KPIData} currency={auth.orgData.carbon_price_currency} noTarget={props.isLocationView}/>
      </Box>

      <Box paddingBottom={"2em"} >
        <Card key={props.isTargetView}>
          {/* <Flex>
            <Spacer />
            <Box>
              <Toggle onChange={setCostMode} prompt={"Cost view"} />
            </Box>
          </Flex> */}

          <RoadmapChart
            key={chartScope}
            chartRef={chartRef}
            carbonPrice={auth.orgData.carbon_price}
            combinedTargetData={combinedTarget}
            roadmapLineData={roadmapData}
            scope={chartScope}
            targetData={(props.isTargetView && target) ? [target] : targetData}
            isTargetView={props.isTargetView}
            updateKPIs={setKPIData}
            isLocationView={props.isLocationView}
            locationsReady={locationsReady}

            baselineYear={baselineYear}
            finalYear={finalYear}
          />
        </Card>
      </Box>

      <InitiativesContainer
        baselineYear={baselineYear}
        context={props.context}
        finalYear={finalYear}
        initiativeYearChange={initiativeYearChange}
        scope={chartScope}
      />
    </>
  )
}

export default Roadmap