import { Button, Center, Flex, Image, Spacer } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import OrgLogo from './OrgLogo'

const User = (props) => {

  const displayName = props.name
  const splitName = displayName.split(' ')

  const AvatarImage = () => {
    const getFile = (user) => {
      if (displayName === 'Amit Jay Shah')
        return 'amit.png'
      if (displayName === 'Ryan Snyder')
        return 'ryan.jpg'
      if (user === 'lexi.laxenby@xct.ai')
        return 'lexi.jpg'
      if (user === 'bill.roberts@xct.ai')
        return 'bill.jpg'
      return null
    }
    const file = getFile(props.user)

    if (file)
      return <Image
        height={"35px"} w="35px"
        borderRadius={"35px"}
        src={'/images/' + file}
        mr={"10px"}
      />
    return <Center
      color={"onSurface.primary.high"}
      bg="ui.100"
      minHeight={"35px"} minWidth={"35px"}
      borderRadius={"35px"}
    >
      {props.user ? (splitName[0][0] + (splitName[1] ? splitName[1][0].toUpperCase() : '')) : <Image src='/icons/person.svg' />}
    </Center>
  }

  return (
    <Link to="/profile">
      <Button
        height="64px"
        bg="ui.100"
        _hover={{bg: 'ui.200'}}
        border='1px solid'
        borderColor={'ui.300'}
        fontSize="14px"
        // variant={'outline'}
        minWidth='238px'
      >
        <Flex alignItems={"center"} width="100%" gap='16px'>
          <Spacer />
          <OrgLogo orgId={props.orgId} maxW='100px' maxH='54px' padding={0}/>
          <Spacer />
          <AvatarImage />
          <Spacer />
        </Flex>
      </Button>
    </Link>
  )
}

export default User