import { Flex, Text } from '@chakra-ui/react'
import React from 'react'
import theme from '../../../theme'
import { compassScoreColourScheme } from '../../Roadmaps/Initiatives/compassScoreColour'

const CarbonMaturity = (props) => {

  const value = props.value || <>&mdash;</>

  const charCode = value.toLowerCase().charCodeAt(0) - 96
  let colourScheme = theme.colors.ui['500']

  if (charCode === 1)
    colourScheme = compassScoreColourScheme.green
  else if (1 < charCode && charCode < 4)
    colourScheme = compassScoreColourScheme.yellow
  else if (charCode === 4)
    colourScheme = compassScoreColourScheme.red


  return (
    <Flex
      bg={colourScheme.bg}

      justifyContent={"center"}
      alignItems={"center"}
      w={"24px"}
      h={"24px"}
      borderRadius={"6px"}
    >
      <Text
        color={colourScheme.text}

        fontFamily={"SF Pro Display"}
        fontWeight={500}
        fontSize={"12px"}
        line-height={"20px"}
      >
        { value }
      </Text>
    </Flex>
  )
}

export default CarbonMaturity