import { Image } from '@chakra-ui/react'
import React from 'react'

const ConnectionIcon = (props) => {

  const connected = props.connected

  if (connected)
    return <Image h="24px" src={"/icons/connected.svg"}></Image>
  return (
    <Image h={"24px"} src={"/icons/notConnected.svg"}></Image>
  )
}

export default ConnectionIcon