import { Box, Image } from '@chakra-ui/react'
import React from 'react'

const OrgLogo = (props) => {

  const getOrgLogo = (orgId) => {
    if (props.expect)
      return '/wideLogo.svg'
    if (orgId === 293 || orgId === 30)
      return '/images/orgLogos/sonoro.png'
    if (orgId === 2)
      return '/images/brands/socar-turkiye.png'
    if (orgId === 1)
      return '/images/orgLogos/energy-zero.png'
    return '/wideLogo.svg'
  }

  return (
    <Box padding={props.padding ?? '16px 0 32px 8px'}>
      <Image
        maxWidth={props.maxW ||'180px'}
        w={props.w || ''}
        maxHeight={props.h || props.maxH || '100px'}
        fit='contain'
        src={getOrgLogo(Number(props.orgId))}
      />
    </Box>
  )
}

export default OrgLogo
