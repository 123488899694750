export const prepareNewInitiative = (auth, newData) => {
  newData['org_id'] = Number(auth.orgId)
  newData['producer_id'] = Number(auth.orgId)
  newData['source_org_id'] = Number(auth.orgId)

  newData['carbon_annual_total'] = getCarbonAnnualTotal({Initiative: newData})
  newData['cost_annual_carbon'] = (newData['carbon_annual_total'] / 1000) * auth.orgData.carbon_price
  newData['cost_capex'] = newData['cost_capex'] ?? 0

  return {
    Initiative: newData,
    slug: auth.orgData.slug,
  }
}

export const getCarbonAnnualTotal = (initiative) => {
  return [1, 2, 3].map(n => initiative.Initiative['carbon_annual_scope_' + n] || 0).reduce(
    (prev, current) => Number(prev) + Number(current)
  )
}
