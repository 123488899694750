import { Flex, IconButton, Image, Spacer } from '@chakra-ui/react'
import React, { useRef } from 'react'
import { useInsights } from '../../../services/APIHooks'
import WhatsNewCard from './WhatsNewCard'

const ScrollableInsights = (props) => {

  const auth = props.auth

  const { insightsData, ready } = useInsights(auth.token, auth.orgId, props.scope)

  const ref = React.createRef()

  const timer = useRef(setTimeout(()=>{}))

  const scroll = (pixels) => {
    ref.current.scrollLeft += pixels
    timer.current = setTimeout(() => scroll(pixels > 0 ? 50 : -50), 25)
  }

  const stopScroll = () => {
    clearTimeout(timer.current);
  }

  if (!insightsData.length && ready)
    return <></>

  return (
    <Flex direction={'column'} mb='1em' gap='1em'>
      <Flex>
        <Spacer />
        <IconButton onMouseDown={() => scroll(-30)} onMouseUp={() => stopScroll()}
          mr='12px' aria-label='scroll left' icon={<Image src='/icons/chevronLeft.svg' />} />
        <IconButton onMouseDown={() => scroll(30)} onMouseUp={() => stopScroll()}
          aria-label='scroll right' icon={<Image src='/icons/chevronRight.svg' />} />
      </Flex>

      <Flex width={"100%"} overflowX="auto" gap={"24px"} pb="20px" ref={ref}>
        {insightsData.map((info, index) =>
          <WhatsNewCard info={info} key={index} />
        )}
      </Flex>
    </Flex>
  )
}

export default ScrollableInsights