export const getKPIObject = (chart, carbonPrice, endDate) => {

  let roadmapSeries = chart.series.find(series => series.name.toLowerCase().includes('roadmap trajectory'))
  let newData = roadmapSeries.options.data

  if (!newData.length) return {
    year: null,
    trajectory: null,
    target: null,
    deltaCarbon: null,
    deltaCost: null,
  }

  // Final value of roadmap in current zoom
  let roadmapFinal = endDate ?
    newData.filter(point => point.x <= endDate).reverse()[0]
    :
    newData.filter(point => point.x <= chart.options.xAxis[0].max).reverse()[0]

  // Find most suitable target to compare with
  let allTargets = chart.series.filter(series => series.name.includes('Reduce') || series.name.includes('target'))
    .map(target => target.options.data)
  let targetSeriesData
  if (endDate && allTargets.length) {
    let targetsForYear = allTargets.filter(series => series[series.length - 1]?.x >= endDate)
    targetSeriesData = targetsForYear.sort((a, b) => a.length - b.length)[0] // Shortest target applicable to year
  }
  if (!targetSeriesData) {
    targetSeriesData = allTargets.sort((a, b) => b.length - a.length)[0] // Longest of all targets
  }

  let targetKgs
  let targetYear
  let roadmapFinalAtTargetEnd
  let targetRoadmapDelta
  let missedCostSavings

  if (targetSeriesData?.length) {
    // Find values of latter three KPIs
    let closestTargetPoint = null
    if (endDate) {
      closestTargetPoint = targetSeriesData.reduce((prevPoint, currentPoint) => {
        return (Math.abs(currentPoint.x.getTime() - endDate.getTime()) < Math.abs(prevPoint.x.getTime() - endDate.getTime())) ?
          currentPoint : prevPoint
      })
    }
    if (!closestTargetPoint) {
      closestTargetPoint = targetSeriesData[targetSeriesData.length - 1]
    }
    let roadmapPointAtTargetEnd = newData.reduce((prevPoint, currentPoint) => {
      return (Math.abs(currentPoint.x.getTime() - closestTargetPoint.x.getTime()) < Math.abs(prevPoint.x.getTime() - closestTargetPoint.x.getTime())) ?
        currentPoint : prevPoint
    })

    targetKgs = closestTargetPoint.y
    targetYear = new Date(closestTargetPoint.x).getFullYear()
    roadmapFinalAtTargetEnd = roadmapPointAtTargetEnd
    targetRoadmapDelta = roadmapFinalAtTargetEnd.y - targetKgs
    missedCostSavings = (targetRoadmapDelta / 1000) * carbonPrice
  }

  let final
  if (chart.options.xAxis[0].max && targetSeriesData?.length) {
    final = roadmapFinalAtTargetEnd
  } else {
    final = roadmapFinal
  }

  let trajectory = final?.y

  return {
    year: final?.x.getFullYear() + 1,
    trajectory: trajectory,
    target: targetKgs ?? null,
    targetYear: (targetKgs != null) ? (roadmapFinalAtTargetEnd.x.getFullYear() || targetYear) + 1 : null,
    deltaCarbon: targetRoadmapDelta,
    deltaCost: missedCostSavings,
  }
}