import { Flex, Spacer, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { displayCarbon, displayCost, round } from '../../../helpers/formatValues'
import Card from '../../General/Card'
import LinkArrowButton from '../../General/LinkArrowButton'
import TrendIcon from '../../General/TrendIcon'

const MiniInitiativeCard = (props) => {

  const initiative = props.initiative || {}

  return (
    <Card padding={"24px"} style={{ textAlign: "left" }}>
      <Flex direction={"column"}>
        <Text as={"span"}
          fontSize={"16px"}
          lineHeight={"20px"}

          width={"100%"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
        >
          {initiative.initiative_name || <>&mdash;</>}
        </Text>

        <Spacer />

        <Flex
          marginTop={"12px"}
          w={"100%"}
          fontSize={"28px"}
          lineHeight={"40px"}
        >
          <Tooltip label="Reduced emissions">
            <Flex gridGap={"8px"} mr={"16px"}>
              {displayCarbon(initiative.carbon_annual_total) || <>&mdash;</>}
              <TrendIcon value={-round(initiative.carbon_annual_total)} height={"24px"} />
            </Flex>
          </Tooltip>

          <Tooltip label="Benefit">
            <Flex gridGap={"8px"} mr={"16px"}>
              {displayCost(initiative.cost_annual_carbon, undefined, true, undefined, props.currency) || <>&mdash;</>}
              <TrendIcon invert value={-round(initiative.cost_annual_carbon, 2)} />
            </Flex>
          </Tooltip>

          <Spacer />

          <LinkArrowButton
            url={props.url}
            ariaLabel={'View initiative in roadmap'}
          />
        </Flex>
      </Flex>

    </Card>
  )
}

export default MiniInitiativeCard