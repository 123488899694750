import { Box } from '@chakra-ui/react'
import React from 'react'

const Card = (props) => {
  return (
    <Box
    mt={props.mt || props.marginTop}
    style={props.style}

    bg={props.bg || (props.black ? "grayscale.50" : "ui.100")}
    padding={props.padding ?? "20px"}
    textAlign={props.textAlign || 'left'}

    borderColor={"ui.300"}
    borderRadius={"6px"}
    borderWidth={"1px"}

    w={"100%"}
    h={props.h || "100%"}

    className={props.className}

    onClick={props.onClick}

    >
      {props.children}
    </Box>
  )
}

export default Card