import { Flex } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { useOutletContext } from 'react-router-dom'
import { displayCarbon } from '../../helpers/formatValues'
import { useSuppliers } from '../../services/APIHooks'
import theme from '../../theme'
import BarChart from '../Emissions/Charts/BarChart'
import DataTable from '../General/DataTable'

const AllSuppliers = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())

  const suppliers = useSuppliers(auth.token, auth.orgId, outletContext.year)

  const sortedData = suppliers.filter(source => source.emissions_total)
    .sort((a, b) => {
      return b.emissions_total - a.emissions_total
    })

  return (
    <Flex direction='column' gap='2em'>
      <BarChart
        colours={[theme.colors.ice['500']]}
        data={sortedData.map(source => [source.emissions_source_name || source.name, source.emissions_total])}
      />

      <DataTable
        headings={['Category', 'CO2e', 'Cost']}
        rows={sortedData.map((source) => [
          source.emissions_source_name || source.name,
          displayCarbon(source.emissions_total),
          source.emissions_total_cost_display
        ])}
        links={sortedData.map(supplier => "../suppliers/" + supplier.supplier_id.toString())}
      />
    </Flex>
  )
}

export default AllSuppliers