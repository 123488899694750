import { Table, Thead, Th, Tr, Td, Tbody, Text, Tooltip, Flex } from '@chakra-ui/react'
import React from 'react'
import { displayCarbon, displayCost } from '../../../helpers/formatValues'
import YearSelect from '../YearSelect'
import CompassScore from './CompassScore'

const InitiativeTable = (props) => {

  const initiatives = props.initiatives.map(initiative => initiative.Initiative)

  return (
    <Table>
      <Thead>
        <Tr>
          <Th> Compass score </Th>
          <Th> Name </Th>
          <Th> Carbon impact </Th>
          <Th> Benefit </Th>
          <Th> Capex </Th>
          <Th> Scheduled </Th>
        </Tr>
      </Thead>

      <Tbody fontSize={14}>
        {
          initiatives.map(initiative => (
            <Tr key={initiative.initiative_id} onClick={() => props.editInitiative(initiative)} cursor='pointer'>
              <Td>
                <CompassScore score={initiative.compass_score} />
              </Td>
              <Td>
                <Tooltip label={initiative.initiative_name}>
                  <Text noOfLines={1} maxWidth={'400px'}>
                    {initiative.initiative_name}
                  </Text>
                </Tooltip>
              </Td>
              <Td>
                {displayCarbon(initiative.carbon_annual_total)}
              </Td>
              <Td>
                {displayCost(initiative.cost_annual_carbon || null) || <>&mdash;</>}
              </Td>
              <Td>
                {displayCost(initiative.cost_capex)}
              </Td>
              <Td>
                <Flex alignItems={"center"}>
                  <YearSelect
                    initiative={initiative}
                    variant={'unstyled'}
                    baselineYear={props.baselineYear}
                    finalYear={props.finalYear}
                    initiativeYearChange={props.initiativeYearChange}
                  />
                </Flex>
              </Td>
            </Tr>
          ))
        }
      </Tbody>
    </Table>
  )
}

export default InitiativeTable