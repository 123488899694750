
// TODO All methods should be documented
// TODO safeLog should only be called in staging, not in production
// TODO safeLog statements should always include the function name for quicker cause identification

import { safeLog } from '../helpers/logging'

// TODO There should be a global method for determing production / staging / development environments
const getAPIUrl = () => {
  if (window.location.host === 'localhost:3000' || window.location.host === 'x2-staging-vtd5ekus7a-oa.a.run.app' || window.location.host === 'x2-staging.expectai.com' || window.location.host === 'una-staging.expectai.com') {
    return process.env.REACT_APP_API_URL_STAGING
  }
  if (window.location.host === 'x2-production-vtd5ekus7a-oa.a.run.app' || window.location.host === 'x2.expectai.com' || window.location.host === 'una.expectai.com') {
    return process.env.REACT_APP_API_URL_PRODUCTION
  }
  safeLog('Error: no api url found...')
  return ''
}

export const authenticate = async (username, password) => {
  const url = getAPIUrl() + '/token'
  const formdata = new FormData()
  formdata.append('username', encodeURI(username))
  formdata.append('password', password)

  try {
    const response = await fetch(url,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: formdata
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getInitiatives = async (token, orgId, scope) => {
  let url = getAPIUrl() + '/initiatives/'
  if (scope)
    url += 'scopes/' + scope
  url += '?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const editInitiative = async (token, orgId, newInitiativeData) => {
  const url = getAPIUrl() + '/initiatives/?id=' + newInitiativeData.initiative_id + '&org_id=' + orgId

  safeLog('json: ', newInitiativeData)

  try {
    const response = await fetch(url,
      {
        method: 'PATCH',
        headers: {
          authorization: token,
          'Content-type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify(newInitiativeData)
      })
    return checkResponse(response)
  }
  catch (error) {
    return null
  }
}

export const getSBTIs = async (token, orgId) => {
  try {
    const response = await fetch(getAPIUrl() + '/roadmap/targets/sbt/?org_id=' + orgId,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getRoadmap = async (token, orgId, scope, locationId) => {
  let url = getAPIUrl() + '/roadmaps/'
  if (scope)
    url += 'scopes/' + scope + '/'
  else if (locationId) {
    url += 'locations/' + locationId + '/'
  }
  url += '?org_id=' + orgId
  safeLog('calling api for roadmap...', url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getTargets = async (token, orgId) => {

  let targetsWithYears = []

  return getTargetIDs(token, orgId).then(targets => {
    targetsWithYears = targets
    return targets.map(target => target.target_id)
  })
    .then(ids => {
      const requests = ids.map(id => getTargetLine(token, orgId, id))

      return Promise.all(requests).then(responses => {
        targetsWithYears.forEach((target, index) => { target.years = responses[index] })
        targetsWithYears.forEach(target => target.name = 'Reduce scope ' + target.scope +
          ' ' + target.reduction_percentage + '% by ' + target.year)
        return targetsWithYears
      })
    })
}

const getTargetIDs = async (token, orgId) => {
  let url = getAPIUrl() + '/targets/active?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const addEditDeleteTarget = async (token, orgId, targetId, target) => {
  let method = "DELETE"
  if (target) {
    if (targetId)
      method = "PATCH"
    else
      method = "POST"
  }

  let url = getAPIUrl() + '/targets/'
  if (method !== "POST") {
    url += "?target_id=" + targetId
    url += "&org_id=" + orgId
  }

  const init = {
    method: method,
    headers: {
      authorization: token,
      "Content-type": 'application/json; charset=UTF-8',
    }
  }
  if (target) {
    init.body = JSON.stringify(target)
  }

  try {
    const response = await fetch(url, init)
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

const getTargetLine = async (token, orgId, id) => {

  let url = getAPIUrl() + '/roadmaps/targets/'
  url += id
  url += '/?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getCombinedTargetLine = async (token, orgId) => {
  safeLog('fetching combined target')
  const url = getAPIUrl() + '/roadmaps/targets/combined/?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

/**
 * API call that will fetch the emission data by estimating emissions for each compound (CO2, CH4, NOX, etc)
 * for scope for an organisation in a single year.
 *
 * Example Call — https://api.expectai.com/emissions/scopes/{scope}/compounds/totals/years/{year}
 *
 * @param  token string     The auth token
 * @param  orgId int        The organisation ID
 * @param  scope int        The scope (1, 2, 3)
 * @param  year int        The year
 * @param  compound string     A specific compound (co2, ch4) - optional
 * @return string     A json blob containing the returned results
 */
export const getEmissionsCompoundsByScope = async (token, orgId, scope, year, compound = null) => {

  if (orgId && scope && year) {
    const url = getAPIUrl() + '/emissions/scopes/' + scope + '/compounds/totals/years/' + year + '?org_id=' + orgId

    safeLog('getEmissionsCompounds url: ', url)

    try {
      const response = await fetch(url,
        {
          method: 'GET',
          headers: {
            authorization: token
          }
        })
      return response.json()
    }
    catch (error) {
      return []
    }
  }
}

/**
 * API call that will fetch insights for an organisation.
 *
 * Example Call — https://api.expectai.com/insights
 * Example Call — https://api.expectai.com/insights/scopes/{scope}
 * Example Call — https://api.expectai.com/insights/scopes/{scope}/categories/{primary_category_id}
 *
 * @param  token     The auth token
 * @param  orgId     The organisation ID
 * @param  scope     The scope (1, 2, 3) - optional
 * @param  primary_category_id     The primary_category_id - optional
 * @return string    A json blob containing the returned results
 */
export const getInsights = async (token, orgId, scope = null, primary_category_id = null) => {

  if (orgId) {
    var url = getAPIUrl() + '/insights'
    if (scope) url = url + '/scopes/' + scope
    if (primary_category_id) url = url + '/categories/' + primary_category_id
    url = url + '?org_id=' + orgId

    safeLog('API Call — getInsights() url: ', url)

    try {
      const response = await fetch(url,
        {
          method: 'GET',
          headers: {
            authorization: token
          }
        })
      return response.json()
    }
    catch (error) {
      return []
    }
  }
}

export const getMonthlyEmissions = async (token, orgId, scope, year, location, system, unit, equipment, period) => {
  if (scope === 3) {
    return getScope3Emissions(token, orgId, null, year)
  }

  safeLog('calling api for monthly emissions...')
  let url = getAPIUrl() + '/emissions/'
  if (location)
    url += 'locations/' + location + '/'
  if (system)
    url += 'systems/' + system + '/'
  if (unit)
    url += 'units/' + unit + '/'
  if (equipment)
    url += 'equipments/' + equipment + '/'
  if (scope)
    url += 'scopes/' + scope + '/'
  if (year)
    url += 'years/' + year
  url += '?org_id=' + orgId
  if (period)
    url += '&period=' + period

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

const checkResponse = (response) => {
  const contentType = response.headers.get('content-type')
  if (response.status === 204)
    return 204
  if (contentType?.includes('application/json'))
    return response.json()
}

export const getMonthlySupplierEmissions = async (token, orgId, year) => {
  const url = getAPIUrl() + '/suppliers/emissions/years/' + year + '?org_id=' + orgId

  safeLog('Requesting monthly supplier emissions: ', url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  } catch (error) {
    safeLog('error: ', error)
  }
}

export const getScope3Emissions = async (token, orgId, category_type, year, primary_id, secondary_id, tertiary_id) => {
  safeLog('getScope3Emissions() -- calling api for scope 3 monthly emissions...')
  let url = getAPIUrl() + '/emissions/scopes/3/'
  if (category_type)
    url += 'categories/' + category_type + '/'
  if (primary_id)
    url += '/' + primary_id + '/'
  if (secondary_id)
    url += 'subcategories/' + secondary_id + '/'
  if (tertiary_id)
    url += 'products/' + tertiary_id + '/'
  if (year)
    url += 'years/' + year
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}


export const getScope3Emitters = async (token, orgId, category_type, year, primary_id, secondary_id) => {
  let url = getAPIUrl() + '/emissions/scopes/3/'
  if (category_type)
    url += 'categories/' + category_type + '/'
  if (primary_id)
    url += '/' + primary_id + '/'
  if (secondary_id)
    url += 'subcategories/' + secondary_id + '/'
  if (year)
    url += 'years/' + year
  url += '/emitters?org_id=' + orgId
  safeLog('getScope3Emitters()', url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getScope3CategoryEmissions = async (token, orgId, primary_id, secondaryId, tertiaryId, year) => {

  let url = getAPIUrl() + '/emissions/scopes/3/categories/ghgprotocol/' + primary_id
  if (secondaryId)
    url += '/subcategories/' + secondaryId
  if (tertiaryId)
    url += '/products/' + tertiaryId
  url += '/years/' + year + '?org_id=' + orgId
  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return {}
  }
}

export const getScope3CategoryEmitters = async (token, orgId, primary_id, secondaryId, year) => {
  let url = getAPIUrl() + '/emissions/scopes/3/categories/ghgprotocol/' + primary_id
  if (secondaryId)
    url += '/subcategories/' + secondaryId
  url += '/years/' + year + '/emitters?org_id=' + orgId
  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return {}
  }
}

export const getAssetEmissionBreakdown = async (token, orgId, year, location, system, unit, equipment) => {
  let url = getAPIUrl() + '/emissions/locations'
  if (location)
    url += '/' + location
  if (system)
    url += '/systems/' + system
  if (unit)
    url += '/units/' + unit
  if (equipment)
    url += '/equipments/' + equipment
  if (year)
    url += '/years/' + year
  url += '?org_id=' + orgId

  safeLog('asset url: ', url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getTopEmitters = async (token, orgId, scope, year) => {
  let url = getAPIUrl() + '/emissions/scopes/'
  url += scope
  url += "/emitters/years/" + year
  url += '?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getTopEmissionSources = async (token, orgId, scope, year) => {
  safeLog('getTopEmissionSources() -- calling api for top emission sources...')

  let url = getAPIUrl() + '/emissions/scopes/'
  url += scope
  url += "/sources/years/" + year
  url += '?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}


export const getSourceEmissions = async (token, orgId, scope, year, emission_source) => {
  safeLog('getSourceEmissions() -- calling api for ???')

  let url = getAPIUrl() + '/emissions/scopes/'
  url += scope
  url += "/sources/" + emission_source
  url += "/years/" + year
  url += '?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getSourceBreakdown = async (token, orgId, scope, year, emission_source) => {

  let url = getAPIUrl() + '/emissions/scopes/'
  url += scope
  url += "/sources/" + emission_source
  url += "/years/" + year
  url += '/emitters?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}


export const getSpecificAsset = async (token, orgId, location, system, unit, equipment) => {
  safeLog('calling api for specific asset information...')
  let url = getAPIUrl() + '/locations/' + location
  if (system)
    url += '/systems/' + system
  if (unit)
    url += '/units/' + unit
  if (equipment)
    url += '/equipment/' + equipment
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getAssetBreakdown = async (token, orgId, year, location, system, unit) => {
  safeLog('calling api for sub-asset details...')
  let url = getAPIUrl() + '/emissions/locations'
  if (location)
    url += '/' + location + '/systems'
  if (system)
    url += '/' + system + '/units'
  if (unit)
    url += '/' + unit + '/equipment'
  url += '/years/' + year
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}


export const getAssetTopInitiatives = async (token, orgId, location, system, unit, equipment) => {
  safeLog('calling api for specific asset information...')
  let url = getAPIUrl() + '/locations/' + location
  if (system)
    url += '/systems/' + system
  if (unit)
    url += '/units/' + unit
  if (equipment)
    url += '/equipment/' + equipment
  url += '/initiatives?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getSupplierEmissions = async (token, orgId, supplierId, year) => {
  const url = getAPIUrl() + '/suppliers/emissions/' + supplierId + '/years/' + year + '?org_id=' + orgId
  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return {}
  }
}

/**
 * API call that will fetch the report data that has been customised by an organisation for
 * GHG Protocol, TCFD, and other reports.
 *
 * Example Call — https://api.xct.ai/reports/{report_type}/years/{year}
 *
 * @param  token   The auth token
 * @param  orgId  The organisation ID
 * @param  reportType   The report type (ghg_protocol, tcfd, etc)
 * @param  reportYear  The year of the report
 * @return string   A json blob containing the returned results
 */
export const getReport = async (token, orgId, reportType, reportYear) => {
  safeLog('APICalls — getReport() - including orgId ' + orgId + ' reportType ' + reportType + ' + reportYear ' + reportYear)

  let url = getAPIUrl() + '/reports/'
  if (reportType)
    url += reportType
  if (reportYear)
    url += '/years/' + reportYear
  url += '?org_id=' + orgId
  safeLog("APICalls — API Call URL " + url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getReportTypes = async (token, orgId) => {
  safeLog('calling api for report types...')
  let url = getAPIUrl() + '/reports/types'
  url += '?org_id=' + orgId
  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}


export const getIntensities = async (token, orgId, year, location, system, unit) => {
  safeLog('calling api for intensities ...')
  let url = getAPIUrl() + '/intensities'
  if (location)
    url += '/locations/' + location
  if (system)
    url += '/systems/' + system
  if (unit)
    url += '/units/' + unit
  if (year)
    url += '/years/' + year
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

export const getVendors = async (token, orgId, vendor) => {
  safeLog('calling api for vendor information...')
  let url = getAPIUrl() + '/vendors'
  if (vendor)
    url += "/" + vendor
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getSuppliers = async (token, orgId, year) => {
  safeLog('calling api for supplier information...')
  const url = getAPIUrl() + '/suppliers/years/' + year + '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const getSupplierCategories = async (token, orgId, year, categoryType) => {
  safeLog('calling api for supplier categories')

  const url = getAPIUrl() + '/suppliers/' + categoryType + '/emitters/years/' + year + '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  } catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getOrganisationPartners = async (token, orgId) => {
  safeLog('calling api for organisation partners...')
  let url = getAPIUrl() + '/organisations/partners/'
  url += '?org_id=' + orgId

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return []
  }
}

export const addInitiative = async (token, initiative) => {
  safeLog('calling api to add an initiative...')
  let url = getAPIUrl() + '/initiatives/'

  try {
    const response = await fetch(url,
      {
        method: 'POST',
        headers: {
          authorization: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(initiative)
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const updateInitiative = async (token, orgId, initiative_id, initiative) => {
  let url = getAPIUrl() + '/initiatives/'
  if (initiative_id) {
    url += '?org_id=' + orgId
    url += '&id=' + initiative_id
  }
  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: initiative_id ? 'PATCH' : 'POST',
        headers: {
          authorization: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(initiative)
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

// export const updateRoadmap = async (initiative) => {
//   safeLog('calling api to update the roadmap based on initiative change...')
//   let url = 'https://europe-west6-x-decarbonisation.cloudfunctions.net/expect-roadmap-update-monthly-roadmaps-public-test'

//   try {
//     const response = await fetch(url,
//       {
//         method: 'POST',
//         headers: {
//           'Accept': 'application/json',
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           initiative_id : initiative.initiative_id,
//           updated_initiative_date : initiative.carbon_impact_date,
//           updated_initiative_scope1 : initiative.carbon_annual_scope_1,
//           updated_initiative_scope2 : initiative.carbon_annual_scope_2,
//           updated_initiative_scope3 : initiative.carbon_annual_scope_3,
//           database : 'staging'
//         })
//       })

//     return checkResponse(response)
//   }
//   catch (error) {
//     safeLog('Error: ', error)
//     return []
//   }
// }

export const updateCompassScore = async (token, orgId) => {
  safeLog('calling api to update the roadmap based on initiative change...')
  let url = 'https://europe-west6-x-decarbonisation.cloudfunctions.net/expect-roadmap-update-initiatives-compass-score-public-test'

  const db_details = {
    database: 'staging',
    org_id: orgId
  }

  try {
    const response = await fetch(url,
      {
        method: 'POST',
        headers: {
          authorization: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        // @ts-ignore
        body: db_details
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getUserInfo = async (token, email) => {
  let url = getAPIUrl() + '/users/' + encodeURIComponent(email)

  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })

    return checkResponse(response)
  }
  catch (error) {
    return
  }
}

export const setUserYear = async (token, email, year) => {
  let url = getAPIUrl() + '/users/years'

  safeLog(email, year)

  // const body = new FormData
  // body.append('email', email)
  // body.append('year', year)

  try {
    const response = await fetch(url,
      {
        method: 'PATCH',
        headers: {
          authorization: token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        // body: body,
        body: JSON.stringify({
          "email": email,
          "year": year
        })
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

export const getIdNames = async (token, orgId, page, primary_id, secondary_id, tertiary_id) => {
  let url = getAPIUrl() + '/'
  if (page === 'scope3') {
    url += 'scope3_categories/' + primary_id
    if (secondary_id)
      url += '/' + secondary_id
    if (tertiary_id)
      url += '/' + tertiary_id
  }
  url += '/names'
  url += '?org_id=' + orgId

  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

export const getLocationIdNames = async (token, orgId, locationId, systemId, unitId, equipmentId) => {

  let url = getAPIUrl() + '/locations/' + locationId
  if (systemId)
    url += '/systems/' + systemId
  if (unitId)
    url += '/units/' + unitId
  if (equipmentId)
    url += '/equipment/' + equipmentId
  url += '/names'
  url += '?org_id=' + orgId

  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

/**
 * API call that will fetch a single organisation
 *
 * Example Call — https://api.xct.ai/organisations/{org_id}
 *
 * @param  token   The auth token
 * @param  orgId  The organisation ID
 * @return string   A json blob containing the returned results
 */
export const getOrganisation = async (token, orgId) => {
  safeLog('Running API Call - getOrganisation() - including orgId ' + orgId)

  let url = getAPIUrl() + '/organisations/' + encodeURIComponent(orgId)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }

}

/**
 * API call to get all of the internal carbon prices for a particular industry.
 *
 * Example Call — https://api.xct.ai/insights/icp/industries/{industryId}
 *
 * @param  token      The auth token
 * @param  industryId     The industry ID
 * @return string      A json blob containing the returned results
 */
export const getInternalCarbonPrices = async (token, industryId) => {
  safeLog('Running API Call - getInternalCarbonPrices() - including industryId ' + industryId)

  let url = getAPIUrl() + '/insights/icp/industries/' + encodeURIComponent(industryId)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getIndustryCarbonMaturity = async (token, industryId) => {

  let url = getAPIUrl() + '/industries/' + (industryId || 'all') + '/carbon-maturity'

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        },
      })

    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error: ', error)
    return []
  }
}

export const getIndustryIds = async (token) => {
  const url = getAPIUrl() + '/insights/carbon-maturity/industries'

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    safeLog('Error', error)
    return []
  }
}

export const getPopulatedYears = async (token, orgId) => {
  const url = getAPIUrl() + '/emissions/years/?org_id=' + orgId
  safeLog(url)

  try {
    const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          authorization: token,
        }
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}

export const getMethodologies = async (token, orgId, year, scope) => {
  return genericAPICall(token, '/emissions/scopes/' + scope + '/methodologies/', { orgId, year }, {})
}

export const changeOrganisation = async (token, userEmail, newOrgId) => {
  return genericAPICall(token, '/users/organisation', {}, { email: userEmail, new_org_id: Number(newOrgId) }, 'PATCH')
}

export const uploadEmissionsFile = async (token, orgId, file, categoryNumber) => {
  const url = getAPIUrl() + '/uploadfile/?org_id=' + orgId + '&category=' + categoryNumber
  safeLog(url)

  const formData = new FormData()
  formData.append('file', file, file.name)

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        authorization: token,
      },
      body: formData
    })
    return checkResponse(response)
  } catch (error) {
    return error
  }
}

export const getOrgFiles = async (token, orgId) => {
  return genericAPICall(token, '/uploadfile/files', {orgId});
}

export const deleteFileFromDB = async (token, orgId, originalName, dbFileName) => {
  return genericAPICall(token, '/uploadfile/delete/?original_file_name=' + originalName + '&actual_file_name=' + dbFileName + '&org_id=' + orgId,
   {}, {}, 'DELETE')
}

export const setCarbonPrice = async (token, orgId, price) => {
  return genericAPICall(token,
    '/organisations/' + orgId + '/carbon_price?carbon_price=' + price, {}, {}, 'PATCH'
  )
}

export const setCurrency = async (token, orgId, currency) => {
  return genericAPICall(token,
    '/organisations/' + orgId + '/currency?currency=' + currency, {}, {}, 'PATCH'
  )
}

export const getTransparency = async (token, orgId, year, locationId, systemId, unitId, equipmentId) => {
  // todo - add ability to call /transparency/scopes/{scope} endpoint
  let url = '/transparency/'
  if (locationId)
    url += 'locations/' + locationId
  if (systemId)
    url += '/systems/' + systemId
  if (unitId)
    url += '/units/' + unitId
  if (equipmentId)
    url += '/equipment/' + equipmentId
  url += '/years/' + year

  return genericAPICall(token, url, {orgId: orgId})
}

// Use and improve this method wherever possible. This file has gotten way too long!
// Todo: refactor API calls that don't use this function to use this function, improving the function if need be
export const genericAPICall = async (token, endpoint, args = {}, body = {}, method = 'GET') => {
  let url = getAPIUrl() + endpoint
  if (args.year)
    url += 'years/' + args.year
  if (args.orgId)
    url += '?org_id=' + args.orgId
  if (args.category)
    url += '&category=' + args.category
  safeLog(url)

  const hasJSON = !(Object.keys(body).length === 0) && !body.file;

  let requestBody = null
  if (hasJSON)
    requestBody = JSON.stringify(body)

  try {
    const response = await fetch(url,
      {
        method: method || 'GET',
        headers: {
          authorization: token,
          'Accept': hasJSON ? 'application/json' : '',
          'Content-Type': hasJSON ? 'application/json' : '',
        },
        body: requestBody
      })
    return checkResponse(response)
  }
  catch (error) {
    return error
  }
}
