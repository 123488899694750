import { Box, Flex, Select, Spacer, Spinner, Text } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useAssetBreakdown, useAssets } from '../../../services/APIHooks'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsKPIs from '../EmissionsKPIs'

const EmissionsLocations = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const params = useParams()
  const { assetData, ready } = useAssets(auth.token, auth.orgId, outletContext.year)
  const locations = assetData

  const [location, setLocation] = useState()

  useEffect(() => {
    setLocation(params.locationId ?
      locations.find(location => Object(location).location_id.toString() === params.locationId)
      :
      locations.find(location => location.emissions_total) || locations[0])
  }, [locations, params])

  if (ready && !locations.length) {
    return <DataNotAvailable year={outletContext.year} description={"locations"} />
  }

  const ChooseLocation = () => {
    const navigate = useNavigate()
    const chooseLocation = (event) => navigate('/emissions/locations/' + event.target.value)

    if (!locations.length)
      return <Flex>
        <Spacer /><Spinner />
      </Flex>

    return <Flex>
      <Spacer />
      <Box>
        <Select variant={"filled"} value={Object(location)?.location_id} onChange={chooseLocation}>
          {locations.map((location, index) => (
            <option value={Object(location).location_id} key={index}> {Object(location).location_name} </option>
          ))}
        </Select>
      </Box>
    </Flex>
  }

  const KPIs = () => {
    return <Box mt="2em">
      <EmissionsKPIs
        accuracy={Object(location)?.accuracy || <>&mdash;</>}
        currency={auth.orgData.carbon_price_currency}
        cost={Object(location)?.emissions_total_cost_display}
        emissions={Object(location)?.emissions_total}
        noNBA
      />
    </Box>
  }


  const ChartContainer = () => {
    const { assetData, ready } = useAssets(auth.token, auth.orgId, outletContext.year, Object(location)?.location_id)

    const Chart = () => {
      if (ready && !Object(assetData).emissions_monthly?.length)
        return <DataNotAvailable noCard />
      return <EmissionsChart
        period='month'
        label={Object(location)?.location_name}
        type={'location'}
        scope={props.scope}
        lineData={Object(assetData).emissions_monthly || []}
      />
    }

    return <Card>
      <Flex>
        <Box>
          <Text> Monthly CO2e emissions for {Object(location).location_name || <>&mdash;</>}</Text>
        </Box>
      </Flex>

      <Chart />

    </Card>
  }

  const Systems = () => {
    const systems = useAssetBreakdown(auth.token, auth.orgId, outletContext.year, Object(location).location_id)

    if (!systems.length)
      return <></>

    return <Box mt="2em">
      <DataTable
        headings={['System', 'CO2e', 'Cost']}
        rows={systems.map(system => [
          system.system_name,
          system.emissions_total_display,
          system.emissions_total_cost_display
        ])}
        links={systems.map(system => {
          if (params.locationId)
            return 'system' + system.system_id
          return Object(location).location_id + '/system' + system.system_id
        })}
      />
    </Box>
  }

  return <Flex direction={"column"} gap="2em">
    <ChooseLocation />
    <KPIs />
    {
      Object(location)?.location_id ? <>
        <ChartContainer />
        <Systems />
      </> : <></>
    }
  </Flex>
}

export default EmissionsLocations