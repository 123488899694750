import './App.css'
import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom'
import Highcharts from 'highcharts'
import Roadmap from './components/Roadmaps/Roadmap'
import Emissions from './components/Emissions/Overview/Emissions'
import EmissionsScope from './components/Emissions/ScopeView/EmissionsScope'
import EmissionsHeader from './components/Emissions/EmissionsHeader'
import EmissionsSource from './components/Emissions/ScopeView/EmissionsSource'
import Reports from './components/Reports/Report'
import GHGProtocol from './components/Reports/Reports/GHGProtocol'
import TCFD from './components/Reports/Reports/TCFD'
import InsightsBenchmarks from './components/Insights/InsightsBenchmarks'
import InsightsCarbonPrices from './components/Insights/InsightsCarbonPrices'
import theme from './theme'
import React, { useEffect, useState } from 'react'
import APITest from './components/APITest'
import DefaultContainer from './components/General/DefaultContainer'
import LoginPage from './components/Login/LoginPage'
import { authProvider } from './services/authProvider'
import EmissionsSuppliers from './components/Emissions/Suppliers/EmissionsSuppliers'
import Dashboard from './components/Dashboard/Dashboard'
import EmissionsLocations from './components/Emissions/Locations/EmissionsLocations'
import EmissionsAssets from './components/Emissions/Locations/EmissionsAssets'
import ComingSoon from './components/Blank/ComingSoon'
import SupplierEmissions from './components/Emissions/Suppliers/SupplierEmissions'
import { safeLog } from './helpers/logging'
import { useOrganisation } from './services/APIHooks'
import Catch404 from './components/Blank/Catch404'
import Targets from './components/Targets/Targets'
import SupplierCategories from './components/Tables/SupplierCategories'
import AllSuppliers from './components/Tables/AllSuppliers'
import AllScopeCategories from './components/Tables/AllScopeCategories'
import AllScopeEquipment from './components/Tables/AllScopeEquipment'
import UserProfile from './components/User Profile/UserProfile'
import EmissionsProducts from './components/Emissions/Products/EmissionsProducts'
import EmissionsProduct from './components/Emissions/Products/EmissionsProduct'
import DataUpload from './components/Data/DataUpload'
import LocationsOverview from './components/Emissions/Locations/LocationsOverview'
import DataAdapters from './components/Data/DataAdapters'
import Experts from './components/Marketplace/Experts'
import ErrorBoundary from './components/General/ErrorBoundary'
import CarbonPriceControl from './components/Targets/CarbonPrice/CarbonPriceControl'

function App() {

  Highcharts.setOptions({
    colors: [
      theme.colors.fuchsia[400],
      theme.colors.canyon[400],
      theme.colors.cherry[500],
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
    ]
  })

  const AuthContext = React.createContext({ user: '' })

  const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(localStorage.getItem('username') || '')
    const [name, setName] = useState(localStorage.getItem('name') || '')
    const [originalOrgId, setOriginalOrgId] = useState(localStorage.getItem('originalOrgId') || false)

    const [token, setToken] = useState(localStorage.getItem('token') ? 'Bearer ' + localStorage.getItem('token') : '')
    const [orgId, setOrgId] = useState(localStorage.getItem('orgId') || '')
    const [year, setYear] = useState(localStorage.getItem('year') || new Date().getFullYear() - 1)
    const orgData = useOrganisation(token, orgId)

    safeLog('org data: ', orgData)

    const signIn = (username, password, callback) => {
      return authProvider.signin(username, password, (token, orgId, year, name, original_org_id) => {
        setUser(username)
        setName(name)
        setOrgId(orgId)
        setYear(year)
        setOriginalOrgId(original_org_id)
        setToken('Bearer ' + token)
        callback()
      })
    }

    const signOut = (callback) => {
      return authProvider.signout(() => {
        setUser('')
        setToken('')
        setOrgId('')
        callback()
      })
    }

    const updateYear = (newYear) => {
      setYear(newYear)
      authProvider.updateYear(token, user, newYear)
    }

    const value = { user, token, name, originalOrgId, year, orgId, orgData, signIn, signOut, setOrgId, updateYear }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  }

  const useAuth = () => React.useContext(AuthContext)

  const RequireAuth = ({ children }) => {
    const auth = Object(useAuth())
    const location = useLocation()

    // const path = location.pathname

    if (!auth.token)
      return <Navigate to="/login" state={{ from: location }} replace />

    // // Redirect demo URLs to 404 if the user isn't viewing a demo org
    // const demoUrls = [
    //   '/data', '/emissions/products', '/emissions/customers', '/roadmap/products',
    //   '/roadmap/customers', '/roadmap/suppliers', '/insights/carbon-prices'
    // ]

    return children
  }

  const ScrollToTop = ({ children }) => {
    const location = useLocation()
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [location.pathname])
    return children
  }

  return (

    <AuthProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              <Route path="/login" element={<LoginPage context={AuthContext} />} />
              <Route element={<RequireAuth><DefaultContainer context={AuthContext} /></RequireAuth>}>
                <Route path="*" element={<ComingSoon />} />
                <Route path="/404" element={<Catch404 />} />
                <Route path="/dashboard" element={<Dashboard context={AuthContext} />} />

                <Route path="/targets">
                  <Route index element={<Targets context={AuthContext}/>} />
                  <Route path='carbon-price' element={<CarbonPriceControl context={AuthContext}/>} />
                </Route>

                <Route path="/roadmap" element={<Roadmap context={AuthContext} />} />
                <Route path="/roadmap/scope1" element={<Roadmap scope={1} context={AuthContext} />} />
                <Route path="/roadmap/scope2" element={<Roadmap scope={2} context={AuthContext} />} />
                <Route path="/roadmap/scope3" element={<Roadmap scope={3} context={AuthContext} />} />
                <Route path="/roadmap/targets" element={<Roadmap isTargetView context={AuthContext} />} />
                <Route path="/roadmap/targets/:targetId" element={<Roadmap isTargetView context={AuthContext} />} />
                <Route path="roadmap/locations" element={<Roadmap isLocationView context={AuthContext} />} >
                  <Route path=":locationId" element={<Roadmap isLocationView context={AuthContext} />} />
                </Route>

                <Route path="/emissions" element={<EmissionsHeader context={AuthContext} />}>
                  <Route index element={<Emissions context={AuthContext} />} />
                  <Route path="scope1" element={<EmissionsScope scope={1} context={AuthContext} />} />
                  <Route path="scope2" element={<EmissionsScope scope={2} context={AuthContext} />} />
                  <Route path="scope3" element={<EmissionsScope scope={3} context={AuthContext} />} />
                  <Route path="scope:scope/categories" element={<AllScopeCategories context={AuthContext} />} />
                  <Route path="scope:scope/equipment" element={<AllScopeEquipment context={AuthContext} />} />
                  <Route path="scope:scope/sources/:source" element={<EmissionsSource />} />
                  <Route path="scope:scope/category:category" element={<EmissionsSource context={AuthContext} />} />
                  <Route path="scope:scope/category:category/subcategory:subcategory" element={<EmissionsSource context={AuthContext} />} />
                  <Route path="scope:scope/category:category/subcategory:subcategory/tertiarycategory:tertiarycategory" element={<EmissionsSource context={AuthContext} />} />
                  <Route path="products" element={<EmissionsProducts context={AuthContext} />} />
                  <Route path="products/:productId" element={<EmissionsProduct context={AuthContext} />} />
                  <Route path="suppliers" element={<EmissionsSuppliers context={AuthContext} />} />
                  <Route path="suppliers/categories" element={<SupplierCategories context={AuthContext} />} />
                  <Route path="suppliers/all" element={<AllSuppliers context={AuthContext} />} />
                  <Route path="suppliers/:supplierId" element={<SupplierEmissions context={AuthContext} />} />
                  <Route path="locations" element={<LocationsOverview context={AuthContext} />} />
                  <Route path="locations/:locationId" element={<EmissionsLocations context={AuthContext} />} />
                  <Route path="locations/:locationId/system:systemId" element={<EmissionsAssets context={AuthContext} />} />
                  <Route path="locations/:locationId/system:systemId/unit:unitId" element={<EmissionsAssets context={AuthContext} />} />
                  <Route path="locations/:locationId/system:systemId/unit:unitId/equipment:equipmentId" element={<EmissionsAssets context={AuthContext} />} />
                </Route>

                <Route path="/apitest" element={<APITest context={AuthContext} />} />

                <Route path="/reports">
                  <Route index element={<Reports context={AuthContext} />} />
                  <Route path="/reports">
                    <Route path="/reports/ghg-protocol" element={<GHGProtocol context={AuthContext} />} />
                    <Route path="/reports/ghg-protocol/years/:yearReport" element={<GHGProtocol context={AuthContext} />} />
                    <Route path="/reports/tcfd" element={<TCFD context={AuthContext} />} />
                    <Route path="/reports/tcfd/years/:yearReport" element={<TCFD context={AuthContext} />} />
                  </Route>
                </Route>

                <Route path="/insights" >
                  <Route index element={<Navigate to="carbon-index" />} />
                  <Route path="carbon-index" element={<InsightsBenchmarks context={AuthContext} />} />
                  <Route path="carbon-prices" element={<InsightsCarbonPrices context={AuthContext} />} />
                </Route>

                <Route path="/marketplace" >
                  <Route index element={<Experts context={AuthContext} />} />
                </Route>

                <Route path="/data">
                  <Route index element={<Navigate to="upload" />} />
                  <Route path="upload" element={<DataUpload context={AuthContext} />} />
                  <Route path="data-adapters" element={<DataAdapters context={AuthContext} />} />
                </Route>

                <Route path="/profile" element={<UserProfile context={AuthContext} />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </ErrorBoundary>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
