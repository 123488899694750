import { Button, Flex, Select, Spacer, Spinner, Text } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { safeLog } from '../../helpers/logging'
import { alphabeticalSort } from '../../helpers/sorting'
import { changeOrganisation } from '../../services/APICalls'
import { usePartners } from '../../services/APIHooks'

const ChangeOrganisation = (props) => {

  const auth = useContext(props.context)
  const currentOrgId = auth.orgId

  const partnersData = usePartners(auth.token, auth.originalOrgId)
  const organisations = partnersData.partners
  const ready = partnersData.ready

  const [newOrgId, setNewOrgId] = useState(currentOrgId)
  const [isLoading, setIsLoading] = useState(false)

  const saveButtonClick = () => {
    setIsLoading(true)

    changeOrganisation(auth.token, auth.user, newOrgId).then(response => {
      setIsLoading(false)
      if (Number(response) ===  204) {
        safeLog('Error switching organisation!', newOrgId, '->', currentOrgId, auth.user)
        return
      }
      auth.setOrgId(newOrgId)
      localStorage.setItem('orgId', newOrgId)
    })
  }

  if (ready && !organisations.length)
    return <></>

  if (!auth.orgId || !ready)
    return <Spinner />

  return (
    <Flex direction={'column'} gap='0.5em'>
      <Text fontSize={'12px'}> Select another organisation to view </Text>

      <Select value={newOrgId} onChange={(e) => setNewOrgId(e.target.value)}>
        {organisations?.sort(alphabeticalSort('org_name')).map((org, index) => (
          <option value={org.partner_org_id} key={index}> {org.org_name} </option>
        ))}
      </Select>

      <Flex w='100%'>
        <Spacer/>
        <Button
          onClick={saveButtonClick}
          isLoading={isLoading}
          isDisabled={Number(currentOrgId) === Number(newOrgId)}
        > Change organisation </Button>
      </Flex>
    </Flex>
  )
}

export default ChangeOrganisation