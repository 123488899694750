import { Box, Circle, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { displayCarbon, displayCost, round } from '../../../helpers/formatValues'
import theme from '../../../theme'
import DataTable from '../../General/DataTable'

const ScopeEmissionsTable = (props) => {

  const emissions = props.emissions

  const getPercentage = (scope) => {
    return (emissions['emissions_scope' + scope] / emissions.emissions_total) * 100
  }

  return (
    <DataTable
      headings={['', '', '']}
      rows=

      {[1, 2, 3].map(scope => [
        <Flex minH="80px" alignItems={"center"}>
          <Circle size={"8px"} mr="16px" bg={theme.colors['scope' + scope][500]} />
          <Box>
            <Text
              fontFamily={"SF Pro Display"}
              color="onSurface.light.low"
              fontSize={"12px"}
            > SCOPE {scope} </Text>

            <Text
              mt="8px"
            >
              {round(getPercentage(scope), 2) ?? <>&mdash;</>} %
            </Text>
          </Box>
        </Flex>,
        <Flex minH="80px" direction={"column"} justifyContent="center">
          <Text
            fontFamily={"SF Pro Display"}
            color="onSurface.light.low"
            fontSize={"12px"}
          > EMISSIONS </Text>
          <Text
            mt="8px"
          >
            {displayCarbon(emissions['emissions_scope' + scope]) || <>&mdash;</>}
          </Text>
        </Flex>,
        <Flex minH="80px" direction={"column"} justifyContent="center">
          <Text
            fontFamily={"SF Pro Display"}
            color="onSurface.light.low"
            fontSize={"12px"}
          > COST </Text>
          <Text
            mt="8px"
          >
            {
            emissions['emissions_scope' + scope + '_cost'] != null && emissions.carbon_price ?
            displayCost(emissions['emissions_scope' + scope + '_cost'], undefined, undefined, undefined, props.currency)
            : <>&mdash;</>}
          </Text>
        </Flex>
      ])}
    />
  )
}

export default ScopeEmissionsTable