import { Box, Button, ButtonGroup, Flex, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { round } from '../../../helpers/formatValues'
import CenteredSpinner from '../../Blank/CenteredSpinner'
import DataDisplay from '../../General/DataDisplay'
import PercentageDonut from '../../General/PercentageDonut'
import DataSourceDisplay from './DataSourceDisplay'
import { describeAccuracy } from './describeAccuracy'

const AccuracyModal = (props) => {

  let data = {}
  data = {
    accuracy: props.data?.accuracy,
    dataSources: [],
    types: [],
    factors: [],
    methodologies: [],
  }

  if (props.data?.transparency_info || props.data?.transparency_metrics) {
    const transparencyInfo = props.data?.transparency_info || props.data?.transparency_metrics
    const headings = [...new Set(transparencyInfo.factor_info?.map(factor => factor.factor_source))]

    data = {
      accuracy: transparencyInfo.accuracy,
      title: props.title,
      dataSources: transparencyInfo.file_info?.map(file => [file.file_name, file.file_type, file.date_ingested])
        .filter(file => file[0] || file[1])
        .sort((fileA, fileB) => new Date(fileB[2]).getTime() - new Date(fileA[2]).getTime()),
      types: [...new Set(transparencyInfo.file_info?.filter(file => file.data_type !== 'Unavailable').map(file => file.data_type))],
      factors: headings.map(heading => {
        return {
          heading: heading,
          factors: transparencyInfo.factor_info
            .filter(factor => factor.factor_source === heading)
            .map(factor => (factor.factor_id? '#' + factor.factor_id + ' ' : '') + factor.factor_name + ': ' + round(factor.factor_value, 2) + ' kg CO2e/' + factor.uom)
        }
      }),
      methodologies: transparencyInfo.method_info,
    }
  }

  const BulletList = (props) => {
    if (!props.data?.length)
      return <>&mdash;</>
    return <UnorderedList>
      {props.data.map((type, index) =>
        <ListItem key={index}>
          {type}
        </ListItem>
      )}
    </UnorderedList>
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} preserveScrollBarGap={true}>
      <ModalOverlay />
      <ModalContent bg="ui.200" minW="600px">
        <ModalHeader> {data.title} Data Details </ModalHeader>
        <ModalCloseButton />

        <ModalBody>

          {props.loading ? <CenteredSpinner/> : <></>}

          {props.data?.accuracy || data.accuracy ?
            <DataDisplay
              rows={[
                ['Data Sources',
                  <Flex alignItems="center" gap="10px">
                    <DataSourceDisplay dataSources={data.dataSources} />
                  </Flex>
                ],
                ['Data Types',
                  <BulletList data={data.types} />
                ],
                ['Emission factors', data.factors.length ? data.factors.map((factorType, index) => (
                  <Box key={index}>
                    {factorType.heading}
                    <BulletList data={factorType.factors} />
                  </Box>
                )) : <>&mdash;</>],
                ['Calculation methodologies',
                  data.methodologies?.length ? <UnorderedList>
                    {data.methodologies.map((methodology, index) =>
                      <ListItem key={index}>
                        {methodology.method_name}
                        : <a href={methodology.method_source} rel='noreferrer' target='_blank'><u>{methodology.methodology} </u></a>
                      </ListItem>)}
                  </UnorderedList> : <>&mdash;</>],
                ['Emission accuracy estimation',
                  <Flex alignItems={"center"}>
                    <PercentageDonut value={data.accuracy / 7 * 100} />
                    {describeAccuracy(data.accuracy)}
                  </Flex>
                ],

              ]}
            /> : <></>
          }
        </ModalBody>

        <ModalFooter>
          <ButtonGroup w="100%">
            {/* <Tooltip label='Flag innacuracy'>
              <IconButton
                // disabled
                color="red"
                bg="danger.high"
                _hover={{ bg: "danger.medium" }}
                _active={{ bg: "danger.low" }}
                aria-label='Flag inaccuracy button'
                icon={<Image src="/icons/flag.svg" />}
              />
            </Tooltip> */}
            <Spacer />
            <Button onClick={props.onClose}> Close </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AccuracyModal