export const demoAdapters = [
  {name: 'Sage', slug:'sage', url: 'www.sage.com', category: 'Accounting', connected: false, comingSoon: false,
  toIngest: 'Spend'},
  {name: 'SAP', slug:'sap', url: 'www.sap.com', category: 'Business operations', connected: true,
  comingSoon: false, toIngest: 'Operations'},
  {name: 'Quickbooks', slug:'quickbooks', url: 'quickbooks.intuit.com', category: 'Accounting', connected: false,
  comingSoon: false, toIngest: 'Spend'},
  {name: 'Xero', slug:'xero', url: 'www.xero.com', category: 'Accounting', connected: false, comingSoon: false,
  toIngest: 'Spend'},
  {name: 'Oracle', slug: 'oracle', url: 'www.oracle.com', category: 'Database', connected: false, comingSoon: false, toIngest: 'Operations'},
  {name: 'Infor', slug: 'infor', url: 'www.infor.com', category: 'Cloud', connected: false, comingSoon: true, toIngest: 'Processing'},
  {name: 'Amazon Web Services', slug: 'amazon-web-services', url: 'aws.amazon.com', category: 'Cloud', connected: false, comingSoon: true, toIngest: 'Processing'},
  {name: 'Microsoft Azure', slug: 'microsoft-azure', url: 'azure.microsoft.com', category: 'Cloud', connected: false, comingSoon: true, toIngest: 'Processing'},
  {name: 'Google Cloud Platform', slug: 'google-cloud-platform', url: 'cloud.google.com', category: 'Cloud', connected: false, comingSoon: true, toIngest: 'Processing'},
  {name: 'LinkedIn', slug: 'linkedin', url: 'linkedin.com', category: 'Social media', connected: false, comingSoon: true, toIngest: 'Profile'},
  {name: 'Epicor', slug: 'epicor', url: 'www.epicor.com', category: 'Business operations', connected: false, comingSoon: true, toIngest: 'Operations'},
  {name: 'SAP Concur', slug: 'sap-concur', url: 'www.concur.com', category: 'Expense', connected: false, comingSoon: true, toIngest: 'Spend'},
  {name: 'Expensify', slug: 'expensify', url: 'www.expensify.com', category: 'Expense', connected: false, comingSoon: true, toIngest: 'Spend'},
  {name: 'Samsara', slug: 'samsara', url: 'www.samsara.com', category: 'IoT', connected: false, comingSoon: true, toIngest: ''},
  {name: 'Stripe', slug: 'stripe', url: 'www.stripe.com', category: 'Accounting', connected: false, comingSoon: true, toIngest: ''},
]