import { Box } from '@chakra-ui/react'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import React, { useRef } from 'react'
import { getMapOptions } from '../../helpers/mapChartProvider'
import theme from '../../theme'


const ExpertMap = (props) => {

  const chartOptions = getMapOptions()

  const chartRef = useRef(null)

  chartOptions.chart.height = 250
  // chartOptions.chart.width = 400

  chartOptions.datalabels = {
    color: 'white'
  }

  chartOptions.chart.events = {
    load: function () {
      const point = this.get('expert')
      const coords = this.mapView.lonLatToPixels(point)
      this.mapView.zoomBy(3, undefined, [coords.x, coords.y])
    }
  }

  chartOptions.series.push(Object({
    type: 'mappoint',
    name: 'Cities',
    accessibility: {
      point: {
        valueDescriptionFormat: '{xDescription}. Lat: {point.lat:.2f}, lon: {point.lon:.2f}.'
      }
    },
    color: 'white',
    dataLabels: {
      color: 'white',
    },
    data: [{
      name: props.location,
      lat: props.lat,
      lon: props.lon,
      id: 'expert'
    }]
  }))

  chartOptions.tooltip.enabled = false
  // chartOptions.mapNavigation.enabled = false
  chartOptions.series[0].nullColor = theme.colors.mint['600']

  return (
    <Box h='200px' onClick={(e) => {e.stopPropagation()}} cursor='default'>
      <HighchartsReact
        constructorType='mapChart'
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </Box>
  )
}

export default ExpertMap

//

// let expertPoint = this.get('expert')
//       expertPoint.zoomTo()
//       this.mapZoom(0.5)

//