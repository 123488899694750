import { Box, Flex, Spacer, Text, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { round } from '../../../helpers/formatValues'
import { useCategoryEmissions, useCategoryEmitters } from '../../../services/APIHooks'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import ExpandModalButton from '../../General/ExpandModalButton'
import AccuracyModal from '../Accuracy/AccuracyModal'
import EmissionsChart from '../Charts/EmissionsChart'
import EmissionsKPIs from '../EmissionsKPIs'

const EmissionsSource = (props) => {

  const auth = useContext(props.context)
  const params = useParams()
  const outletContext = Object(useOutletContext())
  const scope = params.scope
  const categoryId = params.category
  const secondaryId = params.subcategory
  const tertiaryId = params.tertiarycategory

  const [noData, setNoData] = useState(false)


  const Overview = () => {
    const sourceData = useCategoryEmissions(auth.token, auth.orgId, scope, categoryId, secondaryId, tertiaryId, outletContext.year)
    const [accuracyData, setAccuracyData] = useState({})
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
      if (Number(sourceData) === 204)
        setNoData(true)
    }, [sourceData])

    if (Number(sourceData) === 204)
      return  <></>

    return <>
      <Box>
        <EmissionsKPIs
          noNBA
          accuracy={round(sourceData.accuracy)}
          cost={sourceData.emissions_total_cost_display}
          currency={auth.orgData.carbon_price_currency}
          emissions={sourceData.emissions_total}
        />
      </Box>
      <Box mt="2em">
        <Card>
          <Flex>
            <Text> {sourceData.name} CO2e emissions </Text>
            <Spacer />
            <ExpandModalButton enable ready={sourceData.accuracy != null} onClick={() => { setAccuracyData(sourceData); onOpen() }}/>
          </Flex>
          <EmissionsChart
            period={props.scope === 1 ? 'day' : 'month'}
            scope={Number(scope)}
            lineData={sourceData.emissions_monthly}
          />
        </Card>
        <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
      </Box>
    </>
  }

  const Breakdown = () => {
    const breakdownData = useCategoryEmitters(auth.token, auth.orgId, scope, categoryId, secondaryId, outletContext.year)
    const [accuracyData, setAccuracyData] = useState({})
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
      if (Number(breakdownData) === 204)
        setNoData(true)
    }, [breakdownData])

    if (Number(breakdownData) === 204)
      return <></>

    let links
    if (tertiaryId) links = null
    else links = breakdownData.map(category =>
      secondaryId ? 'tertiarycategory' + category.tertiary_category_id : 'subcategory' + category.secondary_category_id
    )

    return <Box mt="2em">
      <DataTable
        headings={['Category', 'Emissions', 'Cost', '']}
        rows={breakdownData.map(category => [
          category.tertiary_category || category.secondary_category || category.primary_category,
          category.emissions_total_display,
          category.emissions_total_cost_display,
          <ExpandModalButton ready={breakdownData.length} enable onClick={() => { setAccuracyData(category); onOpen() }}/>
        ])}
        links={links}
      />
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
    </Box>
  }

  if (noData)
    return <DataNotAvailable/>

  return (
    <>
      <Overview />

      {tertiaryId ?
        <></>
        :
        <Breakdown />
      }
    </>
  )
}

export default EmissionsSource