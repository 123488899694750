import { Center, Flex, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { displayCarbon } from '../../../helpers/formatValues'
import { useProductBreakdown } from '../../../services/APIHooks'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import ExpandModalButton from '../../General/ExpandModalButton'
import AccuracyModal from '../Accuracy/AccuracyModal'
import BarChart from '../Charts/BarChart'

const EmissionsProducts = (props) => {
  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())

  const ProductBreakdown = () => {
    const { products, ready } = useProductBreakdown(auth.token, auth.orgId, outletContext.year)

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [accuracyData, setAccuracyData] = useState({})

    if (!ready)
      return <Center><Spinner /></Center>

    if (!products.length)
      return <DataNotAvailable />

    return <Card>
      <Flex direction='column' gap='1em'>
        <div>
          <Text> {outletContext.year} product emissions </Text>
          <Text className='subtitle'>
            Emissions from {outletContext.year} seperated by product.
            Products in the bottom 1 percentile are excluded from the chart.
          </Text>
        </div>

        <BarChart
          data={
            products.filter(product => product.emissions > products[0].emissions / 100)
              .map(product => [product.name, product.emissions])
          }
        />

        <DataTable
          headings={['Product', 'Descripton', 'Emissions', '']}
          rows={products.map(product => [
            product.name, product.description, displayCarbon(product.emissions),
            <ExpandModalButton onClick={() => { setAccuracyData(product); onOpen() }}
            enable={product.transparency_metrics?.accuracy != null} ready={products.length}/>
          ])}
          links={products.map(product => product.product_id.toString())}
        />
        <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
      </Flex>
    </Card>
  }

  return <ProductBreakdown />
}

export default EmissionsProducts