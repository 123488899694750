import { Text, Image } from '@chakra-ui/react'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const SideNavLink = (props) => {
  const location = useLocation()

  const active = location.pathname.startsWith(props.url)

  return (
    <Link to={props.url}>

      <Text
        background={active ? "primary.soft" : ""}

        lineHeight={"48px"}
        color={active ? "onSurface.primary.high" : "grayscale.300"}
        display={"inline-flex"}
        alignItems={"center"}
        margin={"8px 0px"}
        paddingLeft={"8px"}
        borderRadius={"8px"}

        fontSize={"16px"}

        width={"100%"}
      >
        <Image src={props.icon} mr={"12px"} />

        {props.children}

      </Text>

    </Link>
  )
}

export default SideNavLink