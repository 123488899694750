import { IconButton } from '@chakra-ui/react'
import React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Link } from 'react-router-dom'

const LinkExternalButton = (props) => {

  // TODO this is appending the external url to the current url as opposed to linking to the external url
  return (
    <a href={props.url} target="_blank">
      <IconButton
        disabled={!props.url}
        aria-label= {props.ariaLabel}
        height={"40px"}
        icon={<ExternalLinkIcon />}
        color={"rgba(0, 168, 240, 0.9)"}
        borderColor={"rgba(0, 168, 240, 0.9)"}
        variant={"outline"}
      />
    </a>
  )
}

export default LinkExternalButton