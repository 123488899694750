import { Center, Link, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import Card from '../General/Card'

const DataNotAvailable = (props) => {

  const Contents = () => {
    return <VStack margin={'30px'}>
      {/* <Text> Data is not available {props.description || props.year ? "for " +
            props.description + " " + props.year : ""}</Text> */}

      <Text> Data is not available {props.year ? "for " + props.year : "for this section"} </Text>

      <Text> Contact <Link color="sky.500" href="mailto: enable@expectai.com" target={"_blank"}>enable@expectai.com</Link> to
        add the necessary data for this area </Text>
    </VStack>
  }

  if (props.noCard)
    return <Contents />

  return (
    <Card>
      <Center h={props.h || props.height || "calc(100vh - 200px)"}>
        <VStack>
          {/* <Text> Data is not available {props.description || props.year ? "for " +
            props.description + " " + props.year : ""}</Text> */}

          <Text> Data is not available {props.year ? "for " + props.year : "for this section"} </Text>

          <Text> Contact <Link color="sky.500" href="mailto: enable@expectai.com" target={"_blank"}>enable@expectai.com</Link> to
            add the necessary data for this area </Text>
        </VStack>
      </Center>
    </Card>
  )
}

export default DataNotAvailable