import { useDisclosure } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { checkProxy } from '../../helpers/checkProxy'
import { displayCarbon } from '../../helpers/formatValues'
import { useTopEmitters } from '../../services/APIHooks'
import AccuracyModal from '../Emissions/Accuracy/AccuracyModal'
import DataTable from '../General/DataTable'
import ExpandModalButton from '../General/ExpandModalButton'

const AllScopeEquipment = (props) => {

  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const params = useParams()

  const emitters = useTopEmitters(auth.token, auth.orgId, params.scope, outletContext.year)

  const [accuracyData, setAccuracyData] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <DataTable
        headings={['Equipment', 'Location', 'Unit', 'System', 'CO2e', 'Cost', '']}
        rows={emitters.map((emitter) => [
          checkProxy(emitter, 'equipment'),
          emitter.location_name,
          checkProxy(emitter, 'unit'),
          checkProxy(emitter, 'system'),
          displayCarbon(emitter.emissions_total),
          emitter.emissions_total_cost_display,
          <ExpandModalButton ready={emitters.length} enable onClick={() => { setAccuracyData(emitter); onOpen() }} />
        ])
        }
      />
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose}/>
    </>
  )
}

export default AllScopeEquipment