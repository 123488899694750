import { Center, Flex, Heading, Spinner, Text, useDisclosure } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { displayCarbon } from '../../../helpers/formatValues'
import { useProductEmissions } from '../../../services/APIHooks'
import DataNotAvailable from '../../Blank/DataNotAvailable'
import Card from '../../General/Card'
import DataTable from '../../General/DataTable'
import ExpandModalButton from '../../General/ExpandModalButton'
import KPI from '../../General/KPI'
import AccuracyModal from '../Accuracy/AccuracyModal'
import BarChart from '../Charts/BarChart'

const EmissionsProduct = (props) => {
  const auth = useContext(props.context)
  const outletContext = Object(useOutletContext())
  const params = useParams()

  const { productEmissions, ready } = useProductEmissions(auth.token, auth.orgId, outletContext.year, params.productId)

  if (!ready)
    return <Center><Spinner /></Center>

  const emissions = [
    ['Raw Material Emissions', 'raw_material_emissions'],
    ['Production Emissions', 'production_emissions'],
    ['Upstream Transport Emissions', 'ustream_transport_emissions'],
    ['Downstream Emissions', 'downstream_transport_emissions'],
    ['Usage Emissions', 'use_emissions'],
    ['Disposal Emissions', 'disposal_emissions'],
  ]

  const totalEmissions = emissions.map(category => Math.abs(productEmissions[category[1]])).reduce((a, b) => a + b, 0)

  const Chart = () => {
    if (totalEmissions === 0)
      return <Center padding={'2em'}>
        <Text> Found no emissions data for this product for this year </Text>
      </Center>

    return <BarChart
      horizontal
      data={emissions.map(category => [category[0], productEmissions[category[1]]])}
      yAxisFormatter={
        function () {
          return '<span style="font-size:12px"> STAGE ' + (this.pos + 1) + '</span><br/>' + this.value
        }
      }
    />
  }

  const Table = () => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [accuracyData, setAccuracyData] = useState({})

    return <>
      <DataTable
        headings={['Stage', 'Stage name', 'Emissions', 'Cost', '']}
        rows={emissions.map((stage, stageNumber) => [
          (stageNumber + 1).toString(),
          stage[0],
          displayCarbon(productEmissions[stage[1]]),
          productEmissions[stage[1] + '_cost_display'],
          <ExpandModalButton onClick={() => { setAccuracyData({transparency_info: productEmissions.transparency_metrics[stageNumber]}); onOpen() }}
            enable ready={ready} />
        ])}
      />
      <AccuracyModal isOpen={isOpen} data={accuracyData} onClose={onClose} />
    </>
  }

  if (!emissions.map(category => productEmissions[category[1]] != null).includes(true))
    return <DataNotAvailable />

  return <Flex direction={'column'} gap='2em'>
    <Heading size='lg'> {productEmissions.product_name} emissions </Heading>

    <Flex gap='2em'>
      <KPI
        label='Total products produced'
        value={productEmissions.amount_of_product?.toLocaleString('en-US')}
      />

      <KPI
        label='Total emissions'
        value={displayCarbon(totalEmissions)}
      />

      <KPI
        label='Emissions per unit'
        value={displayCarbon(totalEmissions / productEmissions.amount_of_product)}
      />
    </Flex>


    <Card>
      <Text> Product emissions split by category </Text>
      <Chart />
    </Card>

    <Table />

  </Flex>
}

export default EmissionsProduct