import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, CloseButton, Spacer, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { capitalise } from '../../../helpers/formatValues'

const UploadAlert = (props) => {

  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true })

  if (!isOpen)
    return <></>

  return <Alert status={props.type || 'error'} marginBottom={props.plain ? '' : '1em'} borderRadius='6px'>
    {props.plain ? <></> : <AlertIcon />}
    <Box>
      {props.plain ? <></> :
        <AlertTitle> {capitalise(props.type || 'Error')} </AlertTitle>}
      <AlertDescription>
        {props.message}
      </AlertDescription>
    </Box>
    <Spacer />
    <CloseButton
      alignSelf='flex-start'
      position='relative'
      right={-1}
      top={-1}
      onClick={onClose}
    />
  </Alert>
}

export default UploadAlert