import React, { useContext, useState } from 'react'
import { Flex, Box, Button, ButtonGroup, useDisclosure, Image, Spinner, Center } from '@chakra-ui/react'
import InitiativeGrid from './Initiatives/InitiativeGrid'
import { useInitiatives } from '../../services/APIHooks'
import InitiativeFormModal from './Form/InitiativeFormModal'
import InitiativeTable from './Initiatives/InitiativeTable'

const InitiativesContainer = (props) => {

  const auth = useContext(props.context)
  const { initiativeData, setInitiativeData, loaded } = useInitiatives(auth.token, auth.orgId, props.scope)
  const [newInitiatives, setNewInitiatives] = useState([])

  const [gridMode, setGridMode] = useState(true)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [activeInitiative, setActiveInitiative] = useState({})

  const sortInitiatives = (initiativeA, initiativeB) => {
    // if ((initiativeA.Initiative.carbon_impact_date != null) == (initiativeB.Initiative.carbon_impact_date != null))
    return initiativeB.Initiative.compass_score - initiativeA.Initiative.compass_score
    // return (initiativeB.Initiative.carbon_impact_date) ? 1 : -1
  }

  const addInitiative = () => {
    setActiveInitiative({})
    onOpen()
  }

  const editInitiative = (initiative) => {
    setActiveInitiative(initiative)
    onOpen()
  }

  if (!initiativeData.length && !loaded) {
    return <Center padding="50px">
      <Spinner />
    </Center>
  }

  return (
    <Box>

      <Flex justifyContent={"right"} mb={"1em"} gridGap={"1em"}>
        <ButtonGroup isAttached variant='outline'>

          <Button variant={gridMode ? 'outline' : 'ghost'} onClick={() => setGridMode(true)}>
            <Image src="/icons/gridMode.svg"></Image>
          </Button>
          <Button variant={gridMode ? 'ghost' : 'outline'} onClick={() => setGridMode(false)}>
            <Image src="/icons/tableMode.svg"></Image>
          </Button>

        </ButtonGroup>

        {/* <Button rightIcon={<ChevronDownIcon />}>
          Filters
        </Button> */}

        <Button
          onClick={addInitiative}
          bg="expect.sky"
          fontWeight={500}
          _hover={{}}
          _focus={{}}
        >Add initiative</Button>
      </Flex>

      <InitiativeFormModal
        baselineYear={props.baselineYear}
        finalYear={props.finalYear}
        initiative={activeInitiative}

        initiativeData={initiativeData}
        setInitiatives={setInitiativeData}

        newInitiatives={newInitiatives}
        setNewInitiatives={setNewInitiatives}

        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}

        context={props.context}
      />

      {gridMode ?
        <InitiativeGrid
          baselineYear={props.baselineYear}
          currency={auth.orgData.carbon_price_currency}
          editInitiative={editInitiative}
          finalYear={props.finalYear}
          initiatives={initiativeData.sort(sortInitiatives)}
          initiativeYearChange={props.initiativeYearChange}
          newInitiatives={newInitiatives}
        />
        :
        <InitiativeTable
          baselineYear={props.baselineYear}
          editInitiative={editInitiative}
          finalYear={props.finalYear}
          initiatives={initiativeData.sort(sortInitiatives)}
          initiativeYearChange={props.initiativeYearChange}
        />
      }

    </Box>
  )
}

export default InitiativesContainer