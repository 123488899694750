import { Box, Button, Flex, Image, Spacer, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import { displayCarbon } from '../../../helpers/formatValues'

const OverallTargetCard = (props) => {

  const targets = props.targetData.targetData
  const combinedTarget = props.targetData.combinedTarget

  const latestTargetYear = Math.max(...targets.map(target => target.year))

  const currentEmissions = combinedTarget[0]?.target_emissions
  const latestTargetEmissions = combinedTarget[combinedTarget.length - 1]?.target_emissions
  // const totalReduction = currentEmissions - latestTargetEmissions
  const totalPercentageReduction = (1 - latestTargetEmissions / currentEmissions) * 100

  const Content = () => {
    if (targets.length) return <>
      <Flex alignItems={"start"}>
        <Box>
          <Text
            textTransform={"uppercase"}
            lineHeight={"20px"}
          > Keep the eyes on the prize 🏆 </Text>
          <Text
            fontSize={"14px"}
            textColor={"ice.200"}
            lineHeight={"20px"}
          > Reduce emissions by {latestTargetYear || <>&mdash;</>} </Text>
        </Box>
        <Spacer />
        <Image src="\icons\activity.svg" />
      </Flex>

      <Spacer />

      <Text
        mt={"8px"}
        fontSize={"32px"}
        lineHeight={"40px"}
        color={"ice.50"}
      >
        {Math.round(totalPercentageReduction) || <>&mdash;</>}%
      </Text>

      <Spacer />
    </>

    return <>
      <Flex alignItems={"start"}>
        <Box>
          <Text
            textTransform={"uppercase"}
            lineHeight={"20px"}
          > Let's get started 🏃‍♀️ </Text>
          <Text
            fontSize={"14px"}
            textColor={"ice.200"}
            lineHeight={"20px"}
          > Add your first decarbonisation target </Text>
        </Box>
        <Spacer />
        <Image src="\icons\activity.svg" />
      </Flex>

      <Spacer/>

      <Button> Add your first target </Button>

      <Text
        mt={"8px"}
        fontSize={"32px"}
        lineHeight={"40px"}
        color={"ice.50"}
      >

      </Text>
    </>
  }

  return (
    <Link to={targets.length ? "/roadmap" : "/targets"} state={{new: true}}>
      <Flex
        h="100%" w="100%"
        borderRadius={"8px"}
        padding={"20px"}
        direction={"column"}

        backgroundColor={"rgba(0, 255, 255, 0.35);"}
        backgroundPosition={"bottom"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"100% auto"}
        backgroundImage={"/images/dashboard/reductionCardBackground.svg"}
      >

        <Content />

      </Flex>
    </Link>
  )
}
export default OverallTargetCard

