import { WarningTwoIcon } from '@chakra-ui/icons'
import { Box, Button, Image, Tooltip } from '@chakra-ui/react'
import React from 'react'

const ExpandModalButton = (props) => {

  const onClick = (event) => {
    event.stopPropagation()
    props.onClick()
  }

  // if (!(window.location.host === 'localhost:3000' ||
  //   window.location.host === 'x2-staging-vtd5ekus7a-oa.a.run.app' ||
  //   window.location.host === 'x2-staging.expectai.com'))
  //   return <></>

  if (props.ready && !props.enable) {
    return <Tooltip label='Add the data needed to determine the emissions for this category'>
      <Box margin="5px">
        <Button
          h="32px" w="20px"
          variant={"unstlyed"}
          padding={"0px"}
          disabled
        >
          <WarningTwoIcon w="20px" />
        </Button>
      </Box>
    </Tooltip>
  }

  return (
    <Tooltip label="View data details">
      <Box margin="5px">
        <Button
          aria-label='Expand modal button'
          h="32px" w="20px"
          variant={"ghost"}
          padding={"0px"}

          disabled={!props.enable}
          onClick={onClick}
        >
          <Image src='/icons/verticalDots.svg' />
        </Button>
      </Box>
    </Tooltip>
  )
}

export default ExpandModalButton