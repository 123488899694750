import { Button, Center, Flex, Image, Text } from '@chakra-ui/react'
import React from 'react'
import { FileUploader } from 'react-drag-drop-files'

const UploadDragDrop = (props) => {

  const handleChange = (newFile) => {
    if (props.files.find(file => file.name === newFile.name))
      return

    if (!['csv', 'xlsx', 'json', 'xml'].map(type => newFile.type.includes(type)).includes(true)) {
      const copy = [...props.alerts]
      copy.push({message: newFile.type + ' is not an accepted type'})
      // @ts-ignore
      props.setAlerts(copy)
      return
    }

    const copy = [...props.files]
    copy.push(newFile)
    props.setFiles(copy)

    if (props.onUpload)
      props.onUpload(newFile)
  }

  return (
    <FileUploader handleChange={handleChange} name="file">
      <Center h='400px'
        border='5px'
        borderStyle={'dashed'}
        borderColor={"ui.500"}
        borderRadius={"6px"}
        borderWidth={"2px"}
      >
        <Flex direction='column' gap='1em' alignItems='center'>
          <Flex direction='column' gap='1em'>
            <Image h='60px' src='/icons/navigation/data.svg' />
            <Text fontSize={'24px'}>
              Drag and drop to upload a file
            </Text>
          </Flex>

          or

          <Button variant={'outline'} size='lg'>
            Upload
          </Button>

          <Text>
            Supports .csv, .xlsx, .json, .xml
          </Text>
        </Flex>

      </Center>
    </FileUploader>
  )
}

export default UploadDragDrop