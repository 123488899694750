import { Button, Center, Divider, Flex, SimpleGrid, Spacer, Text, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useEmissions, useTargets } from '../../services/APIHooks'
import CenteredSpinner from '../Blank/CenteredSpinner'
import NoTargetsYet from '../Blank/NoTargetsYet'
import TargetCard from './TargetCard'
import TargetModal from './TargetModal'

const Targets = (props) => {

  const auth = useContext(props.context)
  const baselineYear = auth.orgData.year_baseline
  const finalYear = 2050

  const { targetData, loaded, setTargetData } = useTargets(auth.token, auth.orgId)
  const targets = Object(targetData)

  const baselineYearEmissions = useEmissions(baselineYear ? auth.token : null, auth.orgId, undefined, baselineYear)
  const baseline = [
    baselineYearEmissions.emissions_scope1,
    baselineYearEmissions.emissions_scope2,
    baselineYearEmissions.emissions_scope3
  ]

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [activeTarget, setActiveTarget] = useState({})

  const editTarget = useCallback((target) => {
    if (!target) {
      target = {
        new: true,
        scope: 1,
        reduction_focus: 'emissions',
        year: new Date().getFullYear() + 1,
      }
    }
    setActiveTarget({ ...target })
    onOpen()
  }, [onOpen])

  const location = useLocation()
  useEffect(() => {
    if (Object(location).state?.new) {
      editTarget(null)
    }
  }, [editTarget, location])

  const AddTargetButton = () => {
    return <Button
      onClick={() => editTarget(null)}
      width="200px" bg={"expect.sky"} color="black"
      _hover={{}} _active={{ bg: "sky.700" }}
    > Add target </Button>
  }

  const Modal = () => {
    return <TargetModal
      token={auth.token}
      orgId={auth.orgId}

      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}

      target={activeTarget}
      targets={targets}
      setTargets={setTargetData}

      baselineYear={baselineYear}
      finalYear={finalYear}
    />
  }

  if (!loaded) {
    return <CenteredSpinner />
  }

  if (loaded && targetData.length === 0) {
    return <VStack>
      <NoTargetsYet button={<AddTargetButton />} />
      <Modal />
    </VStack>
  }

  return (
    <Flex direction={"column"} gap="2em">
      <Modal />

      {[1, 2, 3].map((scope) => (
        <Flex direction={"column"} gap="1em" key={scope} w="100%">
          <Text> Scope {scope} </Text>

          <Divider />

          <SimpleGrid minChildWidth={"400px"} spacing="1em">
            {Object(targetData).filter(target => Number(target.scope) === scope)
              .sort((targetA, targetB) => targetA.year - targetB.year)
              .map((target, index) => (
                <TargetCard
                  key={index} target={target} editTarget={editTarget}
                  baseline={baseline} carbonPrice={auth.orgData.carbon_price}
                />
              ))}
          </SimpleGrid>
        </Flex>
      ))}

      <Spacer />

      <Center>
        <AddTargetButton />
      </Center>

    </Flex>
  )
}

export default Targets