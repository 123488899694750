import { Image } from '@chakra-ui/react'
import React from 'react'

const AlertIcon = (props) => {



  return (
    <Image
      h='20px' mr='14px'
      src={'/icons/insights/' + props.type + '.svg'}
    />
  )
}

export default AlertIcon