import { Flex, FormControl, FormLabel, Input, Radio, RadioGroup, Select, Stack } from '@chakra-ui/react'
import React from 'react'

const TargetForm = (props) => {

  const target = props.target

  const yearOptions = []
  for (let year = props.baselineYear;year <= props.finalYear;year++)
    yearOptions.push(year)

  const getUpdateFn = (attribute) => {
    return (event) => {
      const newValue = event.target?.value || event
      const dataCopy = props.newData
      dataCopy[attribute] = newValue
      props.setNewData(dataCopy)
    }
  }

  return (
    <Flex direction="column" gap="1em">
      <FormControl>
        <FormLabel> Scope </FormLabel>
        <RadioGroup onChange={getUpdateFn('scope')} defaultValue={target.scope?.toString() || "1"}>
          <Stack direction='row' gap="16px">
            <Radio value='1'> Scope 1 </Radio>
            <Radio value='2'> Scope 2 </Radio>
            <Radio value='3'> Scope 3 </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl display={'none'}>
        <FormLabel> Focus </FormLabel>
        <RadioGroup onChange={getUpdateFn('reduction_focus')} defaultValue={target.reduction_focus || "emissions"}>
          <Stack direction='row' gap="16px">
            <Radio value="emissions"> Emissions </Radio>
            <Radio value="carbon_intensity"> Carbon intensity </Radio>
          </Stack>
        </RadioGroup>
      </FormControl>

      <FormControl>
        <FormLabel> Reduction percentage </FormLabel>
        <Input
          onChange={getUpdateFn('reduction_percentage')} defaultValue={props.target.reduction_percentage}
          type={'number'} min={1} step={1}
        />
      </FormControl>

      <FormControl>
        <FormLabel> Target year </FormLabel>
        <Select onChange={getUpdateFn('year')} defaultValue={props.target.year}>
          {yearOptions.map(year => (
            <option key={year}> {year} </option>
          ))}
        </Select>
      </FormControl>
    </Flex>
  )
}

export default TargetForm