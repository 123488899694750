import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, FormControl, FormErrorMessage, IconButton, Image, Input, InputGroup, InputLeftElement, InputRightElement, Text, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { env } from '../../helpers/helpers'
import UploadAlert from '../Data/UploadSteps/UploadAlert'

const LoginPage = (props) => {

  const location = useLocation()
  // @ts-ignore
  const from = location.state?.from?.pathname || '/'
  const navigate = useNavigate()
  const auth = useContext(props.context)
  const [queryParams] = useSearchParams()

  const [username, setUsername] = useState(queryParams.get('email') || '')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isInvalid, setIsInvalid] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)

  const forgotPasswordUrl = (env() === 'production') ? "https://accounts.expectai.com/password/reset" : "https://accounts-staging.expectai.com/password/reset";

  const login = (e) => {
    e.preventDefault()

    if (!password || !username) {
      setIsInvalid(true)
      return
    }

    setIsLoading(true)

    const signinResponse = auth.signIn(username, password, () => {
      navigate(from, { replace: true })
    })

    signinResponse.then(response => {
      setIsLoading(false)
      if (response?.detail === 'Incorrect username or password')
        setIsInvalid(true)
    })
  }

  const getErrorMessage = () => {
    if (!username)
      return "The email field is required"
    else if (!password)
      return "The password field is required"
    return "These credentials do not match any accounts in our records"
  }

  const ShowHidePasswordButton = () => {
    const isEdge = window.navigator.userAgent.includes("Edg")

    if (isEdge)
      return <></>
    return <IconButton aria-label="show/hide password" h="30px" size={"lg"} color={"grey"}
      onClick={toggleShowPassword}
      icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
      variant={"ghost"}
    />
  }

  const NewUserNotification = () => {
    if (!queryParams.get('registration_complete'))
      return <></>
    return <UploadAlert plain type='info' message='Welcome! Your account has been created. Please sign in.' />
  }

  return (
    <Flex backgroundColor={'grey'} h="100vh" w="100vw" bg="ui.100">
      <Box h={"100%"} flex="1">
        <Image h={"100%"} w={"100%"} src='\images\loginBackground.png' fit={'cover'} />
      </Box>

      <Flex direction={'column'} minWidth={"600px"} padding={"140px 50px"}>

        <Flex w="100%" justifyContent={"center"} mb={"2em"}>
          <Image w="200px" src='\images\logos\mark-and-text-white-padding.svg' />
        </Flex>



        <form style={{ width: "100%" }} onSubmit={login}>
          <FormControl isInvalid={isInvalid}>
            <VStack gap={"24px"}>
              <NewUserNotification />

              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Image src="\icons\person.svg" />}
                />
                <Input
                  isInvalid={isInvalid}

                  autoFocus={true}
                  value={username}
                  onChange={(e) => { setUsername(e.target.value); setIsInvalid(false) }}
                  type='email'
                  placeholder='Email'
                  tabIndex={1}
                  id="email"
                />
              </InputGroup>


              <InputGroup>
                <InputLeftElement
                  pointerEvents='none'
                  children={<Image src="\icons\padlock.svg" />}
                />
                <Input
                  isInvalid={isInvalid}

                  value={password}
                  onChange={(e) => { setPassword(e.target.value); setIsInvalid(false) }}
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Password'
                  tabIndex={2}
                  id="password"
                />
                <InputRightElement width='4.5rem'>
                  <ShowHidePasswordButton />
                </InputRightElement>
              </InputGroup>
            </VStack>
            <FormErrorMessage> {getErrorMessage()} </FormErrorMessage>
          </FormControl>

          <a href={forgotPasswordUrl}>
            <Text mt={"12px"} w="100%" textAlign={"right"} color={"onSurface.primary.high"}>
              Forgot password?
            </Text>
          </a>

          <Button
            type="submit" mt={"48px"} w={"100%"} bg={"primary.primary"} _hover={{ bg: "primary.hover" }}
            isLoading={isLoading}
            tabIndex={3}
          >
            Sign in
          </Button>
        </form>
      </Flex>
    </Flex>
  )
}

export default LoginPage