import topology from "@highcharts/map-collection/custom/world-highres.topo.json"
import theme from '../theme';

export const getMapOptions = () => {

  return {
    chart: {
      map: topology,
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Poppins',
        color: 'white',
      },
      borderWidth: 0,
      // borderColor: 'black'
    },
    tooltip: {},
    credits: {
      enabled: false
    },
    title: {
      text: ''
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        style: {
          color: theme.colors.ui['500']
        },
        theme: {
          fill: theme.colors.ui['300'],
          'stroke-width': 1,
          stroke: theme.colors.ui['500'],
          color: 'pink',
          states: {
            hover: {
              fill: theme.colors.ui['400']
            },
          }
        }
      }
    },
    legend: {
      enabled: false
    },
    colorAxis: {
      stops: [
        [0, theme.colors.ice[800]],
        [0.1, theme.colors.ice[700]],
        [0.2, theme.colors.ice[600]],
        [0.3, theme.colors.ice[500]],
        [0.4, theme.colors.ice[400]],
        [0.5, theme.colors.ice[300]],
        [0.6, theme.colors.ice[200]],
        [0.7, theme.colors.ice[100]],
        [1, theme.colors.ice[50]],
      ]
    },
    series: [{
      data:[],
      nullColor: theme.colors.primary.soft,
      borderColor: theme.colors.ui['50'],
      joinBy: ['iso-a2', 'code'],
      animation: true,
      name: 'Suppliers',
      states: {
        hover: {
          color: 'white'
        }
      },
      tooltip: {
        valueSuffix: ''
      },
      shadow: false
    }]
  }
}
