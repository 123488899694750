export const categories = [
  {
    scope: 1,
    name: 'Scope 1',
    description: 'Direct emissions occurring from sources within your organisation\'s control'
  },

  {
    scope: 2,
    name: 'Scope 2',
    description: 'Indirect emissions associated with your purchase of electricity, steam, heat, or cooling'
  },

  {
    scope: 3,
    name: 'Scope 3',
    description: 'Indirect from assets not controlled by your organisation, but that your organisation indirectly impacts in its value chain',
  },

  {
    scope: 3,
    category: 1,
    name: 'Category 1',
    description: 'Purchased Goods and Services'
  },

  {
    scope: 3,
    category: 2,
    name: 'Category 2',
    description: 'Capital Goods'
  },

  {
    scope: 3,
    category: 3,
    name: 'Category 3',
    description: 'Fuel- and Energy-Related Activities that aren\'t covered in Scopes 1 and 2'
  },

  {
    scope: 3,
    category: 4,
    name: 'Category 4',
    description: 'Upstream Transportation and Distribution',
  },

  {
    scope: 3,
    category: 5,
    name: 'Category 5',
    description: 'Waste Generated in Operations'
  },

  {
    scope: 3,
    category: 6,
    name: 'Category 6',
    description: 'Business Travel'
  },

  {
    scope: 3,
    category: 7,
    name: 'Category 7',
    description: 'Employee Commuting'
  },

  {
    scope: 3,
    category: 8,
    name: 'Category 8',
    description: 'Upstream Leased Assets'
  },

  {
    scope: 3,
    category: 9,
    name: 'Category 9',
    description: 'Downstream Transportation and Distribution'
  },

  {
    scope: 3,
    category: 10,
    name: 'Category 10',
    description: 'Processing of Sold Products'
  },

  {
    scope: 3,
    category: 11,
    name: 'Category 11',
    desciption: 'Use of Sold Products'
  },

  {
    scope: 3,
    category: 12,
    name: 'Category 12',
    description: 'End-of-Life Treatment of Sold Products',
  },

  {
    scope: 3,
    category: 13,
    name: 'Category 13',
    description: 'Downstream Leased Assets'
  },

  {
    scope: 3,
    category: 14,
    name: 'Category 14',
    description: 'Franchises'
  },

  {
    scope: 3,
    category: 15,
    name: 'Category 15',
    description: 'Investments'
  },
]